/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';

import { Container, Row, Col } from 'shards-react';
import { useNavigate } from 'react-router-dom';

import API from 'api';

import { useDispatch } from 'react-redux';
import {
  setCurrentLineItemIndex,
  setLineItemsParams,
  setSpecialItemParams,
  setupDefaultParticipants,
  setValidationStatus,
} from 'redux/cart/cartSlice';

import Loading from 'components/loading/Loading';
import PageTitle from 'components/common/PageTitle';
import VerticalStepper from 'views/PreBooking/components/VerticalStepper';
import PreBookingItem from 'views/PreBooking/components/PreBookingItem';
import CompletePreBooking from 'views/PreBooking/components/CompletePreBooking';
import { buildTitle } from 'lib/title';
import { getQueryParameter } from 'lib/query-string';
import { useAuth } from 'contexts/AuthContext';
import { useCart, useDynamicFieldsValidation } from 'lib/hooks';
import { useBookingValidation } from './hooks/useBookingValidation';

import './styles.scss';

const PreBooking = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { lineItemParams, lineItemIndex, allLineItemsParams } = useCart();

  const dispatch = useDispatch();
  const orderId = getQueryParameter('ref');
  const [order, setOrder] = useState(null);
  const [user, setUser] = useState({
    name: '',
    email: '',
    document: '',
    address: {
      street: '',
      number: '',
      complement: '',
      city: '',
      stateId: '',
      zipCode: '',
    },
  });

  const [validUserAddress, setValidUserAddress] = useState({
    street: true,
    number: true,
    complement: true,
    city: true,
    stateId: true,
    zipCode: true,
  });

  const [bookingsValidations, setBookingsValidations] = useState({});

  const [registerCustomField, validateCustomFields] =
    useDynamicFieldsValidation();

  const [registerParticipantsField, validateParticipantsFields] =
    useDynamicFieldsValidation();

  const [validateBookingFields] = useBookingValidation();

  useEffect(() => {
    async function fetchData() {
      if (orderId) {
        document.title = buildTitle('Pré-agendamento');

        await Promise.all([fetchOrderData(), fetchUserData()]);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchUserData() {
      if (order) {
        addUserToOrder();
      }
    }

    fetchUserData();
  }, [order]);

  useEffect(() => {
    if (order && user && variantIndexes) {
      const variantsFields = variantIndexes.map(
        (variantIndex) =>
          order.line_items[variantIndex].variant.participant_fields,
      );

      dispatch(setupDefaultParticipants({ user, variantsFields }));
    }
  }, [order, user, variantIndexes]);

  if (!orderId) {
    navigate('/404');
  }

  const fetchUserData = async () => {
    API.User.find(currentUser.id)
      .then((response) => {
        const { data: body } = response;
        const userFromBackend = body.data;

        if (userFromBackend !== null) {
          setUser({
            ...user,
            name: `${userFromBackend.first_name} ${userFromBackend.last_name}`,
            email: userFromBackend.email || '',
            document: userFromBackend.document || '',
            address: {
              ...user.address,
              street: userFromBackend.street_address || '',
              number: userFromBackend.address_number || '',
              complement: userFromBackend.address_complement || '',
              city: userFromBackend.city || '',
              stateId: userFromBackend.state_id,
              zipCode: userFromBackend.zip_code || '',
            },
          });
        }
      })
      .catch((err) => {
        window.Rollbar.info('Failed to fetch user data on PreBooking', {
          error: err,
        });
        navigate('/your-dates');
      });
  };

  const addUserToOrder = async () => {
    if (order) {
      if (order.user_id) {
        return false;
      }
      const response = await API.Order.addUser(order.id, currentUser.id);
      setOrder(response.data);

      return true;
    }

    return false;
  };

  const fetchOrderData = async () => {
    API.Order.find(orderId)
      .then((response) => {
        const { data: body } = response;

        if (body.data !== null) {
          const currentOrder = body.data;
          setOrder(currentOrder);

          const variantLineItems = currentOrder.line_items.filter(
            (lineItem) => lineItem.type === 'variant',
          );

          const emptyLineItems = variantLineItems.map((item) => {
            const emptyParticipants = Array.from(
              { length: item.variant.max_party_size },
              () => {
                const participant = {};
                item.variant.participant_fields.forEach((field) => {
                  participant[field.name] = '';
                });
                return participant;
              },
            );

            return {
              id: item.id,
              participants: emptyParticipants,
              booking: {
                startDate: '',
                fromTime: '',
                toTime: '',
                weekDays: 0,
                comment: '',
              },
              customFields: {},
              userIsParticipant: true,
              validationStatus: 'initial',
              removed: false,
            };
          });

          dispatch(setLineItemsParams(emptyLineItems));

          const specialLineItems = currentOrder.line_items.filter(
            (lineItem) => lineItem.type === 'gift_card',
          );

          specialLineItems.forEach((li) => {
            dispatch(setSpecialItemParams({ id: li.id, removed: false }));
          });
        }
      })
      .catch((err) => {
        window.Rollbar.info('Failed to fetch order data on PreBooking', {
          error: err,
        });
        navigate('/your-dates');
      });
  };

  const validateLineItem = () => {
    if (!lineItemParams || lineItemParams.removed) {
      return true;
    }

    const { variant } = variantLineItems[lineItemIndex];

    const { customFields, participants, booking } = lineItemParams;

    const areCustomFieldsValid = validateCustomFields(
      variant.custom_fields,
      customFields,
    );

    const areParticipantsValid = validateParticipantsFields(
      variant.participant_fields,
      participants,
    );

    const [areBookingFieldsValid, bookingValidations] =
      validateBookingFields(booking);

    setBookingsValidations({
      ...bookingsValidations,
      [lineItemIndex]: bookingValidations,
    });

    const isValid =
      areCustomFieldsValid && areParticipantsValid && areBookingFieldsValid;

    dispatch(
      setValidationStatus({
        validationStatus: isValid ? 'valid' : 'invalid',
      }),
    );

    return isValid;
  };

  if (!user || !order) {
    return <Loading />;
  }

  const variantIndexes = order.line_items.reduce((indexes, lineItem, index) => {
    if (lineItem.type === 'variant') {
      indexes.push(index);
    }
    return indexes;
  }, []);

  const variantLineItems = order.line_items.filter(
    (lineItem) => lineItem.type === 'variant',
  );

  const nonVariantLineItems = order.line_items.filter(
    (lineItem) => lineItem.type !== 'variant',
  );

  const steps = variantLineItems.map((lineItem, index) => ({
    title: lineItem.product_title,
    index,
    final: false,
    key: lineItem.id,
    removed: !!allLineItemsParams[index]?.removed,
  }));

  steps.push({
    title: 'Finalizar',
    index: steps.length,
    final: true,
  });

  const onStepperClick = (index) => {
    validateLineItem();
    dispatch(setCurrentLineItemIndex(index));
  };

  return (
    <Container fluid className="main-content-container">
      <Row>
        <Col
          lg="2"
          md="12"
          className="d-none d-md-flex justify-content-center py-4"
        >
          <VerticalStepper
            activeStep={lineItemIndex}
            hasSpecial={nonVariantLineItems.length > 0}
            onClick={onStepperClick}
            steps={steps}
            validationStatus={allLineItemsParams.map((p) => p.validationStatus)}
          />
        </Col>
        <Col lg="10" md="12">
          <Row className="page-header py-4">
            <PageTitle
              title="Confirmação de pré-agendamento"
              subtitle="Agora só falta decidir os detalhes"
              sm="12"
              md="12"
              className="ml-sm-auto mr-sm-auto"
            />
          </Row>

          <Row className="d-flex d-md-none justify-content-center mb-2">
            <div
              className={`my-auto mx-2 ${
                lineItemIndex === 0 ? 'invisible' : ''
              }`}
            >
              <span
                className="material-icons p-1 btn-outline-primary rounded-circle"
                role="button"
                tabIndex="0"
                title="Item anterior"
                onClick={() => onStepperClick(lineItemIndex - 1)}
              >
                navigate_before
              </span>
            </div>
            <VerticalStepper
              activeStep={lineItemIndex}
              hasSpecial={nonVariantLineItems.length > 0}
              onClick={onStepperClick}
              steps={[steps[lineItemIndex]]}
              validationStatus={allLineItemsParams.map(
                (p) => p.validationStatus,
              )}
            />
            <div
              className={`my-auto mx-2 ${
                lineItemIndex === steps.length - 1 ? 'invisible' : ''
              }`}
            >
              <span
                className="material-icons p-1 btn-outline-primary rounded-circle"
                role="button"
                tabIndex="0"
                title="Próximo item"
                onClick={() => onStepperClick(lineItemIndex + 1)}
              >
                navigate_next
              </span>
            </div>
          </Row>

          <Row>
            {lineItemIndex < variantLineItems.length ? (
              <PreBookingItem
                bookingValidations={bookingsValidations[lineItemIndex]}
                key={order.line_items[variantIndexes[lineItemIndex]].id}
                lineItem={order.line_items[variantIndexes[lineItemIndex]]}
                registerCustomField={registerCustomField}
                registerParticipantsField={registerParticipantsField}
                user={user}
                validateLineItem={validateLineItem}
              />
            ) : (
              <CompletePreBooking
                order={order}
                setUser={setUser}
                setValidUserAddress={setValidUserAddress}
                specialItems={nonVariantLineItems}
                user={user}
                validUserAddress={validUserAddress}
              />
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PreBooking;
