/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { FormSelect } from 'shards-react';

import API from 'api';

const EstadosSelect = ({ currentStateId, onChange, klass }) => {
  const [states, setStates] = useState([]);

  useEffect(() => {
    const statesFromLocalStorage = localStorage.getItem('states');
    if (statesFromLocalStorage) {
      setStates(JSON.parse(statesFromLocalStorage));
    } else {
      API.State.all().then((response) => {
        const { data: body } = response;

        if (body.data !== null) {
          localStorage.setItem('states', JSON.stringify(body.data));
          setStates(body.data);
        }
      });
    }
  }, []);

  if (states.length === 0) {
    return null;
  }

  return (
    <FormSelect
      id="feInputState"
      onChange={onChange}
      className={klass}
      value={currentStateId}
      data-testid="address-state"
    >
      <option>Escolha...</option>
      {states.map((state) => (
        <option key={state.id} value={state.id}>
          {state.name}
        </option>
      ))}
    </FormSelect>
  );
};

export default EstadosSelect;
