/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Badge, Card, CardBody, Tooltip } from 'shards-react';

import { BADGE_INFO } from 'lib/badge-info';
import { formatDate } from 'lib/date-utils';
import { showDateImage as showImage } from 'lib/date-image';

const DateListItem = ({ item, orderID }) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const [additionalRequestsVisible, setAdditionalRequestsVisible] =
    useState(false);

  const toggle = () => {
    setOpenTooltip((o) => !o);
  };

  const badge = (options) => {
    let klass = 'item-badge';
    let id = `statusBadge_${item.id}`;
    let href = null;

    if (options.mobile) {
      klass = `${klass}-mobile`;
      id = `mobile_${id}`;
    }

    if (item.status === 'waiting_for_payment') {
      href = `/your-reservation?ref=${orderID}`;
      klass = `${klass} pointer`;
    }

    const currentBadge = () => (
      <Badge
        pill
        id={id}
        className={`${klass} ${BADGE_INFO[item.status].klass}`}
        style={
          item.status === 'booked'
            ? { animation: 'green-pulse 2s infinite' }
            : {}
        }
      >
        {BADGE_INFO[item.status].text}
      </Badge>
    );

    if (href) {
      // eslint-disable-next-line react/jsx-no-target-blank
      return (
        <a target="_blank" href={href}>
          {currentBadge()}
        </a>
      );
    }

    return currentBadge();
  };

  const { billing } = item;

  // let cardClass = '';
  const cardClass = '';

  // if (!order.number && order.status === 'pre_booking') {
  //   cardClass = 'date-card-disabled-background';
  // }

  // const continueOrder = () => {
  //   const preBookingUrl = `/pre-booking?ref=${orderID}`;
  //   navigate(preBookingUrl);
  // };

  return (
    <Card
      small
      className="card-post card-post--aside card-post--1 flex-flow-column overflow-hidden"
    >
      <div className={`inherit column-mobile ${cardClass}`}>
        <div
          className="pointer card-post__image__2 card-img-top-left"
          style={{ backgroundImage: `url('${showImage(item)}')` }}
          onClick={() => {
            //   if (order.number) {
            //     navigate(`/your-dates/${order.number}`);
            //   } else {
            //     continueOrder();
            //   }
          }}
        >
          {badge({ mobile: true })}
        </div>
        <CardBody className="date-list-item-info d-flex">
          <div className="card-text-2">
            <h5 className="card-title mb-1">
              {/* {order.number && ( */}
              <a
                className="text-fiord-blue"
                // href={`/your-dates/${order.number}`}
                href="#"
              >
                {item.type === 'gift_card'
                  ? item.title.split('-').shift()
                  : item.product_title}
              </a>
              {/* )} */}

              {/* {!order.number && (
              <span className="pointer" onClick={continueOrder}>
                {item.product_title}
              </span>
              )} */}
            </h5>
            {item.number && <p>Item {item.number}</p>}
            <p className="card-text d-inline-block mb-3">
              {item.type === 'variant' && (
                <>
                  <strong>Opção escolhida:</strong> {item.variant_title}
                  <br />
                </>
              )}
              {item.type === 'gift_card' && (
                <>
                  <strong>Para:</strong> {item.recipient_name}
                  <br />
                </>
              )}
              {item.scheduled_for && (
                <>
                  <strong>Agendado para:</strong>{' '}
                  {formatDate(item.scheduled_for)}
                  <br />
                </>
              )}
              <strong>Preço: </strong>
              {billing.price_with_discount.formatted} <br />
              {billing.balance_due > 0 && (
                <>
                  <strong>Valor a pagar: </strong>
                  {billing.balance_due.formatted} <br />
                </>
              )}
              {billing.balance_due < 0 && (
                <>
                  <strong>Valor a receber: </strong>
                  {billing.balance_due.formatted} <br />
                </>
              )}
            </p>
          </div>
          <div className="date-list-item-details">
            <div>
              <span className="text-muted">
                <strong>Criado em</strong> {formatDate(item.inserted_at)}
              </span>

              {item.status === 'waiting_for_payment' && item.expires_at && (
                <>
                  <br />
                  <span className="text-muted">
                    <strong>Expira em</strong> {formatDate(item.expires_at)}
                  </span>
                </>
              )}
              {item.children?.length > 0 && (
                <div
                  className="mt-2 pointer"
                  onClick={() => {
                    setAdditionalRequestsVisible(!additionalRequestsVisible);
                  }}
                >
                  <span style={{ textDecoration: 'underline' }}>
                    {additionalRequestsVisible ? 'Esconder' : 'Ver'} pedidos
                    adicionais
                  </span>
                  <span
                    className="material-icons ml-2"
                    style={{ fontSize: '20px', verticalAlign: 'bottom' }}
                  >
                    {additionalRequestsVisible
                      ? 'visibility_off'
                      : 'visibility'}
                  </span>
                </div>
              )}
            </div>
            {badge({ mobile: false })}
            <Tooltip
              open={openTooltip}
              target={`#statusBadge_${item.id}`}
              toggle={toggle}
            >
              {BADGE_INFO[item.status].tooltip}
            </Tooltip>
          </div>
        </CardBody>
      </div>
      {item.children?.length > 0 && (
        <section
          className={`card-footer ${
            additionalRequestsVisible ? 'show-footer border-top' : 'hide-footer'
          }`}
        >
          <h6>Pedidos adicionais:</h6>
          <ul>
            {item.children.map((child) => (
              <li key={child.id}>
                {child.quantity}x {child.title} -{' '}
                {child.billing.price_with_discount.formatted}
              </li>
            ))}
          </ul>
        </section>
      )}
    </Card>
  );
};

export default DateListItem;
