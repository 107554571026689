import React from 'react';
import { Nav } from 'shards-react';

import Notifications from 'components/layout/MainNavbar/NavbarNav/Notifications';
import UserActions from 'components/layout/MainNavbar/NavbarNav/UserActions';
import { useAuth } from 'contexts/AuthContext';

const NavbarNav = () => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return null;
  }

  return (
    <Nav navbar className="border-left flex-row">
      <Notifications />
      <UserActions />
    </Nav>
  );
};

export default NavbarNav;
