import { DEFAULT_ITEM_IMAGE } from './constants';

export const showImage = (item) => showDateImage(item);

export const showDateImage = (item) => {
  if (item.image_url) {
    return item.image_url;
  }

  return DEFAULT_ITEM_IMAGE;
};
