/* eslint-disable no-param-reassign */
export const maskCNPJ = (v) => {
  v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/, '$1.$2'); // Coloca ponto entre o segundo e o terceiro dígitos
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // Coloca ponto entre o quinto e o sexto dígitos
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2'); // Coloca uma barra entre o oitavo e o nono dígitos
  v = v.replace(/(\d{4})(\d)/, '$1-$2'); // Coloca um hífen depois do bloco de quatro dígitos

  return v;
};

export const maskCPF = (v) => {
  v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
  v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
  // de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca um hífen entre o terceiro e o quarto dígitos

  return v;
};

export const hideCPF = (cpf) => {
  const hiddenCPF = `${cpf.substr(0, 3)}.***.***-${cpf.substr(cpf.length - 2)}`;
  return hiddenCPF;
};

export const maskPhoneNumber = (v) => {
  let r = v.replace(/\D/g, '');
  r = r.replace(/^0/, '');

  if (r.length > 10) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
  } else if (r.length > 5) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
  } else {
    r = r.replace(/^(\d*)/, '($1');
  }

  return r;
};

export const maskZipCode = (v) => {
  let r = v.replace(/\D/g, '');

  if (r.length > 5) {
    r = r.replace(/^(\d{5})(\d{0,3}).*/, '$1-$2');
  }
  return r;
};
