/* eslint-disable react/no-array-index-key */

import React from 'react';
import { Nav } from 'shards-react';

import getSidebarNavItems from 'data/sidebar-nav-items';

import SidebarNavItem from 'components/layout/MainSidebar/SidebarNavItem';

const SidebarNavItems = () => {
  const navItems = getSidebarNavItems();

  return (
    <div className="nav-wrapper" data-testid="sidebar-menu">
      <Nav className="nav--no-borders flex-column">
        {navItems.map((item, idx) => (
          <SidebarNavItem key={idx} item={item} />
        ))}
      </Nav>
    </div>
  );
};

export default SidebarNavItems;
