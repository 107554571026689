import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Container, Row, Col, Card, CardHeader, Button } from 'shards-react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import API from 'api';

import BackButton from 'components/navigation/back-button/BackButton';
import ClipboardCopy from 'components/clipboard-copy/ClipboardCopy';
import Loading from 'components/loading/Loading';
import PageTitle from 'components/common/PageTitle';
import { buildTitle } from 'lib/title';
import { getQueryParameter } from 'lib/query-string';
import { redirectToPaymentProcessorCheckout } from 'lib/payment';
import { useAuth } from 'contexts/AuthContext';

const Pix = () => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const orderId = getQueryParameter('ref');

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  document.title = buildTitle('Pague com PIX');

  const fetchOrder = async (id) => {
    const result = await API.Order.find(id);
    const { data: body } = result;
    return body.data;
  };

  const { data: order, isLoading } = useQuery(['get-order', orderId], () =>
    fetchOrder(orderId),
  );

  const loadQRCode = async (id) => {
    if (id) {
      const response = await API.Pix.newResource(id);
      const { data: body } = response;
      return body.pix;
    }
    return null;
  };

  const { data: pixData, isLoading: isLoadingPixData } = useQuery(
    ['get-pix-data', order?.id],
    () => loadQRCode(order?.id),
  );

  if (!order || isLoading || isRedirecting || !pixData || isLoadingPixData) {
    return <Loading />;
  }

  if (
    order.status !== 'waiting_for_payment' ||
    order.user.id !== currentUser.id
  ) {
    navigate('/404');
  }

  return (
    <Container fluid className="main-content-container px-4">
      <BackButton />
      <Row className="page-header py-4">
        <PageTitle
          title="Pagamento com PIX"
          subtitle="Pague seu pedido"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>

      <Row className="m-0">
        <Col lg="12" sm="12" className="mb-4 order-number-highlight">
          <h5 className="m-0">Número do pedido: {order.number}</h5>
        </Col>
      </Row>

      <Row>
        <Col md="6" sm="12" className="mb-4">
          <Card
            small
            className="card-post card-post--aside card-post--1 flex-flow-column h-100"
          >
            {pixData.qrcode_base64 && (
              <>
                <div className="inherit column-mobile center-align">
                  <img
                    className="w-100"
                    src={pixData.qrcode_base64}
                    alt="Pix QR Code"
                  />
                </div>
                <div className="mb-4 text-center pl-4 pr-4">
                  Pague usando o QR code acima ou copie e cole o código da
                  transação no seu banco: <br />
                  <ClipboardCopy
                    copyText={pixData.code}
                    ariaLabel="Código PIX"
                  />
                </div>
              </>
            )}

            {!pixData.qrcode_base64 && <Loading />}
          </Card>
        </Col>
        <Col md="6" sm="12" className="mb-4">
          <Card
            small
            className="card-post card-post--aside card-post--1 flex-flow-column h-100"
          >
            <CardHeader className="border-bottom text-center">
              <h4>Atenção</h4>
            </CardHeader>

            <div className="inherit column-mobile mt-4 pr-4">
              <ul>
                <li>
                  Guarde o comprovante da transação. Em caso de erros durante a
                  compra, o comprovante agiliza a resolução do problema.
                </li>
                <li>
                  Sempre confira os dados antes de pagar o PIX. Você irá pagar
                  para:
                </li>
              </ul>
            </div>

            {pixData.qrcode_base64 && (
              <div
                style={{
                  margin: '0 auto',
                  border: '1px dashed #ccc',
                  padding: '25px',
                  width: '90%',
                }}
              >
                <p>
                  <b>Nome</b>: {pixData.name} <br />
                  <b>Valor</b>: {pixData.formatted.amount}
                </p>
              </div>
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'flex-start',
                alignContent: 'center',
                height: '100%',
              }}
            >
              <Row
                className="center-align text-center mt-4"
                style={{
                  alignSelf: 'flex-end',
                  borderTop: '1px solid #ccc',
                  width: '100%',
                  paddingTop: '30px',
                  paddingBottom: '30px',
                  display: 'inline',
                }}
              >
                <Button
                  className="mr-2 ml-4 btn btn-primary btn-pill"
                  onClick={() => {
                    navigate('/your-dates');
                  }}
                >
                  Já paguei
                </Button>
                <Button
                  className="mx-2 btn btn-outline-danger btn-pill"
                  onClick={() => {
                    setIsRedirecting(true);
                    redirectToPaymentProcessorCheckout(order, {
                      errorCallback: () => {
                        setIsRedirecting(false);
                        Swal.fire(
                          'Não conseguimos redirecionar você para o pagamento, entre em contato com a gente ou tente novamente mais tarde.',
                          '',
                          'error',
                        );
                      },
                    });
                  }}
                >
                  Quero usar cartão ou boleto*
                </Button>
              </Row>
              <p className="font-italic mx-4">
                <small>*Pode haver mudança no valor</small>
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Pix;
