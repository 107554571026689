/* eslint-disable no-return-assign */
import React from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'shards-react';

import EstadosSelect from 'components/estados-select/EstadosSelect';
import { maskZipCode } from 'lib/masks';

const UserData = ({ validAddress, address, user, setUser }) => (
  <Card className="user-data-component">
    <CardHeader className="border-bottom">
      <h5 className="m-0">Complete seu cadastro</h5>
    </CardHeader>
    <CardBody>
      <Col sm="12" className="mb-3">
        <Row>
          <Col sm="12" className="mb-3">
            <p className="card-text d-inline-block mb-3">
              <strong>
                Antes de prosseguir, precisamos de algumas informações.
              </strong>
            </p>
          </Col>

          <Col sm="12">
            <h5>Endereço</h5>
            <Row>
              <Col sm="8" lg="4">
                <div className="form-floating m-2">
                  <h6>Logradouro*</h6>
                  <input
                    className={`form-control ${
                      validAddress.street ? '' : 'required'
                    }`}
                    type="text"
                    placeholder="Rua/avenida/travessa..."
                    value={address.street}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        address: {
                          ...user.address,
                          street: e.currentTarget.value,
                        },
                      })
                    }
                    data-testid="address-street"
                  />
                </div>
              </Col>
              <Col sm="4" md="2">
                <div className="form-floating m-2">
                  <h6>Número*</h6>
                  <input
                    className={`form-control ${
                      validAddress.number ? '' : 'required'
                    }`}
                    type="text"
                    placeholder="Número"
                    value={address.number}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        address: {
                          ...user.address,
                          number: e.currentTarget.value,
                        },
                      })
                    }
                    data-testid="address-number"
                  />
                </div>
              </Col>

              <Col sm="10" md="6">
                <div className="form-floating m-2">
                  <h6>Complemento</h6>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Complemento"
                    value={address.complement}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        address: {
                          ...user.address,
                          complement: e.currentTarget.value,
                        },
                      })
                    }
                    data-testid="address-complement"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <div className="form-floating m-2">
                  <h6>CEP*</h6>
                  <input
                    className={`form-control ${
                      validAddress.zipCode ? '' : 'required'
                    }`}
                    type="text"
                    placeholder="CEP"
                    value={address.zipCode}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        address: {
                          ...user.address,
                          zipCode: e.currentTarget.value,
                        },
                      })
                    }
                    onKeyUp={(e) =>
                      (e.currentTarget.value = maskZipCode(
                        e.currentTarget.value,
                      ))
                    }
                    data-testid="address-zip-code"
                  />
                </div>
              </Col>
              <Col sm="4">
                <div className="form-floating m-2">
                  <h6>Cidade*</h6>
                  <input
                    className={`form-control ${
                      validAddress.city ? '' : 'required'
                    }`}
                    type="text"
                    placeholder="Cidade"
                    value={address.city}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        address: {
                          ...user.address,
                          city: e.currentTarget.value,
                        },
                      })
                    }
                    data-testid="address-city"
                  />
                </div>
              </Col>
              <Col sm="4">
                <div className="form-floating m-2">
                  <h6>Estado*</h6>
                  <EstadosSelect
                    klass={validAddress.stateId ? '' : 'required'}
                    currentStateId={address.stateId}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        address: {
                          ...user.address,
                          stateId: e.currentTarget.value,
                        },
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="mb-3">
            <br />
            <br />
            <p className="card-text d-inline-block mb-3">
              Os campos marcados com um asterisco (*) são obrigatórios.
            </p>
          </Col>
        </Row>
      </Col>
    </CardBody>
  </Card>
);

export default UserData;
