import React from 'react';
import { Container, Row, Col } from 'shards-react';

import NotificationsList from 'components/notifications/Notifications';
import PageTitle from 'components/common/PageTitle';

const Notifications = () => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle
        title="Notificações"
        md="12"
        className="ml-sm-auto mr-sm-auto"
      />
    </Row>
    {/* Notifications */}
    <Row>
      <Col lg="12" md="12" sm="12" className="mb-4">
        <NotificationsList />
      </Col>
    </Row>
  </Container>
);

export default Notifications;
