import React from 'react';

import Login from 'views/authentication/Login';
import Signup from 'views/authentication/Signup';

const AuthSection = ({
  hasAccount,
  preBookingUrl,
  setHasAccount,
  setForgotPassword,
  onEmailChange,
  email,
}) => (
  <>
    {hasAccount && (
      <Login
        returnTo={preBookingUrl}
        onSignUpClick={() => {
          setHasAccount(false);
        }}
        onForgotPasswordClick={() => {
          setForgotPassword(true);
        }}
        onEmailChange={onEmailChange}
        initialEmail={email}
      />
    )}

    {!hasAccount && (
      <Signup
        returnTo={preBookingUrl}
        onSignInClick={() => {
          setHasAccount(true);
        }}
      />
    )}
  </>
);

export default AuthSection;
