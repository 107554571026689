import { useRef } from 'react';
import { VALIDATIONS } from '../fields-validations';

export function useDynamicFieldsValidation() {
  const fieldRefs = useRef({});

  const validateDynamicFields = (fields, values) => {
    if (fields.length === 0) {
      return [true, []];
    }

    const listOfValues = values.constructor === Array ? values : [values];
    const invalidFields = [];

    Object.keys(fieldRefs.current).forEach((index) => {
      const attributesToValidate = Object.keys(fieldRefs.current[index]);
      attributesToValidate.forEach((attribute) => {
        const ref = fieldRefs.current[index][attribute];
        if (ref) {
          ref.classList.remove('is-invalid');
        }
      });
    });

    Object.keys(fieldRefs.current).forEach((index) => {
      const attributesToValidate = Object.keys(fieldRefs.current[index]);
      attributesToValidate.forEach((attribute) => {
        const ref = fieldRefs.current[index][attribute];
        if (ref) {
          const elementId = ref.id.replace('#', '');
          const value = listOfValues[index][elementId] || '';
          const rules = fields.find((field) => field.name === elementId);

          if (rules) {
            const validationType = rules.validation_type || 'default';
            const { required } = rules;

            const valid = required
              ? value !== '' && VALIDATIONS[validationType](value)
              : value === '' || VALIDATIONS[validationType](value);

            if (!valid) {
              ref.classList.add('is-invalid');
              invalidFields.push(ref);
            }
          }
        }
      });
    });

    return invalidFields.length === 0;
  };

  const registerDynamicField = (ref, index = 0) => {
    if (ref) {
      const elementId = ref.id.replace('#', '');

      fieldRefs.current[index] ||= {};
      fieldRefs.current[index][elementId] = ref;
    }
  };

  return [registerDynamicField, validateDynamicFields];
}
