import { useAuth } from 'contexts/AuthContext';

const Logout = () => {
  const { logout } = useAuth();

  logout().then(() => {
    window.location = '/';
  });
};

export default Logout;
