/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useContext } from 'react';
import { publicClient } from 'api/client';
import {
  setAccessToken,
  removeAccessToken,
  removeRefreshToken,
  setRefreshToken,
} from 'api/token-service';

const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const login = async (email, password) => {
    try {
      // Sign in request must not use interceptors, so we cannot use the
      // regular axios API used in all other requests.
      const response = await publicClient.post('session/new', {
        email,
        password,
      });
      const accessToken = response.data.access_token;
      const refreshToken = response.data.refresh_token;
      const { user } = response.data;

      setAccessToken(accessToken);
      setRefreshToken(refreshToken);

      updateProfileDataInMemory(user);

      return { loggedIn: true };
    } catch (error) {
      logout();
      return { loggedIn: false, error };
    }
  };

  const logout = () => {
    removeAccessToken();
    removeRefreshToken();
    removeCurrentUser();
  };

  const updateProfileDataInMemory = (user) => {
    localStorage.setItem(
      'user',
      JSON.stringify({
        id: user.id,
        avatar: user.avatar,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        status: user.status,
      }),
    );
  };

  const getCurrentUser = () => {
    const value = localStorage.getItem('user');
    return value ? JSON.parse(value) : null;
  };

  const removeCurrentUser = () => localStorage.removeItem('user');

  const currentUser = getCurrentUser();

  const value = {
    login,
    logout,
    currentUser,
    updateProfileDataInMemory,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
