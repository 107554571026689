import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menuVisible: false,
};

export const sidebarSlice = createSlice({
  /* eslint-disable no-param-reassign */
  name: 'sidebar',
  initialState,
  reducers: {
    changeMenuVisibility: (state) => {
      state.menuVisible = !state.menuVisible;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeMenuVisibility } = sidebarSlice.actions;

export default sidebarSlice.reducer;
