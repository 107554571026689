/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FormInput, FormRadio } from 'shards-react';

import API from 'api/index';

import { validatePasswordFormat, validateEmailFormat } from 'lib/validators';

import './styles.scss';

const EmailPasswordModal = ({ user, close }) => {
  const [resourceToChange, setResourceToChange] = useState('email');
  const [email, setEmail] = useState(user.email);
  const [newEmail, setNewEmail] = useState('');
  const [newEmailConfirmation, setNewEmailConfirmation] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const MySwal = withReactContent(Swal);

  const [validPassword, setValidPassword] = useState(true);
  const [validNewPassword, setValidNewPassword] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [newPasswordMatchConfirmation, setNewPasswordMatchConfirmation] =
    useState(true);
  const [newEmailMatchConfirmation, setNewEmailMatchConfirmation] =
    useState(true);

  const validatePassword = () => {
    const isValidPassword = password.trim().length > 0;
    setValidPassword(isValidPassword);

    const isValidNewPassword = newPassword.trim().length > 0;
    setValidNewPassword(isValidNewPassword);

    const doesNewPasswordMatchConfirmation =
      newPassword === newPasswordConfirmation;
    setNewPasswordMatchConfirmation(doesNewPasswordMatchConfirmation);

    return (
      isValidPassword && isValidNewPassword && doesNewPasswordMatchConfirmation
    );
  };

  const validateEmail = () => {
    const isValidPassword = password.trim().length > 0;
    setValidPassword(isValidPassword);

    const doesNewEmailMatchConfirmation = newEmail === newEmailConfirmation;
    setNewEmailMatchConfirmation(doesNewEmailMatchConfirmation);

    return isValidPassword && newEmail === newEmailConfirmation;
  };

  const onUpdatePasswordHandler = () => {
    if (!validatePasswordFormat(newPassword)) {
      MySwal.fire({
        title: <strong>Verifique os campos em vermelho</strong>,
        html: (
          <p>
            Lembre-se: a senha deve ter no mínimo 8 caracteres, incluindo ao
            menos uma letra maiúscula, uma minúscula e um número
          </p>
        ),
        icon: 'warning',
      });
    } else if (validatePassword()) {
      API.User.updatePassword(user.id, email, password, newPassword)
        .then(() => {
          MySwal.fire({
            title: <strong>Perfil atualizado!</strong>,
            icon: 'success',
          }).then(() => window.location.reload());
        })
        .catch((e) => {
          if (e.response.status === 401) {
            MySwal.fire({
              title: <strong>Senha incorreta</strong>,
              icon: 'error',
            });

            setValidPassword(false);
          } else {
            MySwal.fire({
              title: <strong>Oops</strong>,
              html: (
                <i>Alguma coisa deu errada, tente novamente mais tarde :(</i>
              ),
              icon: 'error',
            });
          }
        });
    } else {
      MySwal.fire({
        title: <strong>Verifique os campos em vermelho</strong>,
        html: (
          <p>
            Para garantir que vamos trocar sua senha direitinho, a senha e a
            confirmação precisam ser idênticas.
          </p>
        ),
        icon: 'warning',
      });
    }
  };

  const onUpdateEmailHandler = () => {
    const isValidEmail = validateEmailFormat(newEmail);
    setValidEmail(isValidEmail);

    if (validateEmail() && isValidEmail) {
      API.User.updateEmail(user.id, email, password, newEmail)
        .then(() => {
          MySwal.fire({
            title: <strong>Perfil atualizado!</strong>,
            icon: 'success',
          }).then(() => window.location.reload());
        })
        .catch((e) => {
          if (e.response.status === 401) {
            MySwal.fire({
              title: <strong>Senha incorreta</strong>,
              icon: 'error',
            });

            setValidPassword(false);
          } else {
            MySwal.fire({
              title: <strong>Oops</strong>,
              html: (
                <i>Alguma coisa deu errada, tente novamente mais tarde :(</i>
              ),
              icon: 'error',
            });
          }
        });
    } else {
      let message =
        'Certifique-se de que o endereço fornecido é um endereço de email válido.';
      if (newEmail !== newEmailConfirmation) {
        message =
          'Para garantir que vamos trocar seu email direitinho, o email e a confirmação precisam ser idênticos.';
      }
      MySwal.fire({
        title: <strong>Verifique os campos em vermelho</strong>,
        html: <p>{message}</p>,
        icon: 'warning',
      });
    }
  };

  return (
    <Modal show onHide={close} backdrop="static">
      <Modal.Header>
        <Modal.Title>Credenciais de acesso</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <div key="inline-radio" className="mb-3">
              <FormRadio
                name="group1"
                checked={resourceToChange === 'email'}
                style={{ paddingLeft: 0 }}
                onChange={() => {
                  setResourceToChange('email');
                }}
              >
                Trocar email
              </FormRadio>

              <FormRadio
                name="group1"
                checked={resourceToChange === 'password'}
                onChange={() => {
                  setResourceToChange('password');
                }}
              >
                Trocar senha
              </FormRadio>
            </div>
          </Col>
        </Row>
        <Row form>
          {resourceToChange === 'email' && (
            <Col className="form-group">
              <label>Email</label>
              <FormInput
                type="email"
                placeholder="Email"
                disabled
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                autoComplete="email"
              />

              <br />

              <label>Novo email</label>
              <FormInput
                type="email"
                value={newEmail}
                className={validEmail ? '' : 'invalid-input'}
                onChange={(e) => {
                  setNewEmail(e.target.value);
                }}
              />

              <br />

              <label>Confirme seu novo email</label>
              <FormInput
                type="email"
                value={newEmailConfirmation}
                className={newEmailMatchConfirmation ? '' : 'invalid-input'}
                onChange={(e) => {
                  setNewEmailConfirmation(e.target.value);
                }}
              />

              <br />

              <label>Senha atual*</label>
              <FormInput
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className={validPassword ? '' : 'invalid-input'}
              />

              <br />

              <div className="text-right">
                <Button variant="primary" onClick={onUpdateEmailHandler}>
                  Trocar email
                </Button>
              </div>
            </Col>
          )}

          {resourceToChange === 'password' && (
            <Col className="form-group">
              <label>Senha atual*</label>
              <FormInput
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className={validPassword ? '' : 'invalid-input'}
              />

              <br />

              <label>Nova senha</label>
              <FormInput
                type="password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                className={
                  newPasswordMatchConfirmation && validNewPassword
                    ? ''
                    : 'invalid-input'
                }
              />

              <br />

              <label>Confirme sua nova senha</label>
              <FormInput
                type="password"
                value={newPasswordConfirmation}
                onChange={(e) => {
                  setNewPasswordConfirmation(e.target.value);
                }}
                className={newPasswordMatchConfirmation ? '' : 'invalid-input'}
              />

              <br />

              <div className="text-right">
                <Button variant="primary" onClick={onUpdatePasswordHandler}>
                  Trocar senha
                </Button>
              </div>
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailPasswordModal;
