import React from 'react';
import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/loading/Loading';
import Timeline from 'components/timeline/Timeline';
import { formatDateTimeUsingSlashes } from 'lib/date-utils';

import 'views/DatesList/styles.scss';

const DateDetailsTimeline = ({ order }) => {
  const fetchOrderTimeline = async () => {
    const result = await API.OrderTimeline.find(order.id);
    const { data: body } = result;
    return body.data;
  };

  const { data: timeline, isLoading } = useQuery(
    ['get-order-timeline', order.id],
    fetchOrderTimeline,
  );

  if (!timeline || isLoading) {
    return <Loading />;
  }

  const buildTimelineEventTitle = (event) => <span>{event.title}</span>;

  const buildTimelineEventDescription = (event) => {
    if (!event.descriptions) {
      return null;
    }

    const inlineDescriptions = event.descriptions.filter(
      (d) => d.type === 'inline',
    );
    const multilineDescriptions = event.descriptions.filter(
      (d) => d.type === 'multiline',
    );

    return (
      <>
        {inlineDescriptions && inlineDescriptions.length > 0 && (
          <ul className="timeline-inline-list">
            {inlineDescriptions.map((d) => (
              <li>
                {d.title}: {d.text}
              </li>
            ))}
          </ul>
        )}

        {multilineDescriptions &&
          multilineDescriptions.length > 0 &&
          multilineDescriptions.map((d) => {
            if (!d.text) {
              return null;
            }

            return (
              <div className="timeline-multiline-block">
                <p>{d.title}:</p>
                <p className="timeline-message">{d.text}</p>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <div className="date-details-timeline">
      <Timeline
        items={timeline}
        eventTitle={buildTimelineEventTitle}
        eventDate={(item) => formatDateTimeUsingSlashes(item.inserted_at, true)}
        eventDescription={buildTimelineEventDescription}
        marginLeft="6px"
      />
    </div>
  );
};

export default DateDetailsTimeline;
