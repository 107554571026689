import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from 'shards-react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import API from 'api';

import BackButton from 'components/navigation/back-button/BackButton';
import Loading from 'components/loading/Loading';
import PageTitle from 'components/common/PageTitle';
import PaymentSummary from 'components/payment-summary/PaymentSummary';
import ReservationDetails from 'views/Reservation/ReservationDetails';
import { buildTitle } from 'lib/title';
import { getQueryParameter } from 'lib/query-string';
import { redirectToPaymentPage } from 'lib/payment';
import { useAuth } from 'contexts/AuthContext';

import 'views/Reservation/styles.scss';

const Reservation = () => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('credit_card');

  const { currentUser } = useAuth();

  const orderId = getQueryParameter('ref');
  const navigate = useNavigate();

  document.title = buildTitle('Reserva confirmada');

  const fetchOrder = async (id) => {
    const result = await API.Order.find(id);
    const { data: body } = result;
    return body.data;
  };

  const {
    data: order,
    isLoading,
    refetch,
  } = useQuery(['get-order', orderId], () => fetchOrder(orderId));

  const fetchUserStoreCreditsTotal = async (userId) => {
    const result = await API.UserStoreCredit.all(userId);
    return result.data;
  };

  const {
    data: userStoreCreditsTotal,
    isLoading: isLoadingUserStoreCreditsTotal,
    refetch: refetchUserStoreCreditsTotal,
  } = useQuery(['user-store-credits-total', currentUser.id], () =>
    fetchUserStoreCreditsTotal(currentUser.id),
  );

  if (!order || isLoading || isRedirecting || isLoadingUserStoreCreditsTotal) {
    return <Loading />;
  }

  if (
    order !== null &&
    order.status === 'waiting_for_payment' &&
    order.user.id === currentUser.id
  ) {
    if (paymentMethod === '') {
      setPaymentMethod(order.payment_method || 'pix');
    }
  } else {
    navigate(`/your-dates/${order.number}`);
  }

  const goToPaymentHandler = () => {
    setIsRedirecting(true);
    const options = {
      errorCallback: () => {
        setIsRedirecting(false);
        Swal.fire(
          'Não conseguimos redirecionar você para o pagamento, mas não se preocupe, recebemos o seu pedido e vamos entrar em contato por email em breve.',
          '',
          'error',
        );
      },
    };

    if (paymentMethod !== order.payment_method) {
      API.Order.addPaymentMethod(order.id, paymentMethod).then(() =>
        redirectToPaymentPage(order, paymentMethod, options),
      );
    } else {
      redirectToPaymentPage(order, paymentMethod, options);
    }
  };

  const applyStoreCredits = () => {
    API.OrderStoreCredit.create(order.id).then(() => {
      refetch();
      refetchUserStoreCreditsTotal();
    });
  };

  const removeStoreCredits = () => {
    API.OrderStoreCredit.destroy(order.id, '').then(() => {
      refetch();
      refetchUserStoreCreditsTotal();
    });
  };

  const completeWithoutPayment = () => {
    API.Order.update(order.id, {
      status: 'ready',
      comment: 'Pedido pago com créditos na loja.',
    }).then(() => {
      Swal.fire({
        title: 'Sucesso',
        text: 'Seu pedido está pago, agora é só esperar.',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate(`/your-dates/${order.number}`);
      });
    });
  };

  return (
    <Container fluid className="main-content-container px-4">
      <BackButton />
      <Row>
        <Col>
          <Row className="page-header py-4">
            <PageTitle
              title="Sua reserva foi confirmada!"
              subtitle="Agora vai"
              md="12"
              className="ml-sm-auto mr-sm-auto"
            />
          </Row>

          <Row className="m-0">
            <Col className="mb-4 order-number-highlight">
              <h5 className="m-0">Número do pedido: {order.number}</h5>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="8">
          <ReservationDetails order={order} />
        </Col>

        <Col sm="12" md="4">
          <Row>
            <Col className="mb-4">
              <Card>
                <CardHeader className="border-bottom">
                  <h5 className="m-0">Pagamento</h5>
                </CardHeader>
                <CardBody>
                  <Col>
                    <Row>
                      <PaymentSummary
                        order={order}
                        removeStoreCredits={removeStoreCredits}
                      />
                    </Row>

                    {isLoadingUserStoreCreditsTotal && <Loading />}

                    {userStoreCreditsTotal &&
                      userStoreCreditsTotal.total > 0 &&
                      !order.has_store_credits_applied && (
                        <Row className="you-have-credits-section">
                          <Col>
                            <h5 className="card-title">
                              <i className="fas fa-dollar-sign pr-1"></i> Você
                              possui créditos!
                            </h5>

                            <p>
                              Que ótima notícia, você tem{' '}
                              {userStoreCreditsTotal.formatted_total} em
                              créditos. Quer usá-los agora?
                            </p>

                            <Button
                              className="mx-auto"
                              onClick={applyStoreCredits}
                            >
                              Aplicar{' '}
                              {userStoreCreditsTotal.total >
                              order.billing.balance_due
                                ? order.billing.balance_due.formatted
                                : userStoreCreditsTotal.formatted_total}
                            </Button>
                          </Col>
                        </Row>
                      )}

                    {order.billing.balance_due.in_cents > 0 && (
                      <>
                        <Row>
                          <Col>
                            <br />
                            <h5 className="card-title">Método de pagamento:</h5>
                          </Col>
                        </Row>
                        <Row>
                          <div className="payment-method-container">
                            <div
                              className={`payment-method-option ${
                                paymentMethod === 'credit_card'
                                  ? 'selected'
                                  : ''
                              }`}
                              onClick={() => {
                                setPaymentMethod('credit_card');
                              }}
                            >
                              <div className="logo">
                                <i className="mx-1 far fa-credit-card"></i>
                                <i className="mx-1 fas fa-barcode"></i>
                              </div>
                              <div className="text">
                                <p>Cartão ou Boleto</p>
                              </div>
                            </div>
                            <div
                              className={`payment-method-option  ${
                                paymentMethod === 'pix' ? 'selected' : ''
                              }`}
                              onClick={() => setPaymentMethod('pix')}
                            >
                              <div className="logo">
                                <i className="fa-brands fa-pix"></i>
                              </div>
                              <div className="text">
                                <p>PIX</p>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <Row className="mx-auto my-3">
                          <Button
                            pill
                            size="lg"
                            className="mx-auto"
                            onClick={goToPaymentHandler}
                          >
                            Ir para o pagamento
                          </Button>
                        </Row>
                      </>
                    )}

                    {order.billing.balance_due.in_cents <= 0 && (
                      <Row className="mx-auto my-3">
                        <Button
                          pill
                          size="lg"
                          className="mx-auto"
                          onClick={completeWithoutPayment}
                        >
                          Concluir
                        </Button>
                      </Row>
                    )}

                    <p className="gateway-info">
                      * O pagamento será processado pela{' '}
                      {paymentMethod === 'pix' ? 'OpenPix' : 'Stripe'}, uma
                      facilitadora de pagamentos. A Make My Date não terá acesso
                      aos dados bancários fornecidos e receberá apenas a
                      informação sobre o sucesso ou falha do pagamento.
                    </p>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Reservation;
