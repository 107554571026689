import React from 'react';
import { Navbar } from 'shards-react';

import { useDispatch } from 'react-redux';
import { changeMenuVisibility } from 'redux/sidebar/sidebarSlice';

import MainLogo from '../MainLogo';

const SidebarMainNavbar = ({ hideLogoText }) => {
  const dispatch = useDispatch();

  return (
    <div className="main-navbar">
      <Navbar
        className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
        type="light"
      >
        <MainLogo hideLogoText={hideLogoText} />
        {/* eslint-disable-next-line */}
        <a
          className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
          role="button"
          onClick={() => dispatch(changeMenuVisibility())}
        >
          <i className="material-icons">&#xE5C4;</i>
        </a>
      </Navbar>
    </div>
  );
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false,
};

export default SidebarMainNavbar;
