import React from 'react';
import { Button } from 'shards-react';

import { Config } from 'lib/config';

const ActivationAccountInstructions = ({ title, email }) => {
  const imageUrl =
    'https://res.cloudinary.com/makemydate/image/upload/v1649806210/Date/Email_campaign-rafiki_denh2y_h2zcjr.png';

  return (
    <>
      <h2 className="text-center mb-4">{title}</h2>
      <div className="row">
        <div className="col instructions text-center">
          <p>
            Agora é só seguir as instruções que enviamos para
            <strong className="text-primary"> {email}</strong> e ativar sua
            conta :)
          </p>
          <img src={imageUrl} alt="Caça ao tesouro" />

          <div className="hint text-right">
            *Direitos da imagem:{' '}
            <a href="https://storyset.com/communication">Storyset</a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col text-center">
          <Button
            pill
            theme="primary"
            className="m-2 mt-4"
            onClick={() => {
              window.location.href = Config.storeUrl;
            }}
          >
            Voltar para o site
          </Button>
        </div>
      </div>
    </>
  );
};

export default ActivationAccountInstructions;
