import React from 'react';

import 'views/Reservation/styles.scss';

const PaymentSummary = ({ order, removeStoreCredits }) => {
  const { billing } = order;

  return (
    <section className="payment-summary">
      <table className="w-100">
        <tbody>
          <tr>
            <td className="label">Preço:</td>
            <td className="monetary-value">{billing.price.formatted}</td>
          </tr>

          <tr>
            <td className="label">Taxas:</td>
            <td className="monetary-value">R$ 0,00</td>
          </tr>

          {billing.value_paid_with_money.in_cents !== 0 && (
            <tr>
              <td className="label">Valores pagos:</td>
              <td
                className={`monetary-value ${
                  billing.value_paid_with_money.in_cents < 0
                    ? 'to-deduct-from-total'
                    : ''
                }`}
              >
                {billing.value_paid_with_money.formatted}
              </td>
            </tr>
          )}

          {billing.value_paid_with_store_credits.in_cents !== 0 && (
            <tr>
              <td className="label flex-column">
                Créditos aplicados:
                <span
                  className="remove-store-credits-button"
                  onClick={removeStoreCredits}
                >
                  Remover
                </span>
              </td>
              <td
                className={`monetary-value ${
                  billing.value_paid_with_store_credits.in_cents < 0
                    ? 'to-deduct-from-total'
                    : ''
                }`}
              >
                {billing.value_paid_with_store_credits.formatted}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <hr />

      <table className="w-100">
        <tbody>
          <tr>
            <td className="label">Total:</td>
            <td className="monetary-value">{billing.balance_due.formatted}</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default PaymentSummary;
