import React from 'react';
import { Container, Button } from 'shards-react';

import { buildTitle } from 'lib/title';

const ErrorPage = () => {
  document.title = buildTitle('Página não encontrada');

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <div className="error">
        <div className="error__content">
          <h2>404</h2>
          <h3>
            Opa! Não encontramos essa página <span></span>
          </h3>
          <div className="mt-4">
            <Button href="/" pill>
              &larr; Ir para o início
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ErrorPage;
