import React, { useRef, useEffect } from 'react';

function OutsideClickDetector({ visible, onOutsideClick, children }) {
  const wrapperRef = useRef(null);

  useEffect(() => {
    /**
     * Function that will be executed when there is a click anywhere on the
     * page, with the exception of the element that was defined as wrapperRef.
     */
    function handleClickOutside(event) {
      if (
        visible &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        if (onOutsideClick && typeof onOutsideClick === 'function') {
          onOutsideClick();
        }
      }
    }

    // Add event listener
    document.addEventListener('click', handleClickOutside);

    // Remove event listener when component is dismounted
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [wrapperRef, visible, onOutsideClick]);

  return <div ref={wrapperRef}>{children}</div>;
}

export default OutsideClickDetector;
