/* eslint-disable no-empty-character-class */
/* eslint-disable no-param-reassign */

import { calculateAge } from './date-utils';
import { Config } from './config';

export const validateCPF = (cpf, allowEmpty = false) => {
  // We can skip CPF validation using ENV vars
  if (!Config.validateCPF) {
    return true;
  }

  cpf = cpf.replace(/[.-]/g, '');
  let sum = 0;
  let mod;

  if ((cpf === '00000000000' || cpf === '') && !allowEmpty) {
    return false;
  }

  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }

  mod = (sum * 10) % 11;

  if (mod === 10 || mod === 11) {
    mod = 0;
  }

  if (mod !== parseInt(cpf.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }

  mod = (sum * 10) % 11;

  if (mod === 10 || mod === 11) {
    mod = 0;
  }

  if (mod !== parseInt(cpf.substring(10, 11), 10)) {
    return false;
  }

  return true;
};

export const validatePasswordFormat = (password) => {
  // We can skip password format validation using ENV vars
  if (!Config.validatePasswordFormat) {
    return true;
  }

  const valid = password.length >= 8;

  return (
    valid &&
    password.search(/\d/) !== -1 &&
    password.search(/[a-z]/) !== -1 &&
    password.search(/[A-Z]/) !== -1
  );
};

export const validateEmailFormat = (email) => {
  // eslint-disable-next-line no-empty-character-class
  const regex =
    /^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])$/;

  return String(email).toLowerCase().match(regex);
};

export const validatePhoneNumber = (number) => {
  // We can skip phone number validation using ENV vars
  if (!Config.validatePhoneNumber) {
    return true;
  }

  // eslint-disable-next-line no-useless-escape
  const regex = /^(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})$/;

  return String(number).match(regex);
};

export const validateBirthDate = (date) => {
  const today = new Date();
  const birthDate = new Date(date);

  return birthDate < today;
};

export const validateMinimumAge = (birthDate, minimumAge) => {
  const age = calculateAge(birthDate);

  return age >= minimumAge;
};
