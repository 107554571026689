import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormTextarea,
  Button,
} from 'shards-react';

import API from 'api';

import { buildTitle } from 'lib/title';

const ContactDetails = ({ title }) => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState('');

  document.title = buildTitle('Contato');

  const onClickHandler = () => {
    setAlert('');

    API.Contact.create({
      subject,
      message,
    })
      .then(() => {
        setAlert('success');
        setSubject('');
        setMessage('');
      })
      .catch(() => {
        setAlert('error');
      });
  };

  return (
    <Card className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          {alert === 'success' && (
            <div
              className="alert alert-success"
              role="alert"
              data-testid="succes-contact-alert"
            >
              Obrigado por entrar em contato, sua mensagem foi enviada.
            </div>
          )}

          {alert === 'error' && (
            <div className="alert alert-danger" role="alert">
              Não foi possível enviar sua mensagem, tente novamente mais tarde.
            </div>
          )}
          <Row>
            <Col>
              <Form>
                <Row form>
                  {/* First Name */}
                  <Col md="12" className="form-group">
                    <label htmlFor="feSubject">Assunto</label>
                    <FormInput
                      data-testid="contact-subject"
                      id="feSubject"
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                      placeholder="Assunto"
                      value={subject}
                    />
                  </Col>
                </Row>
                <Row form>
                  {/* Message */}
                  <Col md="12" className="form-group">
                    <label htmlFor="feMessage">Mensagem</label>
                    <FormTextarea
                      data-testid="contact-message"
                      id="feMessage"
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      placeholder="Sua mensagem..."
                      rows="5"
                      value={message}
                    />
                  </Col>
                </Row>
                <Button
                  data-testid="send-message-button"
                  disabled={!(subject !== '' && message !== '')}
                  onClick={onClickHandler}
                  theme="accent"
                >
                  Enviar
                </Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

ContactDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

ContactDetails.defaultProps = {
  title: 'Mande sua mensagem',
};

export default ContactDetails;
