import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

import API from 'api';

import { useAuth } from 'contexts/AuthContext';

import './styles.scss';

const ActivationAccountWarning = ({ loggedUser }) => {
  const [user, setUser] = useState(loggedUser);

  const { currentUser, updateProfileDataInMemory } = useAuth();

  useEffect(() => {
    if (!user) {
      API.User.find(currentUser.id).then((response) => {
        const { data: body } = response;
        const userFromBackend = body.data;

        if (userFromBackend !== null) {
          setUser(userFromBackend);
          updateProfileDataInMemory(userFromBackend);
        }
      });
    }
  }, [user, currentUser.id, updateProfileDataInMemory]);

  if (!user) {
    return null;
  }

  const showActivationAccountWarning =
    user.status === 'inactive' || user.status === 'temporary_access';

  if (!showActivationAccountWarning) {
    return null;
  }

  const resendActivationEmail = () => {
    API.Account.resendAccountActivationEmail(currentUser.email).then(() => {
      Swal.fire({
        title: 'Email reenviado!',
        icon: 'success',
      }).then(() => window.location.reload());
    });
  };

  // eslint-disable-next-line no-script-url
  const url = 'javascript:;';

  return (
    <div className="account-activation-warning">
      <div className="account-activation-warning-icon">
        <span className="material-icons-round">warning_amber</span>
      </div>

      <div className="account-activation-warning-text">
        <p>
          Você precisa <strong>ativar sua conta</strong> para continuar tendo
          acesso a tudo o que a Make My Date pode oferecer. Siga as instruções
          que enviamos por email. Caso não tenha recebido o email, veja se não
          está na caixa de spam ou {` `}
          <a href={url} onClick={() => resendActivationEmail()}>
            receba o email novamente
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default ActivationAccountWarning;
