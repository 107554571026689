import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  DatePicker,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'shards-react';
import { useDispatch } from 'react-redux';
import { setBooking } from 'redux/cart/cartSlice';

import ptBR from 'date-fns/locale/pt-BR';
import { weekDaysFromBinary } from 'lib/week-days';
import { registerLocale } from 'react-datepicker';
import { setHours, setMinutes } from 'date-fns';
import { useCart } from 'lib/hooks';

const ReservationCard = ({ bookingValidations }) => {
  const { lineItemParams } = useCart();

  const booking = lineItemParams?.booking;

  const [days, setDays] = useState(weekDaysFromBinary(booking?.weekDays));
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    registerLocale('pt-BR', ptBR);
  }, []);

  const toggleDaysOfTheWeek = () => {
    setOpen(!open);
  };

  const getDayAfterTomorrow = () => {
    const today = new Date();
    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(today.getDate() + 2);
    return dayAfterTomorrow;
  };

  const getEndDate = () => {
    const today = new Date();
    const twoMonthsLater = new Date(today);
    twoMonthsLater.setMonth(today.getMonth() + 2);
    return twoMonthsLater;
  };

  const getTimeFromDate = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;

    return formattedTime;
  };

  const buildDateFromTime = (timeString) => {
    if (!timeString) return null;

    const [hours, minutes] = timeString.split(':').map(Number);

    const today = new Date();

    today.setHours(hours);
    today.setMinutes(minutes);
    today.setSeconds(0);

    return today;
  };

  const selectedDays = Object.keys(days).filter(
    (key) => days[key].state === 'checked',
  );

  const weekDaysPlaceholder =
    selectedDays.length > 0
      ? selectedDays.map((key) => days[key].text.substring(0, 3)).join(', ')
      : 'Dia da semana';

  const onClickWeekDaysHandler = (key) => {
    dispatch(setBooking({ field: 'weekDays', value: formatAvailableDays() }));

    const day = days[key];

    const newState = {
      ...days,
      [key]: {
        text: day.text,
        state: day.state === 'checked' ? 'unchecked' : 'checked',
      },
    };

    setDays(newState);
  };

  const formatAvailableDays = () => {
    const checkWeekDay = (weekDay) =>
      days[weekDay].state === 'checked' ? '1' : '0';

    const binaryNumber = [
      checkWeekDay('sunday'),
      checkWeekDay('monday'),
      checkWeekDay('tuesday'),
      checkWeekDay('wednesday'),
      checkWeekDay('thursday'),
      checkWeekDay('friday'),
      checkWeekDay('saturday'),
    ].join('');

    return parseInt(binaryNumber, 2);
  };

  return (
    <Col lg="12" sm="12" className="mb-4 reservation-card-component">
      <Card>
        <CardHeader className="border-bottom">
          <h5 className="m-0">Sua reserva</h5>
        </CardHeader>
        <CardBody className="py-3">
          <p className="mb-1">
            Para o pré-agendamento, escolha a data a partir da qual o seu date
            pode acontecer, assim como o intervalo de horário do dia e os dias
            da semana disponíveis. Por ser um produto exclusivo, o agendamento é
            feio de forma manual, mas tentaremos ao máximo agendar a sua
            experiência de acordo com as suas preferências.
          </p>
          <Row>
            <Col sm="12" className="py-3">
              <h5 className="card-title">Data e hora</h5>
              <Row className="mx-1" style={{ gap: '2rem' }}>
                <div>
                  <strong>A partir do dia</strong>
                  <br className="mb-2" />
                  <DatePicker
                    selected={
                      booking?.startDate ? new Date(booking?.startDate) : null
                    }
                    onChange={(date) =>
                      dispatch(
                        setBooking({
                          field: 'startDate',
                          value: date.toISOString(),
                        }),
                      )
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Data preferencial"
                    locale="pt-BR"
                    minDate={getDayAfterTomorrow()}
                    maxDate={getEndDate()}
                    className={
                      bookingValidations?.startDate === false
                        ? 'is-invalid'
                        : ''
                    }
                  />
                </div>
                <div>
                  <strong>Entre</strong>
                  <br className="mb-2" />
                  <DatePicker
                    selected={buildDateFromTime(booking?.fromTime)}
                    onChange={(time) => {
                      dispatch(
                        setBooking({
                          field: 'fromTime',
                          value: getTimeFromDate(time),
                        }),
                      );
                    }}
                    className={
                      bookingValidations?.fromTime === false
                        ? 'is-invalid timeSelect'
                        : 'timeSelect'
                    }
                    dateFormat="HH:mm"
                    locale="pt-BR"
                    enableTabLoop={false}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    placeholderText="Horário inicial"
                    timeCaption="Início"
                    timeFormat="HH:mm"
                  />
                  <span className="mx-2">e</span>
                  <DatePicker
                    selected={buildDateFromTime(booking?.toTime)}
                    onChange={(time) => {
                      dispatch(
                        setBooking({
                          field: 'toTime',
                          value: getTimeFromDate(time),
                        }),
                      );
                    }}
                    className={
                      bookingValidations?.toTime === false
                        ? 'is-invalid timeSelect'
                        : 'timeSelect'
                    }
                    dateFormat="HH:mm"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    placeholderText="Horário final"
                    timeCaption="Fim"
                    timeFormat="HH:mm"
                    minTime={
                      booking?.fromTime
                        ? buildDateFromTime(booking?.fromTime)
                        : setHours(setMinutes(new Date(), 0), 0)
                    }
                    maxTime={setHours(setMinutes(new Date(), 59), 23)}
                  />
                </div>
                <div>
                  <strong>No(s) dia(s) </strong>
                  <br className="mb-2" />
                  <Dropdown
                    open={open}
                    toggle={toggleDaysOfTheWeek}
                    className="mr-2"
                  >
                    <DropdownToggle
                      className={
                        bookingValidations?.weekDays === false
                          ? 'is-invalid weekDaysDropdownToggle'
                          : 'weekDaysDropdownToggle'
                      }
                      theme="light"
                      caret
                    >
                      <span className="mr-3">{weekDaysPlaceholder}</span>
                    </DropdownToggle>
                    <DropdownMenu persist className="pb-0">
                      {Object.keys(days).map((key) => {
                        const day = days[key];

                        return (
                          <DropdownItem
                            toggle={false}
                            onClick={() => onClickWeekDaysHandler(key)}
                            key={key}
                          >
                            {day.state === 'checked' ? (
                              <span
                                className="material-icons text-success align-bottom"
                                style={{ fontSize: '22px' }}
                              >
                                check
                              </span>
                            ) : (
                              ''
                            )}
                            {day.text}
                          </DropdownItem>
                        );
                      })}
                      <DropdownItem
                        onClick={() => {
                          dispatch(
                            setBooking({
                              field: 'weekDays',
                              value: formatAvailableDays(),
                            }),
                          );
                        }}
                        className="confirmWeekDaysButton btn btn-primary"
                      >
                        Confirmar
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </Row>
            </Col>
            <Col sm="12" className="py-3">
              <h5 className="card-title">Comentário</h5>
              <p className="mb-2">
                Se desejar, você pode deixar um comentário para a gente:
              </p>
              <div className="form-floating">
                <textarea
                  className="form-control"
                  type="text"
                  placeholder="É uma data especial com um pedido especial? Alguma restrição?
              Dúvidas? Coloque aqui aquilo que acha que devemos saber ou ter para
              deixar sua experiência ainda mais inesquecível!"
                  style={{ height: '8rem' }}
                  onChange={(e) =>
                    dispatch(
                      setBooking({ field: 'comment', value: e.target.value }),
                    )
                  }
                  value={booking?.comment}
                  data-testid="schedule-comment-field"
                ></textarea>
              </div>
              <p
                className="card-text text-muted d-inline-block my-3"
                style={{ fontSize: '0.875rem' }}
              >
                Caso o seu pedido venha a gerar algum custo adicional você será
                avisado previamente e ele só será acrescentado no seu pedido a
                partir do seu aceite. Mas não se preocupe, só faremos a inclusão
                após entrarmos em contato e discutirmos propostas, ok?
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ReservationCard;
