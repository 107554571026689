const MONTHS = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro',
};

const WEEK_DAYS = {
  1: 'Domingo',
  2: 'Segunda-feira',
  3: 'Terça-feira',
  4: 'Quarta-feira',
  5: 'Quinta-feira',
  6: 'Sexta-feira',
  7: 'Sábado',
};

const convertToTimezone = (date, tzString) => {
  // eslint-disable-next-line global-require
  const moment = require('moment-timezone');
  const utcDate = moment.utc(date);
  return utcDate.clone().tz(tzString);
};

export const formatDate = (date) => {
  const brDate = convertToTimezone(date, 'America/Sao_Paulo').toDate();
  return `${brDate.getDate()} de ${
    MONTHS[brDate.getMonth()]
  } de ${brDate.getFullYear()}`;
};

export const formatDateWithDayAndMonth = (date) => {
  const brDate = convertToTimezone(date, 'America/Sao_Paulo').toDate();
  const day = brDate.getDate().toString().padStart(2, '0');
  const month = (brDate.getMonth() + 1).toString().padStart(2, '0');
  return `${day}/${month}`;
};

export const formatDateWithWeekDay = (date) => {
  const brDate = convertToTimezone(date, 'America/Sao_Paulo').toDate();

  return WEEK_DAYS[brDate.getDay()];
};

export const getTimeFromDate = (date) => {
  const brDate = convertToTimezone(date, 'America/Sao_Paulo').toDate();
  const hours = brDate.getHours().toString().padStart(2, '0');
  const minutes = brDate.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};

export const formatDateTimeUsingSlashes = (date, includeTime = false) => {
  const brDate = convertToTimezone(date, 'America/Sao_Paulo').toDate();
  const d = brDate.getDate().toString().padStart(2, '0');
  const m = (brDate.getMonth() + 1).toString().padStart(2, '0');
  const y = brDate.getFullYear();
  const h = brDate.getHours().toString().padStart(2, '0');
  const min = brDate.getMinutes().toString().padStart(2, '0');

  if (includeTime) {
    return `${d}/${m}/${y} ${h}h${min}`;
  }

  return `${d}/${m}/${y}`;
};

export const formatDateTimeUsingSlashesWithTimeAndSeconds = (date) => {
  const parts = dateParts(date);
  return `${parts.day}/${parts.month}/${parts.year} ${parts.hour}:${parts.minute}:${parts.second}`;
};

export const dateParts = (date) => {
  const brDate = convertToTimezone(date, 'America/Sao_Paulo').toDate();
  const d = brDate.getDate().toString().padStart(2, '0');
  const m = (brDate.getMonth() + 1).toString().padStart(2, '0');
  const y = brDate.getFullYear();
  const h = brDate.getHours().toString().padStart(2, '0');
  const min = brDate.getMinutes().toString().padStart(2, '0');
  const sec = brDate.getSeconds().toString().padStart(2, '0');

  return {
    day: d,
    month: m,
    year: y,
    hour: h,
    minute: min,
    second: sec,
  };
};

export const calculateAge = (dateOfBirth, today = new Date()) => {
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();

  if (month < 0 || (month === 0 && today.getDate() <= birthDate.getDate())) {
    age -= 1;
  }

  return age;
};
