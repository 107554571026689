import * as React from 'react';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepConnector from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

const VerticalStepper = ({
  activeStep,
  hasSpecial,
  onClick,
  steps,
  validationStatus,
}) => (
  <Box sx={{ maxWidth: 300 }} className="vertical-stepper-component">
    <Stepper
      activeStep={activeStep}
      className="cursor-pointer"
      connector={steps.length === 1 ? null : <StepConnector />}
      orientation="vertical"
      sx={{
        '& .MuiStepConnector-root': { marginLeft: '20px' },
      }}
    >
      {steps.map((step) => (
        <Step
          className="cursor-pointer"
          key={`${step.title}-${step.index}`}
          onClick={() => onClick(step.index)}
          style={{ position: 'relative' }}
          completed={validationStatus[step.index] === 'valid'}
          index={step.index}
        >
          <StepLabel
            className="cursor-pointer"
            sx={{
              '& .MuiStepIcon-root': {
                width: '40px',
                height: '40px',
                padding: '4px',
                cursor: 'pointer',
              },
              '& .MuiStepIcon-root.Mui-active': {
                color: getComputedStyle(document.body).getPropertyValue(
                  '--primary',
                ),
              },
              '& .MuiStepIcon-root.Mui-completed': {
                color: getComputedStyle(document.body).getPropertyValue(
                  '--primary-light',
                ),
              },
            }}
            optional={
              <Typography variant="caption">
                {!step.final
                  ? `Item ${step.index + 1}/${steps.length}`
                  : hasSpecial && 'Com produto especial'}
              </Typography>
            }
          >
            {validationStatus[step.index] === 'invalid' && (
              <span
                className="badge badge-pill badge-danger"
                style={{ position: 'absolute', left: '20px', fontSize: '10px' }}
              >
                <i className="fa-solid fa-exclamation"></i>
              </span>
            )}

            {step.removed ? <s>{step.title}</s> : step.title}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
);
export default VerticalStepper;
