import { setCookie, getCookie, removeCookie } from '../lib/cookies';

export const getAccessToken = () => localStorage.getItem('access-token');
export const setAccessToken = (accessToken) =>
  localStorage.setItem('access-token', accessToken);
export const removeAccessToken = () => localStorage.removeItem('access-token');

export const getRefreshToken = () => getCookie('ruid');
export const setRefreshToken = (refreshToken) =>
  setCookie('ruid', refreshToken);
export const removeRefreshToken = () => removeCookie('ruid');
