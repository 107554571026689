import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Row, Col, Card, CardBody, CardHeader, Button } from 'shards-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import API from 'api';
import { Config } from 'lib/config';
import { setCurrentLineItemIndex } from 'redux/cart/cartSlice';

import { useCart } from 'lib/hooks';
import { confirm } from 'lib/notifications';
import EmptyCartImage from 'assets/images/empty-cart.png';
import PaymentSummary from 'components/payment-summary/PaymentSummary';
import SpecialItem from './SpecialItem';
import UserData from './UserData';

const CompletePreBooking = ({
  order,
  specialItems,
  user,
  setUser,
  validUserAddress,
  setValidUserAddress,
}) => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const {
    allLineItemsParams,
    lineItemIndex: currentLineItemIndex,
    specialItemsParams,
  } = useCart();

  const countEligibleItemsInTheCart = () =>
    Object.keys(specialItemsParams).filter(
      (id) => !specialItemsParams[id]?.removed,
    ).length;

  const countEligibleLineItems = () =>
    allLineItemsParams.filter((item) => !item.removed).length +
    countEligibleItemsInTheCart();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [lineItemsStillOnCartCount, setLineItemsStillOnCartCount] = useState(
    countEligibleLineItems(),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setLineItemsStillOnCartCount(countEligibleLineItems());
  }, [specialItemsParams, allLineItemsParams]);

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const invalidParams = allLineItemsParams?.filter(
        (lineItemParams) => lineItemParams.validationStatus !== 'valid',
      );

      setIsValid(invalidParams.length === 0);
    }
  }, [isLoaded]);

  const validAddressForm = () => {
    const isValidStreet = user.address.street.trim().length > 0;
    const isValidNumber = user.address.number.trim().length > 0;
    const isValidCity = user.address.city.trim().length > 0;
    const isValidStateId = parseInt(user.address.stateId, 10) > 0;
    const isValidZipCode = user.address.zipCode.trim().length > 0;

    setValidUserAddress({
      street: isValidStreet,
      number: isValidNumber,
      complement: true,
      city: isValidCity,
      stateId: isValidStateId,
      zipCode: isValidZipCode,
    });

    return (
      isValidStreet &&
      isValidNumber &&
      isValidCity &&
      isValidStateId &&
      isValidZipCode
    );
  };

  const onClickHandler = async () => {
    if (validAddressForm()) {
      const removedLineItems = allLineItemsParams.filter(
        (item) => item.removed,
      );
      const lineItemsInTheCart = allLineItemsParams.filter(
        (item) => !item.removed,
      );

      const hasSpecialItensInTheCart = countEligibleLineItems() > 0;

      if (removedLineItems.length > 0) {
        await Promise.all(
          removedLineItems.map(async (item) => {
            API.Order.removeFromCart(order.id, item.id, 'store_credits_only');
          }),
        );
      }

      if (lineItemsInTheCart.length === 0 && !hasSpecialItensInTheCart) {
        confirm({
          title: 'Pedido sem itens',
          text: 'Como não há mais itens no seu carrinho, ele será finalizado.',
          onConfirm: () => {
            window.location.href = `${Config.storeUrl}/dates`;
          },
        });
      } else {
        const replaceBookingKeys = (lineItems) =>
          lineItems.map((item) => {
            if (!item.booking) {
              return item;
            }

            return {
              ...item,
              booking: {
                start_date: item.booking.startDate,
                from_time: item.booking.fromTime,
                to_time: item.booking.toTime,
                week_days: item.booking.weeDays,
                comment: '',
              },
            };
          });

        const data = {
          status: 'placed',
          street_address: user.address.street,
          address_number: user.address.number,
          address_complement: user.address.complement,
          city: user.address.city,
          state_id: user.address.stateId,
          zip_code: user.address.zipCode,
          line_items: replaceBookingKeys([...lineItemsInTheCart]),
        };

        API.Order.placeOrder(order.id, data)
          .then((response) => {
            MySwal.fire({
              title: <strong>Pré-agendamento confirmado</strong>,
              html: (
                <div>
                  <p>Você ainda não fará o pagamento.</p>
                  <p style={{ textAlign: 'justify', marginBottom: '0.75rem' }}>
                    Nós vamos encontrar o melhor dia para a sua experiência
                    baseado nas suas preferências.
                  </p>
                  <p style={{ textAlign: 'justify' }}>
                    Em breve você receberá um e-mail para confirmar a data e
                    realizar o pagamento.
                  </p>
                </div>
              ),
              icon: 'success',
            }).then(() => {
              navigate(`/your-dates/${response.data.number}`);
            });
          })
          .catch(() => {
            MySwal.fire({
              title: <strong>Oops</strong>,
              html: (
                <i>Alguma coisa deu errada, tente novamente mais tarde :(</i>
              ),
              icon: 'error',
            });
          });
      }
    } else {
      MySwal.fire({
        title: <strong>Alto lá</strong>,
        html: <i>Preencha os campos em vermelho para continuar.</i>,
        icon: 'error',
      });
    }
  };

  const orderForPaymentSummary = () => {
    const newOrder = {
      ...order,
      line_items: order.line_items.filter(
        (li) =>
          (li.type === 'variant' &&
            allLineItemsParams.find(
              (item) => item.id === li.id && !item.removed,
            )) ||
          (li.type === 'gift_card' && !specialItemsParams[li.id]?.removed),
      ),
    };

    const sum = newOrder.line_items.reduce((acc, item) => {
      // eslint-disable-next-line no-param-reassign
      acc += item.billing.price_with_discount.in_decimal;
      return acc;
    }, 0);

    const formatted = `R$ ${sum.toFixed(2)}`.replace('.', ',');

    const billing = {
      price: { formatted },
      value_paid_with_money: { in_cents: 0 },
      value_paid_with_store_credits: { in_cents: 0 },
      balance_due: { formatted },
    };

    return { ...newOrder, billing };
  };

  return (
    <Col
      className={`complete-pre-booking-component col-12 ${
        isLoaded ? 'loaded' : ''
      }`}
    >
      {specialItems?.length > 0 && <h5 className="ml-3">Produtos Especiais</h5>}
      {specialItems.map((item) => (
        <SpecialItem item={item} key={JSON.stringify(item)} />
      ))}

      {lineItemsStillOnCartCount > 0 && (
        <>
          <Col lg="12" sm="12" className="mb-4">
            <UserData
              validAddress={validUserAddress}
              address={user.address}
              user={user}
              setUser={setUser}
            />
          </Col>

          <Col lg="12" sm="12" className="mb-4">
            <Card>
              <CardHeader className="border-bottom">
                <h5 className="m-0">Instruções</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="8" className="mb-3">
                    <Row>
                      <Col>
                        <h5 className="card-title">
                          Aguardar a confirmação do agendamento
                        </h5>
                        <p className="card-text d-inline-block mb-1">
                          <strong>
                            Após a sua confirmação abaixo, faremos a sua
                            pré-reserva dentro dos limites do seu
                            pré-agendamento. Com isso, enviaremos um e-mail com
                            a data e horário oficiais para a sua reserva, que
                            você poderá aceitar ou pedir uma revisão. Caso
                            aceite, o pagamento para o seu date estará liberado.
                          </strong>
                        </p>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <h5 className="card-title">Fazer o pagamento</h5>
                        <p className="card-text d-inline-block mb-1">
                          <strong>
                            Ao aceitar a data e horário propostos, a página de
                            pagamento será liberada. Assim que o pagamento for
                            confirmado, sua reserva estará completa e só faltará
                            mais um passo: aproveitar!
                          </strong>
                        </p>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <h5 className="card-title">Aproveite</h5>
                        <p className="card-text d-inline-block mb-3">
                          <strong>
                            Com o pagamento confirmado, você receberá também os
                            detalhes da localização da sua experiência e só
                            restará aparecer na data e horário combinados e
                            aproveitar muito essa experiência única com quem
                            você gosta!
                          </strong>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="4">
                    <PaymentSummary order={orderForPaymentSummary()} />
                    <small className="pl-2">
                      *Importante: os preços podem mudar até que os pedidos
                      sejam concluídos. Iremos avisá-los se isso acontecer.
                    </small>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </>
      )}

      {lineItemsStillOnCartCount === 0 && (
        <Col>
          <Card className="empty-cart-card">
            <CardHeader className="border-bottom"></CardHeader>
            <CardBody>
              <div className="image-container">
                <img src={EmptyCartImage} alt="carrinho vazio" />
              </div>

              <div className="removed text-center mt-4 empty-warning">
                <h5>Seu carrinho está vazio</h5>
              </div>
            </CardBody>
          </Card>
        </Col>
      )}

      <Row className="d-flex justify-content-center mx-auto my-4 flex-column-reverse flex-sm-row">
        <Button
          pill
          outline
          theme="secondary"
          size="lg"
          className="m-2"
          onClick={() =>
            dispatch(
              dispatch(setCurrentLineItemIndex(currentLineItemIndex - 1)),
            )
          }
        >
          Voltar
        </Button>

        {lineItemsStillOnCartCount > 0 && (
          <Button
            pill
            size="lg"
            className="m-2"
            onClick={onClickHandler}
            data-testid="prebooking-confirmation-button"
            disabled={!isValid}
          >
            Confirmar pré-agendamento
            {!isValid && <span>*</span>}
          </Button>
        )}

        {lineItemsStillOnCartCount === 0 && (
          <Button pill size="lg" className="m-2" onClick={onClickHandler}>
            Abandonar carrinho
          </Button>
        )}
      </Row>

      <div className="w-100">
        {lineItemsStillOnCartCount > 0 && !isValid && (
          <Col>
            <p className="text-center">
              <small>* Confira os campos indicados nos passos anteriores</small>
            </p>
          </Col>
        )}

        {lineItemsStillOnCartCount === 0 && (
          <div className="text-center">
            <small>* Não há mais items no seu carrinho</small>
          </div>
        )}
      </div>
    </Col>
  );
};

export default CompletePreBooking;
