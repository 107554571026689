/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ListGroupItem } from 'shards-react';
import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/loading/Loading';
import Timeline from 'components/timeline/Timeline';
import { formatDateTimeUsingSlashes } from 'lib/date-utils';
import { useAuth } from 'contexts/AuthContext';

import './styles.scss';

const OPERATIONS = {
  credit: 'Crédito',
  debit: 'Débito',
  refund: 'Devolução',
  inactivation: 'Desativação',
  expiration: 'Expiração',
};

const StoreCredits = () => {
  const [showStatement, setShowStatement] = useState(false);
  const [showExpirations, setShowExpirations] = useState(false);
  const [statement, setStatement] = useState();
  const [expirations, setExpirations] = useState();
  const { currentUser } = useAuth();

  const fetchUserStoreCreditsTotal = async (userId) => {
    const result = await API.UserStoreCredit.all(userId);
    return result.data;
  };

  const { data: userStoreCreditsTotal, isLoading } = useQuery(
    ['user-store-credits-total', currentUser.id],
    () => fetchUserStoreCreditsTotal(currentUser.id),
  );

  if (!userStoreCreditsTotal || isLoading) {
    return (
      <div className="store-credits-container">
        <Loading />
      </div>
    );
  }

  const closeStatementModal = () => setShowStatement(false);
  const closeExpirationsModal = () => setShowExpirations(false);

  const openStatementModal = () => {
    API.UserStoreCredit.statement(currentUser.id).then((response) => {
      const { data: body } = response;
      setStatement(body.data);
      setShowStatement(true);
    });
  };

  const openExpirationsModal = () => {
    API.StoreCredit.all(currentUser.id).then((response) => {
      const { data: body } = response;
      setExpirations(body.data);
      setShowExpirations(true);
    });
  };

  const buildTimelineEventTitle = (item) => (
    <>
      <i className={`material-icons ${item.operation}-operation`}>paid</i>
      <span>&nbsp;{OPERATIONS[item.operation]}</span>
    </>
  );

  const buildOrderLink = (number) => (
    <a href={`/your-dates/${number}`}>pedido {number}</a>
  );

  const buildParagraph = (storeCreditLog) => {
    const { operation, amount, order: orderNumber } = storeCreditLog;
    const formattedAmount = storeCreditLog.formatted.amount;
    const plural = amount > 1;

    switch (operation) {
      case 'credit':
        if (plural) {
          return <span>{formattedAmount} foram adicionados na sua conta.</span>;
        }

        return <span>{formattedAmount} foi adicionado na sua conta.</span>;
      case 'debit':
        if (plural) {
          return (
            <span>
              {formattedAmount} foram retirados da sua conta e aplicados
              no&nbsp;
              {buildOrderLink(orderNumber)}.
            </span>
          );
        }

        return (
          <span>
            {formattedAmount} foi retirado da sua conta e aplicado no&nbsp;
            {buildOrderLink(orderNumber)}.
          </span>
        );
      case 'refund':
        if (plural) {
          return (
            <span>
              {formattedAmount} foram retirados do {buildOrderLink(orderNumber)}{' '}
              e devolvidos para você.
            </span>
          );
        }

        return (
          <span>
            {formattedAmount} foi retirado do {buildOrderLink(orderNumber)} e
            devolvido para você.
          </span>
        );

      case 'inactivation':
        if (plural) {
          return <span>{formattedAmount} foram desativados da sua conta.</span>;
        }

        return <span>{formattedAmount} foi desativado da sua conta.</span>;

      case 'expiration':
        if (plural) {
          return <span>{formattedAmount} expiraram e foram desativados.</span>;
        }

        return <span>{formattedAmount} expirou e foi desativado.</span>;

      default:
        return '';
    }
  };

  const buildTimelineEventDescription = (storeCreditLog) => (
    <p>{buildParagraph(storeCreditLog)}</p>
  );

  const findStoreCreditsWithExpiration = () =>
    expirations.filter(
      (storeCredit) =>
        storeCredit.active &&
        storeCredit.expires_at &&
        storeCredit.remaining_amount > 0,
    );

  const storeCreditsWithExpiration = expirations
    ? findStoreCreditsWithExpiration()
    : [];

  const showStoreCreditWithExpiration = (storeCredit) => (
    <>
      <span>* </span>
      <strong>{storeCredit.formatted.remaining_amount}</strong>
      <span>
        {storeCredit.remaining_amount > 1 ? ' expiram em' : ' expira em'}{' '}
      </span>
      {formatDateTimeUsingSlashes(storeCredit.expires_at)}
      <br />
    </>
  );

  return (
    <ListGroupItem className="px-4">
      <div>
        <strong className="text-muted d-block mb-2">Créditos na loja</strong>
        <div className="d-flex pointer highlight-on-hover">
          <i className="material-icons big-icon" onClick={openStatementModal}>
            paid
          </i>
          <h5 className="mb-0" onClick={openStatementModal}>
            {userStoreCreditsTotal.formatted_total.replace('R$ ', '')}
          </h5>
          <small
            className="text-muted ml-auto my-auto see-statement"
            onClick={openExpirationsModal}
          >
            Ver datas de expiração
          </small>
        </div>

        <Modal
          show={showStatement}
          onHide={closeStatementModal}
          backdrop="static"
          className="store-credits-modal"
        >
          <Modal.Header>
            <Modal.Title>Extrato de créditos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!statement && <Loading />}
            {statement && (
              <div className="store-credits-timeline">
                <Timeline
                  items={statement}
                  eventTitle={buildTimelineEventTitle}
                  eventDate={(item) =>
                    formatDateTimeUsingSlashes(item.inserted_at, true)
                  }
                  eventDescription={buildTimelineEventDescription}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={closeStatementModal}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showExpirations}
          onHide={closeExpirationsModal}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>Data de expiração dos créditos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!expirations && <Loading />}
            {expirations &&
              storeCreditsWithExpiration.length > 0 &&
              storeCreditsWithExpiration.map(showStoreCreditWithExpiration)}

            {expirations && storeCreditsWithExpiration.length === 0 && (
              <p>Eba! Nenhum de seus créditos tem data para expirar.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={closeExpirationsModal}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </ListGroupItem>
  );
};

export default StoreCredits;
