import React from 'react';
import { Button } from 'shards-react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import API from 'api';

import { getQueryParameter } from 'lib/query-string';

import './styles.scss';
import SeeMyOrderButton from './SeeMyOrderButton';

const PaymentProcessorCallback = () => {
  const navigate = useNavigate();

  const { status } = useParams();

  const fetchOrder = async (id) => {
    const result = await API.Order.find(id);
    const { data: body } = result;
    return body.data;
  };

  const orderId = getQueryParameter('ref');
  const { data: order, isLoading } = useQuery(['get-order', orderId], () =>
    fetchOrder(orderId),
  );

  const showCircles = () => (
    <ul className="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  );

  const showSuccess = () => (
    <div className="row">
      <div className="card border-primary mb-3" style={{ maxWidth: '500px' }}>
        <div className="card-header">
          <div className="area">
            <div className="header-title">Parabéns!</div>
            {showCircles()}
          </div>
        </div>
        <div className="card-body">
          <h5 className="card-title">
            <i className="fas fa-trophy"></i>O pedido {order.number} está pago!
          </h5>
          <p>
            Você receberá um email com os detalhes do pagamento. Agora, tudo o
            que tem que fazer é relaxar e esperar a data do seu date, o resto é
            com a gente.
          </p>
          <div>
            <p>O que você quer fazer agora?</p>
            <p className="actions">
              <SeeMyOrderButton order={order} />

              <Button
                onClick={() => {
                  navigate('/user-profile');
                }}
                theme="secondary"
              >
                <i className="fas fa-user"></i>
                Ir para meu perfil
              </Button>
            </p>
          </div>
          <p className="payment-processor-disclaimer">
            O pagamento foi processado pela <br />
            {order.payment_method === 'credit_card' ? (
              <i className="fab fa-stripe"></i>
            ) : (
              <>
                <i className="fa-brands fa-pix"></i>&nbsp; OpenPIX
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );

  const showFailure = () => (
    <div className="row">
      <div className="card border-primary mb-3" style={{ maxWidth: '500px' }}>
        <div className="card-header">
          <div className="area failure">
            <div className="header-title">Atenção</div>
            {showCircles()}
          </div>
        </div>
        <div className="card-body">
          <h5 className="card-title">
            <i className="fas fa-exclamation-triangle"></i> Não conseguimos
            realizar o pagamento
          </h5>
          <p>
            O serviço de pagamento parece estar indisponível, mas não se
            preocupe. Você pode tentar novamente mais tarde, mas se o erro
            persistir <a href="/contact">entre em contato com a gente</a>.
          </p>
          <div>
            <p>O que você quer fazer agora?</p>
            <p className="actions">
              <SeeMyOrderButton order={order} />

              <Button
                onClick={() => {
                  navigate(`/your-reservation?ref=${order.id}`);
                }}
                theme="warning"
              >
                <i className="fas fa-credit-card"></i>
                Tentar novamente
              </Button>
            </p>
          </div>
          <p className="payment-processor-disclaimer">
            O pagamento será processado pela <br />
            <i className="fab fa-stripe"></i>
          </p>
        </div>
      </div>
    </div>
  );

  const showLoading = () => (
    <div className="row">
      <div className="card border-primary mb-3" style={{ maxWidth: '500px' }}>
        <div className="card-header">
          <div className="area loading">
            <div className="header-title">
              <i className="fas fa-spinner fa-spin"></i>
              Aguarde
            </div>
            {showCircles()}
          </div>
        </div>
        <div className="card-body">
          <h5 className="card-title">Finalizando o pagamento</h5>
          <p>
            Estamos nos comunicando com o serviço de pagamentos e este processo
            pode demorar alguns segundos. Por favor, não feche esta janela até
            que o processo seja concluído.
          </p>
          <p className="payment-processor-disclaimer">
            O pagamento será processado pela <br />
            <i className="fab fa-stripe"></i>
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div id="payment-processor-callback">
      {isLoading && showLoading()}
      {!isLoading && status === 'sucesso' && showSuccess()}
      {!isLoading && status === 'falha' && showFailure()}
    </div>
  );
};

export default PaymentProcessorCallback;
