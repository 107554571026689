const sidebarNavItems = () => [
  {
    title: 'Seus Dates',
    htmlBefore: '<i class="material-icons">vertical_split</i>',
    to: '/your-dates',
  },
  {
    title: 'Seu Perfil',
    htmlBefore: '<i class="material-icons">person</i>',
    to: '/user-profile',
  },
  {
    title: 'Cartão-presente',
    htmlBefore: '<i class="material-icons">redeem</i>',
    to: '/gift-cards',
  },
  {
    title: 'Contato',
    htmlBefore: '<i class="material-icons">contact_mail</i>',
    to: '/contact',
  },
];

export default sidebarNavItems;
