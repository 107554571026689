/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Collapse,
  Tooltip,
} from 'shards-react';
import Accordion from 'react-bootstrap/Accordion';

import AdditionalInfo from 'views/DatesList/details/AdditionalInfo';
import { BADGE_INFO } from 'lib/badge-info';
import { formatDate, getTimeFromDate } from 'lib/date-utils';
import { hideCPF } from 'lib/masks';
import { showDateImage as showImage } from 'lib/date-image';
import { weekDaysFromBooking } from 'lib/week-days';

import ChangesRequested from './ChangesRequested';

import 'views/DatesList/styles.scss';

const LineItem = ({ lineItem, order, refetch }) => {
  const [additionalInfoVisible, setAdditionalInfoVisible] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const toggleTooltip = () => {
    setOpenTooltip((o) => !o);
  };

  const toggleAdditionalInfo = () => {
    setAdditionalInfoVisible(!additionalInfoVisible);
  };

  const badge = (options) => {
    let klass = 'item-badge';
    let id = `statusBadge_${lineItem.id}`;
    let href = null;

    if (options.mobile) {
      klass = `${klass}-mobile`;
      id = `mobile_${id}`;
    }

    if (lineItem.status === 'waiting_for_payment') {
      href = `/your-reservation?ref=${order.id}`;
      klass = `${klass} pointer`;
    }

    const currentBadge = () => (
      <Badge
        pill
        id={id}
        className={`${klass} ${BADGE_INFO[lineItem.status].klass}`}
        style={
          lineItem.status === 'booked'
            ? { animation: 'green-pulse 2s infinite' }
            : {}
        }
      >
        {BADGE_INFO[lineItem.status].text}
      </Badge>
    );

    if (href) {
      // eslint-disable-next-line react/jsx-no-target-blank
      return (
        <a target="_blank" href={href}>
          {currentBadge()}
        </a>
      );
    }

    return currentBadge();
  };

  const weekDayMessage = () => {
    const weekDays = weekDaysFromBooking(lineItem.booking);
    const prefix = weekDays.includes(', ')
      ? 'Dias da semana possíveis:'
      : 'Dia da semana:';

    return (
      <>
        <strong>{prefix} </strong>
        {weekDays}
      </>
    );
  };

  const { billing } = lineItem;

  const buildLabel = (title, text) => (
    <>
      <strong>{title}: </strong>
      {text}
      <br />
    </>
  );

  const showMaskedCPF = (cpf) => {
    if (cpf === '') {
      return '';
    }

    return hideCPF(cpf);
  };

  return (
    <Accordion.Item eventKey={lineItem.id}>
      <Card
        small
        className="card-post card-post--aside card-post--1 flex-flow-column overflow-hidden"
      >
        <Accordion.Header as="div">
          <CardHeader className="d-none d-md-flex px-1">
            <span className="material-icons expand-icon card-title my-auto mr-1 text-fiord-blue">
              expand_more
            </span>
            <CardTitle className="mb-1">{lineItem.title}</CardTitle>
            {lineItem.type === 'variant' && (
              <h5 className="card-title mb-1 ml-1">
                - {billing.total.formatted}
              </h5>
            )}
          </CardHeader>
          <CardHeader className="d-md-none d-flex pl-2">
            <span className="material-icons expand-icon card-title my-auto mr-1 text-fiord-blue">
              expand_more
            </span>
            {lineItem.type === 'variant' ? (
              <CardTitle className="mb-1">{lineItem.product_title}</CardTitle>
            ) : (
              <CardTitle className="mb-1">
                {lineItem.title.split('-').shift()}
              </CardTitle>
            )}
          </CardHeader>
          <CardBody className="expand-phrase d-none d-md-flex py-0 pb-2 justify-content-between text-muted">
            Clique para ver detalhes
            <span>
              <strong>Criado em</strong> {formatDate(lineItem.inserted_at)}
            </span>
          </CardBody>
        </Accordion.Header>
        <Accordion.Body className="d-flex flex-column">
          <div className="inherit column-mobile-md">
            <div
              className="pointer card-post__image__2"
              style={{ backgroundImage: `url('${showImage(lineItem)}')` }}
            >
              {badge({ mobile: true })}
            </div>
            <CardBody className="date-list-item-info d-flex border-bottom">
              <div className="card-text-2">
                <h5 className="card-title mb-1">
                  {lineItem.type === 'gift_card'
                    ? lineItem.title.split('-').shift()
                    : lineItem.product_title}
                </h5>
                <p>Item {lineItem.number}</p>
                <div className="card-text d-inline-block mb-3">
                  {lineItem.type === 'variant' && (
                    <>
                      <strong>Opção escolhida:</strong> {lineItem.variant_title}
                      <br />
                    </>
                  )}
                  {lineItem.type === 'gift_card' && (
                    <>
                      <strong>Para:</strong> {lineItem.recipient_name} (
                      {lineItem.recipient_email})
                      <br />
                      <strong>Mensagem:</strong> &quot;{lineItem.message}
                      &quot;
                      <br />
                    </>
                  )}
                  <div>
                    <strong>Preço: </strong>
                    {billing.has_discount ? (
                      <span>
                        {billing.price_with_discount.formatted}
                        <strike className="text-muted ml-1">
                          {billing.price.formatted}
                        </strike>
                      </span>
                    ) : (
                      <span>{billing.price_with_discount.formatted}</span>
                    )}
                  </div>
                  {billing.children_total_price && (
                    <>
                      <strong>Preço dos pedidos adicionais: </strong>
                      {billing.children_total_price.formatted} <br />
                    </>
                  )}
                  {billing.balance_due > 0 && (
                    <>
                      <strong>Valor a pagar: </strong>
                      {billing.balance_due.formatted} <br />
                    </>
                  )}
                  {billing.balance_due < 0 && (
                    <>
                      <strong>Valor a receber: </strong>
                      {billing.balance_due.formatted} <br />
                    </>
                  )}
                </div>
              </div>
              <div className="date-list-item-details">
                <div>
                  <span className="text-muted">
                    <strong>Criado em</strong>{' '}
                    {formatDate(lineItem.inserted_at)}
                  </span>

                  <br />
                  <span className="text-muted">
                    <strong>Atualizado por último em </strong>
                    {formatDate(lineItem.updated_at)}
                  </span>

                  {lineItem.status === 'waiting_for_payment' &&
                    lineItem.expires_at && (
                      <>
                        <br />
                        <span className="text-muted">
                          <strong>Expira em</strong>{' '}
                          {formatDate(lineItem.expires_at)}
                        </span>
                      </>
                    )}
                </div>
                {badge({ mobile: false })}
                <Tooltip
                  open={openTooltip}
                  target={`#statusBadge_${lineItem.id}`}
                  toggle={toggleTooltip}
                >
                  {BADGE_INFO[lineItem.status].tooltip}
                </Tooltip>
              </div>
            </CardBody>
          </div>

          <div className="d-flex flex-wrap w-100">
            {lineItem.type === 'variant' && !lineItem.scheduled_for && (
              <section className="card-footer flex-grow-1">
                <h6>Preferências para o agendamento</h6>
                <div>
                  <strong>A partir de </strong>
                  {formatDate(lineItem.booking.start_date)}
                  <br className="mb-2" />
                  <strong>Entre </strong>
                  {lineItem.booking.from_time} e {lineItem.booking.to_time}
                  <br className="mb-2" />
                  {weekDayMessage()}
                  <br className="mb-2" />
                  <strong>Seu comentário: </strong>
                  <p className="mb-1">{lineItem.booking.comment}</p>
                </div>
              </section>
            )}

            {lineItem.scheduled_for && (
              <section className="card-footer flex-grow-1">
                <h6>Agendamento</h6>
                <div>
                  <strong>Agendado para </strong>
                  {formatDate(lineItem.scheduled_for)} às{' '}
                  {getTimeFromDate(lineItem.scheduled_for)}
                  <br className="mb-2" />
                  <strong>Seu comentário: </strong>
                  <p className="mb-1">&quot;{lineItem.booking.comment}&quot;</p>
                </div>
              </section>
            )}
            {lineItem.children?.length > 0 && (
              <section className="card-footer flex-grow-1">
                <h6>Pedidos adicionais:</h6>
                <ul>
                  {lineItem.children.map((child) => (
                    <li key={child.id}>
                      {child.quantity}x {child.title} -{' '}
                      {child.billing.price_with_discount.formatted}
                    </li>
                  ))}
                </ul>
              </section>
            )}
            {lineItem.type === 'variant' && (
              <section className="card-footer flex-grow-1">
                <h6>Participantes</h6>
                {lineItem.participants.map((participant) => (
                  <div key={participant.name}>
                    {buildLabel('Nome', participant.name)}
                    {participant.email &&
                      buildLabel('Email', participant.email)}
                    {participant.document &&
                      buildLabel('CPF', showMaskedCPF(participant.document))}
                    <br />
                  </div>
                ))}
              </section>
            )}
          </div>
          {lineItem.type === 'variant' && (
            <CardBody className="border-bottom">
              <div
                className="d-inline-flex pointer"
                onClick={toggleAdditionalInfo}
              >
                <h6>Informações adicionais</h6>
                <span
                  className={`material-icons text-fiord-blue dropdown-arrow-icon
                  ${additionalInfoVisible ? 'inactive-icon' : ''}`}
                >
                  keyboard_arrow_down
                </span>
                <span
                  className={`material-icons text-fiord-blue dropdown-arrow-icon
                  ${additionalInfoVisible ? '' : 'inactive-icon'}`}
                >
                  keyboard_arrow_up
                </span>
              </div>
              <Collapse open={additionalInfoVisible}>
                <AdditionalInfo item={lineItem} />
              </Collapse>
            </CardBody>
          )}

          {lineItem.type === 'variant' && (
            <ChangesRequested
              changesRequested={lineItem.changes_requested}
              lineItem={lineItem}
              order={order}
              refetch={refetch}
            />
          )}
        </Accordion.Body>
      </Card>
    </Accordion.Item>
  );
};

export default LineItem;
