/* eslint-disable no-bitwise */
import Resource from '../resource';

export default class GiftCard extends Resource {
  redeem(code) {
    return this.client.put(`/gift_cards/redeem/${code}`);
  }

  userGiftCards() {
    return this.client.get(`/gift_cards/from_user/`);
  }
}
