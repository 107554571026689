import { useSelector } from 'react-redux';

export function useCart() {
  const lineItemIndex = useSelector((state) => state.cart.currentLineItemIndex);
  const allLineItemsParams = useSelector((state) => state.cart.lineItemsParams);
  const specialItemsParams = useSelector(
    (state) => state.cart.specialItemsParams,
  );
  const lineItemParams = allLineItemsParams[lineItemIndex];

  return {
    lineItemParams,
    lineItemIndex,
    allLineItemsParams,
    specialItemsParams,
  };
}
