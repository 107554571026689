import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Card, CardBody, CardHeader } from 'shards-react';

import { formatDateTimeUsingSlashes } from 'lib/date-utils';

const GiftCardList = ({
  giftCards,
  icon,
  title,
  type,
  hideExpiresAt,
  hideRecipient,
  hideSender,
  hideSent,
}) => {
  const [currentGiftCard, setCurrentGiftCard] = useState(null);
  return (
    <>
      <Card small className="mb-4 redeem-component">
        <CardHeader className="border-bottom">
          {icon && <i className={icon}></i>}
          <span className="mb-0 ml-2 box-title">{title}</span>
        </CardHeader>

        <CardBody className="border-bottom">
          {giftCards.length === 0 && (
            <p>
              Você ainda não {type === 'received' ? 'recebeu' : 'enviou'} nenhum
              cartão-presente
            </p>
          )}
          {giftCards.length > 0 && (
            <Table responsive="md" hover>
              <thead className="thead-light">
                <tr>
                  {!hideRecipient && <th scope="col">Destinatário</th>}
                  {!hideSender && <th scope="col">Remetente</th>}
                  <th scope="col">Valor</th>
                  {!hideSent && <th scope="col">Enviado?</th>}
                  {!hideExpiresAt && <th>Expira em</th>}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {giftCards.map((giftCard) => (
                  <tr key={giftCard.id}>
                    {!hideRecipient && (
                      <td>
                        {giftCard.recipient_name} <br /> (
                        {giftCard.recipient_email})
                      </td>
                    )}
                    {!hideSender && (
                      <td>
                        {giftCard.sender.first_name} {giftCard.sender.last_name}{' '}
                        <br /> ({giftCard.sender.email})
                      </td>
                    )}
                    <td>{giftCard.value}</td>
                    {!hideSent && (
                      <td>
                        {giftCard.sent ? (
                          <i className="fa-solid fa-check ml-3 text-success"></i>
                        ) : (
                          ''
                        )}
                      </td>
                    )}
                    {!hideExpiresAt && (
                      <td>
                        {giftCard.expires_at &&
                          formatDateTimeUsingSlashes(giftCard.expires_at)}
                      </td>
                    )}
                    <td>
                      <i
                        className="fa-solid fa-gift pointer"
                        onClick={() => setCurrentGiftCard(giftCard)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
      {currentGiftCard && (
        <Modal show backdrop="static" className="gift-card-modal">
          <Modal.Header>
            <Modal.Title>
              <i className="fa-solid fa-gift mr-2"></i>
              Cartão-presente
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="gift-card-template">
                De: {currentGiftCard.sender.first_name}{' '}
                {currentGiftCard.sender.last_name} <br />
                Para: {currentGiftCard.recipient_name} <br />
                <br />
                <blockquote className="blockquote">
                  <p>{currentGiftCard.message}</p>
                </blockquote>
                <div className="text-right mt-4">{currentGiftCard.code}</div>
              </div>
              <div>
                {currentGiftCard.redeemed_at && currentGiftCard.redeemer && (
                  <div className="mt-4 text-center">
                    Resgatado em{' '}
                    {formatDateTimeUsingSlashes(currentGiftCard.redeemed_at)}{' '}
                    por{' '}
                    {`${currentGiftCard.redeemer.first_name} ${currentGiftCard.redeemer.last_name} (${currentGiftCard.redeemer.email})`}
                  </div>
                )}
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setCurrentGiftCard(null)}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default GiftCardList;
