/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable global-require */
import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
} from 'shards-react';
import OutsideClickDetector from 'lib/OutsideClickDetector';
import { useAuth } from 'contexts/AuthContext';
import { Config } from 'lib/config';

const UserActions = () => {
  const [visible, setVisible] = useState(false);
  const { logout, currentUser } = useAuth();

  const toggleUserActions = () => {
    setVisible(!visible);
  };

  return (
    <div className="pointer">
      <NavItem caret tag={Dropdown} toggle={toggleUserActions}>
        <OutsideClickDetector
          visible={visible}
          onOutsideClick={toggleUserActions}
        >
          <DropdownToggle
            caret
            tag={NavLink}
            className="text-nowrap px-3"
            data-testid="dropdown-actions"
          >
            {currentUser.avatar && (
              <img
                className="user-avatar rounded-circle mr-2"
                src={currentUser.avatar}
                alt="User Avatar"
              />
            )}{' '}
            <span className="d-none d-md-inline-block">
              {currentUser.first_name}
            </span>
          </DropdownToggle>

          <Collapse
            data-testid="dropdown-container"
            tag={DropdownMenu}
            right
            small
            open={visible}
          >
            <DropdownItem
              className="text-danger"
              data-testid="logout-button"
              onClick={() => {
                logout();
                window.location.href = Config.storeUrl;
              }}
            >
              <i className="material-icons text-danger">&#xE879;</i> Sair
            </DropdownItem>
          </Collapse>
        </OutsideClickDetector>
      </NavItem>
    </div>
  );
};

export default UserActions;
