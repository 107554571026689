import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const alert = (options = {}) => {
  Swal.fire({
    text: options.text,
    icon: options.icon || 'warning',
  }).then(() => {
    if (options.callback && typeof options.callback === 'function') {
      options.callback();
    }
  });
};

export const confirm = (options = {}) => {
  const MySwal = withReactContent(Swal);

  MySwal.fire({
    title: options.title,
    text: options.text,
    icon: options.icon || 'question',
    showConfirmButton: true,
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
  }).then((response) => {
    if (
      response.isConfirmed &&
      options.onConfirm &&
      typeof options.onConfirm === 'function'
    ) {
      options.onConfirm();
    }
  });
};
