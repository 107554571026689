import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <span className="material-icons back-arrow" onClick={() => navigate(-1)}>
      arrow_back
    </span>
  );
};

export default BackButton;
