import React from 'react';
import { Row, Col, Card } from 'shards-react';
import LineItemDetails from './LineItemDetails';

const lineItemFinalStatuses = ['completed', 'canceled', 'unfulfilled'];

const ReservationDetails = ({ order }) => {
  const parentLineItems = order.line_items.filter(
    (li) =>
      (li.type === 'variant' || li.type === 'gift_card') &&
      !lineItemFinalStatuses.includes(li.status) &&
      !li.parent_id,
  );
  return (
    <Row className="reservation-details-component">
      <Col lg="12" sm="12" className="mb-4">
        <Card
          small
          className="card-post card-post--aside card-post--1 flex-flow-column"
        >
          <Row className="payment-instructions">
            <Col>
              <h5 className="card-title title-for-desktop">
                Clique no botão ao lado para ir para a página de pagamento
              </h5>

              <h5 className="card-title title-for-mobile">
                Clique no botão no final da página para ir para o pagamento
              </h5>

              <p className="card-text d-inline-block mb-1">
                <strong>
                  Agora que a sua reserva já foi feita, você só precisa realizar
                  o pagamento para confirmá-la. Assim que seu pagamento for
                  confirmado, você receberá um e-mail com todos os detalhes para
                  sua experiência. Aí é só esperar o dia e aproveitar muito!
                </strong>
              </p>
            </Col>
          </Row>
        </Card>

        <hr />

        {parentLineItems.map((lineItem) => (
          <LineItemDetails
            key={`line-item-details-${lineItem.id}`}
            lineItem={lineItem}
            order={order}
          />
        ))}
      </Col>
    </Row>
  );
};

export default ReservationDetails;
