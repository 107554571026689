const validationRules = {
  startDate: (values) => values?.startDate?.length > 0,
  fromTime: (values) => values?.fromTime?.length > 0,
  toTime: (values) =>
    buildDateFromTime(values?.toTime) > buildDateFromTime(values?.fromTime),
  weekDays: (values) => values?.weekDays > 0,
  comment: () => true,
};

const buildDateFromTime = (timeString) => {
  if (!timeString) return null;

  const [hours, minutes] = timeString.split(':').map(Number);

  const today = new Date();

  today.setHours(hours);
  today.setMinutes(minutes);
  today.setSeconds(0);

  return today;
};

export function useBookingValidation() {
  const bookingFields = [
    'startDate',
    'fromTime',
    'toTime',
    'weekDays',
    'comment',
  ];

  const validateFields = (values) => {
    const validations = {};
    let isValid = true;

    bookingFields.forEach((field) => {
      const validate = validationRules[field];
      const validationResult = validate(values);
      validations[field] = validationResult;
      if (isValid && !validationResult) {
        isValid = false;
      }
    });

    return [isValid, validations];
  };

  return [validateFields];
}
