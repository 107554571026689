import React from 'react';
import { Button } from 'shards-react';

const variantsConfiguration = {
  'remove-from-cart': {
    title: 'Remover item',
    icon: 'close',
    theme: 'danger',
  },
  'put-back-to-cart': {
    title: 'Restaurar',
    icon: 'undo',
    theme: 'dark',
  },
};

const CartButton = ({ onClick, screenSize, variant }) => {
  const { icon, theme, title } = variantsConfiguration[variant];

  return (
    <Button
      outline
      size="sm"
      theme={theme}
      className={`display-flex mr-1 ${screenSize !== 'small' ? 'mb-2' : ''}`}
      onClick={onClick}
    >
      <span className="material-icons">{icon}</span>
      <p className="mx-auto">{title}</p>
    </Button>
  );
};

export default CartButton;
