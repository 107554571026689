import React from 'react';

import { useDispatch } from 'react-redux';
import { changeMenuVisibility } from 'redux/sidebar/sidebarSlice';

const NavbarToggle = () => {
  const dispatch = useDispatch();

  return (
    <nav className="nav">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        onClick={() => dispatch(changeMenuVisibility())}
        className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none text-center pointer"
        data-testid="mobile-menu-button"
      >
        <i className="material-icons">&#xE5D2;</i>
      </a>
    </nav>
  );
};

export default NavbarToggle;
