/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import axios from 'axios';

import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { Config } from 'lib/config';
import {
  getAccessToken,
  setAccessToken,
  removeAccessToken,
  getRefreshToken,
  removeRefreshToken,
} from './token-service';

const client = axios.create({
  baseURL: `${Config.backendUrl}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const publicClient = axios.create({
  baseURL: `${Config.backendUrl}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const removeCurrentUser = () => localStorage.removeItem('user');

const signOutUser = () => {
  removeAccessToken();
  removeRefreshToken();
  removeCurrentUser();
  redirectToLogin();
};

const redirectToLogin = () => {
  window.location = `login?reason=session&url=${encodeURI(
    window.location.href,
  )}`;
};

// Function that will be called to refresh authorization
const refreshToken = async (failedRequest) => {
  try {
    const body = { refresh_token: getRefreshToken() };
    const options = { skipAuthRefresh: true };
    const tokenRefreshResponse = await client.post(
      'session/refresh',
      body,
      options,
    );
    const { access_token: accessToken } = tokenRefreshResponse.data;

    setAccessToken(accessToken);
    failedRequest.response.config.headers.Authorization = `Bearer ${accessToken}`;

    return Promise.resolve();
  } catch (_err) {
    signOutUser();
  }
};

// Instantiate the interceptor
createAuthRefreshInterceptor(client, refreshToken, {});

client.interceptors.request.use(
  (config) => {
    const token = getAccessToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default client;
