/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Nav, NavItem } from 'shards-react';

const MainFooter = ({ contained, menuItems, copyright }) => (
  <footer className="main-footer d-flex p-2 px-3 bg-white border-top">
    <Container fluid={contained}>
      <Row>
        <Nav className="d-flex justify-content-center">
          {menuItems.map((item, idx) => (
            <NavItem key={idx}>
              <a className="nav-link" href={item.href}>
                {item.title}
              </a>
            </NavItem>
          ))}
          <div className="social-icons">
            <a
              href="https://www.facebook.com/makemydate.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              href="https://www.instagram.com/makemydate.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://twitter.com/makemydate_br"
              target="_blank"
              rel="noreferrer noopener"
            >
              <i className="fab fa-twitter"></i>
            </a>
          </div>
        </Nav>
        <span className="copyright ml-auto my-auto mr-2">
          Make My Date | {copyright}
        </span>
      </Row>
    </Container>
  </footer>
);

MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string,
};

MainFooter.defaultProps = {
  contained: false,
  copyright: 'Copyright © 2024',
  menuItems: [
    {
      title: 'Home',
      to: '#',
      href: 'https://makemydate.com.br',
    },
    {
      title: 'Contato',
      to: '#',
      href: '/contact',
    },
  ],
};

export default MainFooter;
