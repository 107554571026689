/* eslint-disable react/no-array-index-key */
import React from 'react';

import 'views/DatesList/details/additional-info.scss';

const AdditionalInfo = ({ item }) => {
  const additionalInfo = item.variant.additional_information.instructions;

  const infoItem = (type, value) => {
    if (type === 'map') {
      return (
        <iframe
          src={value}
          title={value}
          style={{ border: 0, display: 'block', marginTop: '1.5rem' }}
          allowFullScreen=""
          loading="lazy"
          width="100%"
        />
      );
    }
    if (type === 'bullet') {
      return (
        <ul>
          {value.map((bulletItem, index) => (
            <li key={`bullet-${index}`} className="list-bullet-item">
              {bulletItem}
            </li>
          ))}
        </ul>
      );
    }
    return <p className="mt-2">{value}</p>;
  };

  return (
    <div className="additional-info-component">
      {additionalInfo.map((instruction, index) => (
        <div key={`additional-info-${index}`} className="info-item">
          <strong>{instruction.title}</strong>
          {infoItem(instruction.type, instruction.data)}
        </div>
      ))}
    </div>
  );
};

export default AdditionalInfo;
