import React from 'react';
import { Col, Card, CardBody, CardHeader } from 'shards-react';
import { Form } from 'react-bootstrap';
import { setCustomField } from 'redux/cart/cartSlice';
import { useDispatch } from 'react-redux';
import { useCart } from 'lib/hooks';

import { characterCounter, maskField, maxLength } from './dynamic-fields-utils';

const CustomFieldsCard = ({ registerCustomField, variant }) => {
  const { lineItemParams } = useCart();
  const dispatch = useDispatch();

  if (!lineItemParams) {
    return null;
  }

  const { customFields } = lineItemParams;

  if (variant.custom_fields.length === 0) {
    return null;
  }

  return (
    <Col className="mb-4">
      <Card>
        <CardHeader className="border-bottom">
          <h5 className="m-0">Dados adicionais</h5>
        </CardHeader>
        <CardBody className="py-3">
          <Col sm="12" className="mb-3">
            <p className="mb-3">
              Precisamos de mais algumas informações para essa experiência:
            </p>
            <Form>
              {variant.custom_fields.map((customField) => (
                <Col sm="12" md="4" lg="3" key={customField.name}>
                  <Form.Group
                    className="mb-3"
                    controlId={`#${customField.name}`}
                  >
                    <Form.Label>
                      {customField.label}
                      {customField.required ? (
                        <span className="text-danger"> *</span>
                      ) : (
                        <span className="text-muted font-italic ml-1">
                          (opcional)
                        </span>
                      )}
                    </Form.Label>
                    <Form.Control
                      type={customField.type}
                      required={customField.required}
                      ref={registerCustomField}
                      value={customFields[customField.name] || ''}
                      maxLength={maxLength(customField.validation_type)}
                      onChange={(e) => {
                        e.target.value = maskField(
                          e.target.value,
                          customFields.validation_type,
                        );
                        dispatch(
                          setCustomField({
                            field: customField.name,
                            value: e.target.value,
                          }),
                        );
                      }}
                    />
                    {customField.validation_type?.includes('max_size_') &&
                      characterCounter(
                        customFields[customField.name],
                        customField.validation_type,
                      )}
                    {customField.description && (
                      <Form.Text className="text-muted">
                        {customField.description}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              ))}
            </Form>
          </Col>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CustomFieldsCard;
