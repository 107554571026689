export const Config = {
  allowPasteCPF: process.env.REACT_APP_ALLOW_PASTE_CPF === 'true',
  backendUrl: process.env.REACT_APP_BACKEND_API_URL,
  googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  storeUrl: process.env.REACT_APP_STORE_URL,
  validateCPF: process.env.REACT_APP_VALIDATE_CPF === 'true',
  validatePasswordFormat:
    process.env.REACT_APP_VALIDATE_PASSWORD_FORMAT === 'true',
  validatePhoneNumber: process.env.REACT_APP_VALIDATE_PHONE_NUMBER === 'true',
};
