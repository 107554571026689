import React, { useState } from 'react';
import classNames from 'classnames';
import { Col } from 'shards-react';

import SidebarMainNavbar from 'components/layout/MainSidebar/SidebarMainNavbar';
import SidebarNavItems from 'components/layout/MainSidebar/SidebarNavItems';

import { useSelector } from 'react-redux';

const MainSidebar = (props) => {
  const [noSidebar] = useState(props.noSidebar);

  const isMenuVisible = useSelector((state) => state.sidebar.menuVisible);

  const classes = classNames(
    'main-sidebar',
    'px-0',
    'col-12',
    isMenuVisible && 'open',
    noSidebar && 'd-md-none',
  );

  return (
    <Col tag="aside" className={classes} lg={{ size: 2 }} md={{ size: 3 }}>
      <SidebarMainNavbar hideLogoText={props.hideLogoText} />
      <SidebarNavItems />
    </Col>
  );
};

MainSidebar.defaultProps = {
  hideLogoText: false,
};

export default MainSidebar;
