import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

import { Button, Card, CardBody, CardHeader } from 'shards-react';

import API from 'api';

const Redeem = () => {
  const [redeemCode, setRedeemCode] = useState('');
  const [redeemError, setRedeemError] = useState(null);

  const redeem = () => {
    API.GiftCard.redeem(redeemCode)
      .then(() => {
        setRedeemError(null);
        Swal.fire({
          title: 'Cupom resgatado com sucesso!',
          icon: 'success',
        }).then(() => window.location.reload());
      })
      .catch(() => {
        setRedeemError(`O código ${redeemCode} é inválido.`);
      })
      .finally(() => {
        setRedeemCode('');
      });
  };

  return (
    <Card small className="mb-4 redeem-component">
      <CardHeader className="border-bottom">
        <i className="fa-solid fa-hand-holding-dollar"></i>
        <span className="mb-0 ml-2 box-title">Resgatar</span>
      </CardHeader>

      <CardBody className="border-bottom">
        <p>
          O cartão-presente que você recebeu possui um código, que você pode
          resgatar para adicionar créditos à sua conta.
        </p>
        <Form.Group className="mb-3">
          <Form.Label>Código do cartão</Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => setRedeemCode(e.target.value)}
            className={`${redeemError ? 'required' : ''}`}
            value={redeemCode}
          />
          {redeemError && <div className="error-message">{redeemError}</div>}
        </Form.Group>

        <div className="actions-section">
          <Button
            disabled={redeemCode.length === 0}
            onClick={redeem}
            theme="accent"
          >
            Resgatar
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default Redeem;
