/* eslint-disable no-return-assign */
/* eslint-disable react/no-danger */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Loading from 'components/loading/Loading';
import API from 'api';

import { Config } from 'lib/config';
import { getQueryParameter } from 'lib/query-string';
import { maskCPF, maskPhoneNumber } from 'lib/masks';
import { useAuth } from 'contexts/AuthContext';
import {
  validateBirthDate,
  validateCPF,
  validateMinimumAge,
  validatePhoneNumber,
  validatePasswordFormat,
  validateEmailFormat,
} from 'lib/validators';

import 'views/authentication/styles.scss';

const MINIMUM_AGE = 16;

const Signup = ({ returnTo, onSignInClick }) => {
  const { login } = useAuth();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [document, setDocument] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [validEmail, setValidEmail] = useState(true);
  const [validFirstName, setValidFirstName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [validBirthDate, setValidBirthDate] = useState(true);
  const [validCPF, setValidCPF] = useState(true);
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [validPasswordConfirmation, setValidPasswordConfirmation] =
    useState(true);

  const returnUrl = () => returnTo || window.location.origin;

  const orderId = getQueryParameter('ref');
  const hasOrder = orderId && orderId !== '';

  const isEmpty = (value) => value.trim().length === 0;

  const emptyField = () =>
    isEmpty(email) ||
    isEmpty(firstName) ||
    isEmpty(lastName) ||
    isEmpty(document) ||
    isEmpty(birthDate) ||
    isEmpty(password) ||
    isEmpty(passwordConfirmation) ||
    isEmpty(phoneNumber);

  const validateFields = () => {
    const isValidEmail = !isEmpty(email) && validateEmailFormat(email);
    const isValidFirstName = !isEmpty(firstName) && firstName.length > 1;
    const isValidLastName = !isEmpty(lastName) && lastName.length > 1;
    const isValidCPF = !isEmpty(document) && validateCPF(document);
    const isValidBirthDate =
      !isEmpty(birthDate) && validateBirthDate(birthDate);
    const isValidMinimumAge = validateMinimumAge(birthDate, MINIMUM_AGE);
    const isValidPhoneNumber =
      !isEmpty(phoneNumber) && validatePhoneNumber(phoneNumber);
    const isValidPassword = validatePasswordFormat(password);
    const isValidPasswordConfirmation =
      passwordConfirmation.trim().length > 0 &&
      password === passwordConfirmation;

    setValidEmail(isValidEmail);
    setValidFirstName(isValidFirstName);
    setValidLastName(isValidLastName);
    setValidCPF(isValidCPF);
    setValidBirthDate(isValidBirthDate && isValidMinimumAge);
    setValidPhoneNumber(isValidPhoneNumber);
    setValidPassword(isValidPassword);
    setValidPasswordConfirmation(isValidPasswordConfirmation);

    return {
      valid:
        isValidEmail &&
        isValidFirstName &&
        isValidLastName &&
        isValidCPF &&
        isValidBirthDate &&
        isValidMinimumAge &&
        isValidPhoneNumber &&
        isValidPassword &&
        isValidPasswordConfirmation,
      isValidEmail,
      isValidFirstName,
      isValidLastName,
      isValidCPF,
      isValidBirthDate,
      isValidPhoneNumber,
      isValidPassword,
      isValidPasswordConfirmation,
      isValidMinimumAge,
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormSubmitted(true);

    const validation = validateFields();

    if (!validation.valid) {
      if (emptyField()) {
        return setError('Verifique os campos em vermelho');
      }

      if (!validation.isValidPassword) {
        return setError(
          'A senha deve ter no mínimo 8 caracteres, incluindo ao menos uma letra maiúscula, uma minúscula e um número',
        );
      }

      if (!validation.isValidPasswordConfirmation) {
        return setError('A senha e a confirmação precisam ser idênticas');
      }

      if (!validation.isValidBirthDate) {
        return setError('Data de nascimento inválida');
      }

      if (!validation.isValidMinimumAge) {
        return setError(
          `Você precisa ter ao menos ${MINIMUM_AGE} anos para se cadastrar`,
        );
      }

      return setError('Verifique os campos em vermelho!!!');
    }

    setError('');
    setLoading(true);

    const userData = {
      email,
      first_name: firstName,
      last_name: lastName,
      document,
      birth_date: birthDate,
      password,
      phone_number: phoneNumber,
    };

    if (hasOrder) {
      userData.order_id = orderId;
    }

    API.User.create(userData)
      .then(() => {
        login(email, password).then(() => {
          window.location = returnUrl();
        });
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data.errors[0].source === 'email') {
          setError('Email já cadastrado');
          setValidEmail(false);
        } else if (
          err.response &&
          err.response.data.errors[0].source === 'document'
        ) {
          setError('Já existe um cadastro com esse CPF');
          setValidCPF(false);
        } else if (
          err.response &&
          err.response.data.errors[0].source === 'password'
        ) {
          setError(
            'A senha deve ter pelo menos 8 caracteres, com ao menos um número, uma letra maiúscula e uma minúscula',
          );
          setValidPassword(false);
        } else if (
          err.response &&
          err.response.data.errors[0].source === 'birth_date'
        ) {
          setError('Verifique se a data de nascimento está correta');
          setValidBirthDate(false);
        } else {
          const link = `<a class="warning-link" href="${Config.storeUrl}/contato">Entre em contato conosco.</a>`;
          setError(`Não foi possível criar a conta. ${link}`);
        }
      });
  };

  const signInLink = () => {
    if (onSignInClick) {
      return (
        <span
          data-testid="alternate-to-sign-in"
          className="link-appearance"
          onClick={onSignInClick}
        >
          {' '}
          Entrar
        </span>
      );
    }

    return <Link to="/login"> Entrar</Link>;
  };

  const requiredMark = () => <span className="required-mark">*</span>;

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="row justify-content-md-center mt-5 vh-100">
      <div className="col col-md-3 d-xs-none"></div>
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            {error && (
              <div
                className="alert alert-danger"
                dangerouslySetInnerHTML={{ __html: error }}
              ></div>
            )}

            <h2 className="text-center mb-4">Cadastro</h2>

            <div className="required-fields-hint">
              {requiredMark()} campos obrigatórios
            </div>

            <div className="row">
              <div className="col-sm-6">
                <section className="form-group" id="first-name">
                  <label>Nome{requiredMark()}</label>
                  <input
                    className={`form-control ${
                      !formSubmitted || validFirstName ? '' : 'invalid-input'
                    }`}
                    type="text"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </section>
              </div>
              <div className="col-sm-6">
                <section className="form-group" id="last-name">
                  <label>Sobrenome{requiredMark()}</label>
                  <input
                    className={`form-control ${
                      !formSubmitted || validLastName ? '' : 'invalid-input'
                    }`}
                    type="text"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </section>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <section className="form-group" id="cpf">
                  <label
                    className={
                      !formSubmitted || validCPF
                        ? ''
                        : 'label-for-invalid-input'
                    }
                  >
                    {validCPF ? 'CPF' : 'CPF inválido'}
                    {requiredMark()}
                  </label>
                  <input
                    className={`form-control ${
                      !formSubmitted || validCPF ? '' : 'invalid-input'
                    }`}
                    type="text"
                    required
                    maxLength={14}
                    placeholder="xxx.xxx.xxx-xx"
                    onKeyPress={(e) =>
                      (e.target.value = maskCPF(e.target.value))
                    }
                    onPaste={(e) => {
                      if (!Config.allowPasteCPF) {
                        e.preventDefault();
                      }
                    }}
                    value={document}
                    onChange={(e) => setDocument(e.target.value)}
                  />
                </section>
              </div>
              <div className="col-sm-6">
                <section className="form-group" id="birth-date">
                  <label>Data de Nascimento{requiredMark()}</label>
                  <input
                    className={`form-control ${
                      !formSubmitted || validBirthDate ? '' : 'invalid-input'
                    }`}
                    type="date"
                    required
                    value={birthDate}
                    onChange={(e) => setBirthDate(e.target.value)}
                  />
                </section>
              </div>
            </div>

            <section className="row">
              <div className="col-sm-6">
                <section className="form-group" id="email">
                  <label>Email{requiredMark()}</label>
                  <input
                    className={`form-control ${
                      !formSubmitted || validEmail ? '' : 'invalid-input'
                    }`}
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </section>
              </div>

              <div className="col-sm-6">
                <section className="form-group" id="phone-number">
                  <label>Telefone{requiredMark()}</label>
                  <input
                    className={`form-control ${
                      !formSubmitted || validPhoneNumber ? '' : 'invalid-input'
                    }`}
                    type="text"
                    required
                    defaultValue={phoneNumber}
                    onKeyUp={(e) => {
                      const newValue = maskPhoneNumber(e.target.value);
                      e.target.value = newValue;
                      setPhoneNumber(newValue);
                    }}
                  />
                </section>
              </div>
            </section>

            <section className="row">
              <div className="col-sm-6">
                <section className="form-group" id="password">
                  <label>Senha{requiredMark()}</label>
                  <input
                    className={`form-control ${
                      !formSubmitted || validPassword ? '' : 'invalid-input'
                    }`}
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={(e) => setValidPassword(e.target.value)}
                  />
                </section>
              </div>

              <div className="col-sm-6">
                <section className="form-group" id="password-confirm">
                  <label>Confirmação de senha{requiredMark()}</label>
                  <input
                    className={`form-control ${
                      !formSubmitted || validPasswordConfirmation
                        ? ''
                        : 'invalid-input'
                    }`}
                    type="password"
                    required
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                  />
                </section>
              </div>
            </section>

            <button
              disabled={loading}
              className="w-100 btn btn-primary"
              onClick={handleSubmit}
            >
              Cadastrar
            </button>
          </div>
        </div>

        <div className="w-100 text-center mt-2 mb-3">
          Já tem uma conta? {signInLink()}
        </div>
      </div>
      <div className="col col-md-3"></div>
    </div>
  );
};

export default Signup;
