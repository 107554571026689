import React from 'react';
import loadingImg from 'assets/images/loading.svg';

const Loading = () => (
  <div className="loading-container vh-100 d-flex justify-content-center">
    <div className="spinner align-self-center">
      <img src={loadingImg} alt="Loading..." />
    </div>
  </div>
);

export default Loading;
