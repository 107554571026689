/* eslint-disable global-require */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardFooter, Button, Row, Col } from 'shards-react';

import { buildTitle } from 'lib/title';

const Notifications = ({ notifications }) => {
  document.title = buildTitle('Notificações');

  return (
    <Card small className="blog-comments">
      <CardBody className="p-0">
        {notifications.map((notification, idx) => (
          <div key={idx} className="blog-comments__item d-flex p-3">
            {/* Avatar */}
            <div className="blog-comments__avatar mr-3">
              <img src="logo.png" alt="Make My Date" />
            </div>

            {/* Content */}
            <div className="blog-comments__content">
              {/* Content :: Title */}
              <div className="blog-comments__meta text-mutes">
                <a className="text-secondary" href={notification.post.url}>
                  {notification.post.title}
                </a>
                <span className="text-mutes"> - {notification.date} </span>
                {notification.isNew ? (
                  <span className="new-notification">Novo</span>
                ) : (
                  ''
                )}
              </div>

              {/* Content :: Body */}
              <a href={notification.post.url}>
                <p className="m-0 my-1 mb-2 text-muted">{notification.body}</p>
              </a>
            </div>
          </div>
        ))}
      </CardBody>

      <CardFooter className="border-top">
        <Row>
          <Col className="text-center view-report">
            <Button theme="white" type="submit">
              Ver todas notificações
            </Button>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

Notifications.propTypes = {
  /**
   * The notifications dataset.
   */
  notifications: PropTypes.array,
};

Notifications.defaultProps = {
  notifications: [
    {
      id: 1,
      isNew: true,
      date: '3 dias atrás',
      post: {
        title: 'Hello World!',
        url: '#',
      },
      body: 'Well, the way they make shows is, they make one show ...',
    },
    {
      id: 2,
      isNew: false,
      date: '4 dias atrás',
      post: {
        title: 'Hello World Twice!',
        url: '#',
      },
      body: 'After the avalanche, it took us a week to climb out. Now...',
    },
    {
      id: 3,
      isNew: false,
      date: '5 dias atrás',
      post: {
        title: 'Hello World Again!',
        url: '#',
      },
      body: "My money's in that office, right? If she start giving me a bad time due to her suspiciousness of me...",
    },
  ],
};

export default Notifications;
