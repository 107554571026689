import client from '../client';
import Resource from '../resource';

export default class Order extends Resource {
  activate(token) {
    return client.get(`activate-user/${token}`);
  }

  updatePassword(id, email, password, newPassword) {
    const body = {
      user: {
        email,
        password,
        new_password: newPassword,
      },
    };

    return client.patch(`users/${id}/update-password`, body);
  }

  updateEmail(id, email, password, newEmail) {
    const body = {
      user: {
        email,
        password,
        new_email: newEmail,
      },
    };

    return client.patch(`users/${id}/update-email`, body);
  }
}
