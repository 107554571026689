/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { Col, Row } from 'shards-react';
import { useNavigate } from 'react-router-dom';

import AuthSection from 'views/OrderGatekeeper/AuthSection';
import ForgotPassword from 'views/authentication/ForgotPassword';
import Loading from 'components/loading/Loading';
import { getQueryParameter } from 'lib/query-string';
import { useAuth } from 'contexts/AuthContext';

const OrderGatekeeper = () => {
  const { currentUser } = useAuth();
  const [hasAccount, setHasAccount] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);

  const onEmailChangeHandler = (emailAddress) => {
    setEmail(emailAddress);
  };

  const orderId = getQueryParameter('ref');
  const navigate = useNavigate();

  if (!orderId) {
    navigate('/404');
  }

  const preBookingUrl = `/pre-booking?ref=${orderId}`;

  if (currentUser) {
    window.location.href = preBookingUrl;
  } else if (loading) {
    setLoading(false);
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <br />
      <Row>
        <Col>
          <h3 className="text-center" data-testid="gatekeeper-header">
            Para prosseguir, você precisa se identificar.
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          {forgotPassword && (
            <ForgotPassword
              initialEmail={email}
              onSignUpClick={() => {
                setForgotPassword(false);
                setHasAccount(false);
              }}
              onSignInClick={() => {
                setForgotPassword(false);
                setHasAccount(true);
              }}
            />
          )}

          {!forgotPassword && (
            <AuthSection
              email={email}
              hasAccount={hasAccount}
              preBookingUrl={preBookingUrl}
              setHasAccount={setHasAccount}
              setForgotPassword={setForgotPassword}
              onEmailChange={onEmailChangeHandler}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default OrderGatekeeper;
