import React, { useState, useEffect } from 'react';
import { Container, Row, Card, CardBody, Button } from 'shards-react';
import { useNavigate } from 'react-router-dom';

import API from 'api';

import Loading from 'components/loading/Loading';
import { Config } from 'lib/config';
import { getQueryParameter } from 'lib/query-string';

import 'views/authentication/account-activation.scss';

const AccountActivation = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('');

  const activationToken = getQueryParameter('ref');
  const navigate = useNavigate();

  useEffect(() => {
    if (activationToken) {
      API.User.activate(activationToken)
        .then(() => {
          setStatus('success');
          setLoading(false);
        })
        .catch(() => {
          setStatus('failure');
          setLoading(false);
        });
    }
  }, [activationToken]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container fluid className="main-content-container px-4">
      {status === 'success' && (
        <>
          <Row className="page-header pt-4">
            <i className="fa fa-check-circle fa-5x mx-auto mt-3 text-primary"></i>
          </Row>
          <Row className="page-header p-4">
            <Row className="mx-auto text-center">
              <div className="mx-auto">
                <h3 className="page-title">Sua conta está ativa :) </h3>
              </div>
            </Row>
          </Row>
          <Card>
            <CardBody className="text-center">
              <div className="mx-auto">
                <h5>
                  Prontinho, você já pode criar seus próprios dates e reservar
                  aquele dia especial.
                </h5>
              </div>
            </CardBody>
          </Card>
          <Row>
            <Row className="mx-auto">
              <Button
                pill
                outline
                theme="secondary"
                size="lg"
                className="mt-3"
                onClick={() => {
                  navigate('/');
                }}
              >
                &larr; Voltar para a minha conta
              </Button>
            </Row>
          </Row>
        </>
      )}
      {status === 'failure' && (
        <>
          <Row className="page-header pt-4">
            <i className="fa fa-exclamation-circle fa-5x mx-auto mt-3 text-danger"></i>
          </Row>
          <Row className="page-header p-4">
            <Row className="mx-auto pt-1 text-center">
              <div className="mx-auto">
                <h3 className="page-title">
                  Desculpe, alguma coisa deu errada
                </h3>
              </div>
            </Row>
          </Row>
          <Card>
            <CardBody className="text-center">
              <div className="mx-auto">
                <h5>
                  Não encontramos o usuário que você procurava. Lembre-se que
                  uma nova conta deve ser ativada em até 15 dias. Se o prazo já
                  passou,{' '}
                  <a href={`${Config.storeUrl}/contato`}>entre em contato</a>{' '}
                  com a gente ou tente novamente mais tarde.{' '}
                </h5>
              </div>
            </CardBody>
          </Card>
          <Row>
            <Row className="mx-auto">
              <Button
                pill
                outline
                theme="secondary"
                size="lg"
                className="mt-3"
                onClick={() => {
                  window.location = Config.storeUrl;
                }}
              >
                &larr; Voltar para o site
              </Button>
            </Row>
          </Row>
        </>
      )}
    </Container>
  );
};

export default AccountActivation;
