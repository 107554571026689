/* eslint-disable no-bitwise */

/*
 * Given a booking object, return a string with the week days selected as the
 * ideal days for the event.
 *
 * @param {Object} booking - The booking object. Structure example:
 *
 * {
 *   "comment": "",
 *   "start_date": "2023-12-09T19:15:52Z",
 *   "from_time": "09:00",
 *   "to_time": "18:00",
 *   "week_days": 67, # 1000011 in binary, which means it accepts sunday, friday and saturday
 * }
 *
 * @param {number} booking.week_days - The week days in binary.
 *
 * @returns {string} - The week days.
 *
 * @example
 * const booking = { week_days: 34 };
 * weekDaysFromBooking(booking);
 * // Returns 'segunda, terça, quinta'
 *
 * @example
 * const booking = { week_days: 64 };
 * weekDaysFromBooking(booking);
 * // Returns 'domingo'
 *
 * @example
 * const booking = { week_days: 127 };
 * weekDaysFromBooking(booking);
 * // Returns 'segunda, terça, quarta, quinta, sexta, sábado, domingo'
 */
export const weekDaysFromBooking = (booking) => {
  const days = {
    monday: { text: 'segunda', binary: '0100000' },
    tuesday: { text: 'terça', binary: '0010000' },
    wednesday: { text: 'quarta', binary: '0001000' },
    thursday: { text: 'quinta', binary: '0000100' },
    friday: { text: 'sexta', binary: '0000010' },
    saturday: { text: 'sábado', binary: '0000001' },
    sunday: { text: 'domingo', binary: '1000000' },
  };

  const includeDay = (weekDay) => {
    const weekDayData = days[weekDay];
    const intFromBinary = parseInt(weekDayData.binary, 2);
    return (booking.week_days & intFromBinary) === intFromBinary
      ? weekDayData.text
      : null;
  };

  return [
    includeDay('sunday'),
    includeDay('monday'),
    includeDay('tuesday'),
    includeDay('wednesday'),
    includeDay('thursday'),
    includeDay('friday'),
    includeDay('saturday'),
  ]
    .filter((n) => n)
    .join(', ');
};

/*
 * Given a binary number representing the week days, return an object with the
 * week days as keys and their state as values. The state can be 'checked' or
 * 'unchecked'.
 *
 * The week days are: monday, tuesday, wednesday, thursday, friday, saturday
 * and sunday. They are represented by a binary number, where the first bit is
 * sunday and the last is monday.
 *
 * The binary number is a 7-bit number, where 1 means the day is selected and 0
 * means it is not.
 *
 * @param {number} weekDaysInBinary - The week days in binary.
 * @returns {Object} - The week days as keys and their state as values.
 *
 * @example
 * const weekDaysInBinary = 34;
 * weekDaysFromBinary(weekDaysInBinary);
 * // Returns {
 * //   monday: { text: 'Segunda-feira', state: 'checked' },
 * //   tuesday: { text: 'Terça-feira', state: 'unchecked' },
 * //   wednesday: { text: 'Quarta-feira', state: 'checked' },
 * //   thursday: { text: 'Quinta-feira', state: 'unchecked' },
 * //   friday: { text: 'Sexta-feira', state: 'unchecked' },
 * //   saturday: { text: 'Sábado', state: 'unchecked' },
 * //   sunday: { text: 'Domingo', state: 'checked' }
 * // }
 *
 */
export const weekDaysFromBinary = (weekDaysInBinary) => {
  const days = {
    monday: { text: 'Segunda-feira', binary: '0100000' },
    tuesday: { text: 'Terça-feira', binary: '0010000' },
    wednesday: { text: 'Quarta-feira', binary: '0001000' },
    thursday: { text: 'Quinta-feira', binary: '0000100' },
    friday: { text: 'Sexta-feira', binary: '0000010' },
    saturday: { text: 'Sábado', binary: '0000001' },
    sunday: { text: 'Domingo', binary: '1000000' },
  };
  const updatedDays = { ...days };

  Object.keys(updatedDays).forEach((day) => {
    const intFromBinary = parseInt(updatedDays[day].binary, 2);
    updatedDays[day].state =
      (weekDaysInBinary & intFromBinary) === intFromBinary
        ? 'checked'
        : 'unchecked';
  });

  return Object.fromEntries(
    Object.entries(updatedDays).map(([day, { text, state }]) => [
      day,
      { text, state },
    ]),
  );
};
