export const avatarUrl = (avatar) => `avatars/${avatar}.png`;

export const extractAvatarNumberFromUrl = (url) => {
  const regex = /^http.*\/([^/]+)\.\w+$/;
  const match = url.match(regex);

  if (match) {
    const imageName = match[1];
    return imageName;
  }

  return url;
};
