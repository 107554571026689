import React from 'react';
import { Col, Card, CardBody } from 'shards-react';
import { useDispatch } from 'react-redux';
import { setSpecialItemParams } from 'redux/cart/cartSlice';

import { confirm } from 'lib/notifications';
import { formatDate } from 'lib/date-utils';
import { showImage } from 'lib/date-image';
import { useCart } from 'lib/hooks';
import CartButton from './CartButton';

const SpecialItem = ({ item }) => {
  const dispatch = useDispatch();
  const { specialItemsParams } = useCart();

  if (!specialItemsParams[item.id]) {
    return null;
  }

  const { removed } = specialItemsParams[item.id];

  const removeItem = () => {
    confirm({
      title: 'Remover item',
      text: `Tem certeza que deseja remover ${item.title} do seu carrinho?`,
      onConfirm: () => {
        dispatch(setSpecialItemParams({ id: item.id, removed: true }));
      },
    });
  };

  const putItemBack = () => {
    dispatch(setSpecialItemParams({ id: item.id, removed: false }));
  };

  const buildRemoveActionButton = ({ screenSize }) => {
    if (removed) {
      return (
        <CartButton
          onClick={putItemBack}
          variant="put-back-to-cart"
          screenSize={screenSize}
        />
      );
    }

    return (
      <CartButton
        onClick={removeItem}
        variant="remove-from-cart"
        screenSize={screenSize}
      />
    );
  };

  return (
    <Col
      lg="12"
      sm="12"
      className={`mb-4 ${removed ? 'removed-line-item' : ''}`}
    >
      <Card
        small
        className="card-post card-post--aside card-post--1 flex-flow-column h-100 overflow-hidden"
      >
        <div className="inherit flex-column flex-md-row">
          <div
            className="card-post__image__2"
            style={{ backgroundImage: `url('${showImage(item)}')` }}
          ></div>
          <CardBody>
            <div className="card-text-2">
              <h5 className="card-title" data-testid="date-title">
                {item.title}
              </h5>
              {item.type === 'gift_card' && (
                <p className="card-text d-inline-block mb-3">
                  <strong>Para: </strong>
                  {item.recipient_name} (<em>{item.recipient_email}</em>)
                  <br className="mb-2" />
                  <strong>Enviar dia: </strong>
                  {formatDate(item.scheduled_date)}
                  <br className="mb-2" />
                  <strong>Sua mensagem: </strong>
                  {item.message}
                  <br className="mb-2" />
                </p>
              )}
            </div>
            <div className="card-details">
              <span className="text-muted">
                <strong>Criado em</strong> {formatDate(item.inserted_at)}
              </span>
            </div>

            {removed && (
              <div className="card-details removed text-center mt-2">
                <h5>Este item foi removido do seu carrinho.</h5>
              </div>
            )}
          </CardBody>
          <Col className="card-side-buttons-container d-none d-md-flex">
            {buildRemoveActionButton({ screenSize: 'base' })}
          </Col>
          <CardBody
            small="true"
            className="d-md-none display-flex justify-content-center card-bottom-buttons-container border-top"
          >
            {buildRemoveActionButton({ screenSize: 'small' })}
          </CardBody>
        </div>
      </Card>
    </Col>
  );
};

export default SpecialItem;
