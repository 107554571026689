/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import withReactContent from 'sweetalert2-react-content';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Tooltip,
} from 'shards-react';
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import API from 'api';

// import AdditionalInfo from 'views/DatesList/details/AdditionalInfo';
import LineItem from 'views/DatesList/details/LineItem';
import BackButton from 'components/navigation/back-button/BackButton';
import DateDetailsTimeline from 'views/DatesList/DateDetailsTimeline';
import Loading from 'components/loading/Loading';
import PageTitle from 'components/common/PageTitle';

import { BADGE_INFO } from 'lib/badge-info';
import { buildTitle } from 'lib/title';
// import { buildUrl } from 'lib/url';
import {
  formatDate,
  // getTimeFromDate
} from 'lib/date-utils';
// import { hideCPF } from 'lib/masks';
import { useAuth } from 'contexts/AuthContext';

import 'views/DatesList/styles.scss';
// import { Config } from 'lib/config';

const orderFinalStatuses = ['closed', 'expired'];

const DateDetails = () => {
  const { orderNumber } = useParams();
  // const [additionalInfoVisible, setAdditionalInfoVisible] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const MySwal = withReactContent(Swal);

  const fetchOrder = async (number) => {
    const result = await API.UserOrder.find(currentUser.id, number);
    const { data: body } = result;
    return body.data;
  };

  const {
    data: order,
    isLoading,
    refetch,
    isError,
  } = useQuery(['get-order', orderNumber], () => fetchOrder(orderNumber));

  if (isLoading) {
    return <Loading />;
  }

  if (!order || isError) {
    navigate('/404');
  }

  document.title = buildTitle(order.number);

  const toggle = () => {
    setOpenTooltip((o) => !o);
  };

  // const weekDayMessage = () => {
  //   const weekDays = API.Order.weekDaysFromBooking(order);
  //   const prefix = weekDays.includes(', ')
  //     ? 'Dias da semana possíveis:'
  //     : 'Dia da semana:';

  //   return (
  //     <>
  //       <strong>{prefix} </strong>
  //       {weekDays}
  //     </>
  //   );
  // };

  const buildMessage = () => {
    if (order.billing.subtotal.in_cents > 0) {
      return `Caso continue, o seu pedido entrará em processo de análise de reembolso para o valor de ${order.billing.subtotal.formatted}. `;
    }
    return '';
  };

  const confirmCancelation = () => {
    MySwal.fire({
      title: `Tem certeza que quer cancelar o pedido${
        order.number ? ` ${order.number}` : ''
      }?`,
      html: `Esse processo é manual e pode ser desfeito caso mude de ideia, tá? ${buildMessage()}`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#c4183c',
      cancelButtonText: 'Não',
      icon: 'warning',
      input: 'text',
      inputPlaceholder: 'Por que você quer cancelar?',
      inputAttributes: {
        autocapitalize: 'off',
      },
      didOpen: (popup) => {
        const confirmButton = popup.querySelector('button.swal2-confirm');

        confirmButton.setAttribute('disabled', true);

        const input = popup.querySelector('input');

        const charCountSpan = document.createElement('div');
        charCountSpan.style.textAlign = 'right';
        charCountSpan.style.marginRight = '36px';
        charCountSpan.style.fontSize = '12px';
        charCountSpan.style.color = '#888';
        charCountSpan.textContent = 'Digite ao menos 10 caracteres';

        input.parentNode.insertBefore(charCountSpan, input.nextSibling);

        input.addEventListener('keyup', (e) => {
          const inputValue = e.target.value;
          const charLimit = 10;

          if (inputValue.length < charLimit) {
            confirmButton.setAttribute('disabled', true);
            charCountSpan.textContent = `Faltam ${
              charLimit - inputValue.length
            } characteres`;
          } else {
            confirmButton.removeAttribute('disabled');
            charCountSpan.textContent = '';
          }
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const comment = result.value;

        API.Contact.create({
          name: `${currentUser.first_name} ${currentUser.last_name}`,
          email: currentUser.email,
          subject: `Cancelamento do pedido ${order.number || order.id}`,
          message: comment,
        }).then(() => {
          Swal.fire(
            'Pedido de cancelamento em análise, entraremos em contato em breve',
            '',
            'success',
          ).catch(() => {
            Swal.fire(
              'Não conseguimos realizar o pedido de cancelamento, tente novamente ou entre em contato com a gente',
              '',
              'error',
            );
          });
        });
      }
    });
  };

  // const toggleAdditionalInfo = () => {
  //   setAdditionalInfoVisible(!additionalInfoVisible);
  // };

  // const buildLabel = (title, text) => (
  //   <>
  //     <strong>{title}: </strong>
  //     {text}
  //     <br />
  //   </>
  // );
  // const showLocation = () => {
  //   const location = order.option.locations[0];
  //   if (!location) {
  //     return null;
  //   }

  //   if (order.status !== 'ready') {
  //     return (
  //       <p>O local exato será informado após a confirmação de pagamento</p>
  //     );
  //   }

  //   return (
  //     <>
  //       <strong>Local: </strong>
  //       {`${location.address}, ${location.number} ${location.complement || ''}`}
  //       <br />
  //       {`${location.city}, ${location.state} ${location.country} ${
  //         location.zip_code || ''
  //       }`}
  //       <br />
  //       {`${location.reference || ''}`} <br />
  //     </>
  //   );
  // };

  return (
    <Container
      fluid
      className="main-content-container px-4 date-details-component"
    >
      {/* Page Header */}
      <BackButton />
      <Row noGutters className="page-header">
        <PageTitle
          sm="4"
          title={order.number}
          subtitle="Detalhes do Pedido"
          className="text-sm-left  pb-4"
        />
        <div className="col-sm-4 d-flex flex-column align-items-center justify-content-end pb-4">
          <span className="text-uppercase page-subtitle text-sm-left text-center text-md-left text-muted">
            <strong>Status</strong>
          </span>
          <div
            className={`${BADGE_INFO[order.status].klass} status-badge`}
            style={
              order.status === 'ready'
                ? { animation: 'green-pulse 2s infinite' }
                : order.status === 'pre_booking'
                ? { animation: 'dark-pulse 2s infinite' }
                : {}
            }
          >
            {BADGE_INFO[order.status].text}
          </div>
        </div>
      </Row>

      <Row>
        <Col lg="12" sm="12" className="mb-4">
          <Card
            small
            className="card-post card-post--aside card-post--1 flex-flow-column h-100"
          >
            <div className="inherit column-mobile border-bottom p-3">
              <CardBody>
                <div className="card-text-2">
                  <h5>Pagamento</h5>
                  {/* <h5 className="card-title" data-testid="date-title">
                    {order.date.title}
                  </h5> */}
                  {/* <p className="card-text d-inline-block mb-3">
                    <strong>Opção escolhida:</strong> {order.option.title}
                  </p> */}
                  <strong>Valor total: </strong>
                  {order.billing.price.formatted}
                  <br />
                  {order.billing.balance_due.in_cents === 0 &&
                    'Este pedido já está pago'}
                  {order.billing.balance_due.in_cents > 0 &&
                    `Valor a ser pago: ${order.billing.balance_due.formatted}`}
                  <span
                    className="material-icons ml-1"
                    id="payment_info"
                    style={{ fontSize: '16px', verticalAlign: 'sub' }}
                  >
                    info
                  </span>
                  <Tooltip
                    placement="right"
                    open={openTooltip}
                    target="#payment_info"
                    toggle={toggle}
                  >
                    <ul className="px-2 my-1 text-left">
                      <li>
                        <strong>Valor pago em dinheiro: </strong>
                        <span className="text-nowrap">
                          {order.billing.value_paid_with_money.formatted}
                        </span>
                      </li>
                      <li>
                        <strong>Valor pago em créditos da loja: </strong>
                        <span className="text-nowrap">
                          {
                            order.billing.value_paid_with_store_credits
                              .formatted
                          }
                        </span>
                      </li>
                    </ul>
                  </Tooltip>
                </div>
                <div className="card-details">
                  <span className="text-muted">
                    <strong>Criado em</strong> {formatDate(order.inserted_at)}
                  </span>

                  <br />
                  <span className="text-muted">
                    <strong>Atualizado por último em </strong>
                    {formatDate(order.updated_at)}
                  </span>

                  {order.status === 'waiting_for_payment' &&
                    order.expires_at && (
                      <>
                        <br />
                        <span className="text-muted">
                          <strong>Expira em </strong>
                          {formatDate(order.expires_at)}
                        </span>
                      </>
                    )}

                  {/* {order.status === 'completed' && (
                    <>
                      <br />
                      <span className="text-muted">
                        <strong>Pago em</strong> {formatDate(order.paid_at)}
                      </span>
                    </>
                  )} */}
                </div>
              </CardBody>

              <Col className="card-side-buttons-container d-none d-lg-flex">
                {order.status !== 'pre_booking' &&
                  orderFinalStatuses.includes(order.status) && (
                    <Button
                      outline
                      size="sm"
                      theme="secondary"
                      className="mb-2 mr-1 display-flex"
                      // onClick={() => {
                      //   window.location.href = buildUrl(
                      //     `${Config.storeUrl}/date/agende/${order.date.slug}/${order.option.label}`,
                      //   );
                      // }}
                      data-testid="date-buy-again-button"
                    >
                      <span className="material-icons">replay</span>
                      <p className="mx-auto">Pedir de novo</p>
                    </Button>
                  )}

                {!order.number && order.status === 'pre_booking' && (
                  <Button
                    outline
                    size="sm"
                    theme="primary"
                    className="mb-2 mr-1 display-flex"
                    onClick={() => {
                      const preBookingUrl = `/pre-booking?ref=${order.id}`;
                      navigate(preBookingUrl);
                    }}
                    data-testid="date-continue-button"
                  >
                    <span className="material-icons">arrow_forward</span>
                    <p className="mx-auto">Continuar</p>
                  </Button>
                )}

                {order.status === 'waiting_for_payment' && (
                  <Button
                    outline
                    size="sm"
                    theme="warning"
                    className="mb-2 mr-1 display-flex"
                    onClick={() => {
                      const yourReservationUrl = `/your-reservation?ref=${order.id}`;
                      navigate(yourReservationUrl);
                    }}
                    data-testid="date-edit-button"
                  >
                    <span className="material-icons">attach_money</span>
                    <p className="mx-auto">Pagar</p>
                  </Button>
                )}
              </Col>
            </div>

            <CardBody
              small="true"
              className="d-lg-none card-bottom-buttons-container border-bottom"
            >
              <Row className="justify-content-around">
                {order.status !== 'pre_booking' &&
                  orderFinalStatuses.includes(order.status) && (
                    <Button
                      outline
                      size="sm"
                      theme="secondary"
                      className="my-2 display-flex"
                      // onClick={() => {
                      //   window.location.href = buildUrl(
                      //     `${Config.storeUrl}/date/agende/${order.date.slug}/${order.option.label}`,
                      //   );
                      // }}
                      data-testid="date-buy-again-button"
                    >
                      <span className="material-icons">replay</span>
                      <p className="mx-auto">Pedir de novo</p>
                    </Button>
                  )}

                {!order.number && order.status === 'pre_booking' && (
                  <Button
                    outline
                    size="sm"
                    theme="primary"
                    className="my-2 display-flex"
                    onClick={() => {
                      const preBookingUrl = `/pre-booking?ref=${order.id}`;
                      navigate(preBookingUrl);
                    }}
                    data-testid="date-continue-button"
                  >
                    <span className="material-icons">arrow_forward</span>
                    <p className="mx-auto">Continuar</p>
                  </Button>
                )}

                {order.status === 'waiting_for_payment' && (
                  <Button
                    outline
                    size="sm"
                    theme="warning"
                    className="my-2 display-flex"
                    onClick={() => {
                      const yourReservationUrl = `/your-reservation?ref=${order.id}`;
                      navigate(yourReservationUrl);
                    }}
                    data-testid="date-edit-button"
                  >
                    <span className="material-icons">attach_money</span>
                    <p className="mx-auto">Pagar</p>
                  </Button>
                )}
              </Row>
            </CardBody>

            {order.status === 'placed' && (
              <CardBody>
                <div className="order-placed-info">
                  <div className="order-placed-info-icon">
                    <span className="material-icons">info_outline</span>
                  </div>
                  <div className="order-placed-info-text">
                    <p>
                      O seu pedido está pré-agendado, isso significa que estamos
                      analisando o seu pedido para fazer o agendamento seguindo
                      as suas preferências escolhidas. Em breve você receberá um
                      e-mail com a data e horário da sua reserva, assim como um
                      link para o pagamento.
                    </p>
                  </div>
                </div>
              </CardBody>
            )}

            <div className="container mb-3">
              <Tabs className="my-4" defaultActiveKey="line-items">
                <Tab eventKey="line-items" title="Itens nesse pedido">
                  <CardBody>
                    {order.line_items.map((lineItem, idx) => (
                      <Col
                        className="my-2 px-0"
                        key={lineItem.id}
                        data-testid={`order-index-${idx}`}
                      >
                        <Accordion flush>
                          <LineItem
                            lineItem={lineItem}
                            order={order}
                            refetch={refetch}
                          />
                        </Accordion>
                      </Col>
                    ))}
                  </CardBody>
                </Tab>
                <Tab eventKey="timeline" title="Linha do tempo">
                  <CardBody className="border-bottom pb-4">
                    <DateDetailsTimeline order={order} />
                  </CardBody>
                </Tab>
              </Tabs>
            </div>

            {/* <CardBody className="border-bottom">
              <div
                className="d-inline-flex pt-3 pointer"
                onClick={toggleAdditionalInfo}
              >
                <h6>Informações adicionais</h6>
                <span
                  className={`material-icons text-fiord-blue dropdown-arrow-icon
                  ${additionalInfoVisible ? 'inactive-icon' : ''}`}
                >
                  keyboard_arrow_down
                </span>
                <span
                  className={`material-icons text-fiord-blue dropdown-arrow-icon
                  ${additionalInfoVisible ? '' : 'inactive-icon'}`}
                >
                  keyboard_arrow_up
                </span>
              </div>
              <Collapse open={additionalInfoVisible}>
                <AdditionalInfo order={order} />
              </Collapse>
            </CardBody> */}
          </Card>
        </Col>
      </Row>
      <Row className="mx-auto mt-3 mb-5">
        {!orderFinalStatuses.includes(order.status) && (
          <Button
            outline
            size="sm"
            theme="danger"
            className="mb-2 mr-1 display-flex solo-button"
            onClick={confirmCancelation}
            data-testid="date-cancel-button"
          >
            <span className="material-icons">close</span>
            <p>Cancelar pedido</p>
          </Button>
        )}
      </Row>
    </Container>
  );
};

export default DateDetails;
