import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Button } from 'shards-react';
import { useDispatch } from 'react-redux';
import {
  removeLineItem,
  setCurrentLineItemIndex,
  setValidationStatus,
  undoRemoveLineItem,
} from 'redux/cart/cartSlice';

import { confirm } from 'lib/notifications';
import { formatDate } from 'lib/date-utils';
import { showImage } from 'lib/date-image';
import { useCart } from 'lib/hooks';

import CartButton from './CartButton';
import CustomFieldsCard from './CustomFieldsCard';
import ParticipantsCard from './ParticipantsCard';
import ReservationCard from './ReservationCard';

const PreBookingItem = ({
  bookingValidations,
  lineItem,
  registerBookingField,
  registerCustomField,
  registerParticipantsField,
  user,
  validateLineItem,
}) => {
  const { lineItemParams, lineItemIndex } = useCart();
  const { billing, variant, variant_title: variantTitle } = lineItem;

  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();

  const focusFirstInvalidField = () => {
    const reservationCardComponent = document.querySelector(
      '.reservation-card-component',
    );

    if (reservationCardComponent.querySelectorAll('.is-invalid').length > 0) {
      reservationCardComponent.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else {
      const firstFieldWithError = document.querySelector('.is-invalid');
      if (firstFieldWithError) {
        firstFieldWithError.focus();
        firstFieldWithError.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  };

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    if (lineItemParams.validationStatus !== 'initial') {
      const isValid = validateLineItem();

      if (!isValid) {
        focusFirstInvalidField();
      }
    }
  }, []);

  const onClickHandler = () => {
    validateLineItem();

    window.scrollTo({ top: 0, left: 0 });
    dispatch(setCurrentLineItemIndex(lineItemIndex + 1));
  };

  const removeItem = () => {
    confirm({
      title: 'Remover item',
      text: `Tem certeza que deseja remover ${lineItem.product_title} do seu carrinho?`,
      onConfirm: () => {
        dispatch(setValidationStatus('valid'));
        dispatch(removeLineItem());
      },
    });
  };

  const putItemBack = () => {
    dispatch(undoRemoveLineItem());
  };

  const buildRemoveActionButton = ({ screenSize }) => {
    if (lineItemParams.removed) {
      return (
        <CartButton
          onClick={putItemBack}
          variant="put-back-to-cart"
          screenSize={screenSize}
        />
      );
    }

    return (
      <CartButton
        onClick={removeItem}
        variant="remove-from-cart"
        screenSize={screenSize}
      />
    );
  };

  return (
    <Col
      className={`pre-booking-item-component col-12 ${
        isLoaded ? 'loaded' : ''
      } ${lineItemParams.removed ? 'removed-line-item' : ''}`}
    >
      <Col lg="12" sm="12" className="mb-4">
        <Card
          small
          className="card-post card-post--aside card-post--1 flex-flow-column h-100 overflow-hidden"
        >
          <CardHeader className="border-bottom">
            <h5 className="m-0">Resumo</h5>
          </CardHeader>

          <div className="inherit flex-column flex-md-row">
            <div
              className="card-post__image__2"
              style={{ backgroundImage: `url('${showImage(lineItem)}')` }}
            ></div>
            <CardBody>
              <div className="card-text-2">
                <h5 className="card-title" data-testid="date-title">
                  {lineItem.product_title}
                </h5>
                <p className="card-text d-inline-block mb-3">
                  {lineItem.description}
                  <br className="mb-2" />
                  <strong>Opção escolhida: </strong>
                  {variantTitle}
                  <br className="mb-2" />

                  {!billing.has_discount && (
                    <>
                      <strong>Valor: </strong>
                      {billing.price_with_discount.formatted}
                    </>
                  )}

                  {billing.has_discount && (
                    <>
                      <strong>Valor: </strong>
                      de <s>{billing.price.formatted}</s> por{' '}
                      {billing.price_with_discount.formatted}
                    </>
                  )}
                </p>
              </div>
              {!lineItemParams.removed && (
                <div className="card-details">
                  <span className="text-muted">
                    <strong>Criado em</strong>{' '}
                    {formatDate(lineItem.inserted_at)}
                  </span>
                </div>
              )}

              {lineItemParams.removed && (
                <div className="card-details removed text-center">
                  <h5>Este item foi removido do seu carrinho.</h5>
                </div>
              )}
            </CardBody>
            <Col className="card-side-buttons-container d-none d-md-flex">
              {buildRemoveActionButton({ screenSize: 'base' })}
            </Col>
            <CardBody
              small="true"
              className="d-md-none display-flex justify-content-center card-bottom-buttons-container border-top"
            >
              {buildRemoveActionButton({ screenSize: 'small' })}
            </CardBody>
          </div>
        </Card>
      </Col>

      {!lineItemParams.removed && (
        <>
          <ReservationCard
            bookingValidations={bookingValidations}
            registerBookingField={registerBookingField}
          />

          <CustomFieldsCard
            registerCustomField={registerCustomField}
            variant={variant}
          />

          <ParticipantsCard
            user={user}
            variant={variant}
            registerDynamicField={registerParticipantsField}
          />
        </>
      )}

      <Col sm="12" className="mb-4">
        <Row className="d-flex justify-content-center mx-auto my-4">
          <Button
            pill
            size="lg"
            className="m-2"
            onClick={onClickHandler}
            data-testid="prebooking-continue-button"
          >
            Continuar
          </Button>
        </Row>
      </Col>
    </Col>
  );
};

export default PreBookingItem;
