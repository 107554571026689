import React, { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Button, CardBody, FormTextarea, Row } from 'shards-react';

import API from 'api';

const RequestChange = ({ lineItem, order, onSend }) => {
  const [message, setMessage] = useState('');
  const [validMessage, setValidMessage] = useState(true);

  const MySwal = withReactContent(Swal);

  const onRequestChangeHandler = () => {
    const isValid = message.length > 0;
    setValidMessage(isValid);

    if (isValid) {
      API.Order.requestChanges(order.id, {
        comment: message,
        line_item_id: lineItem.id,
      })
        .then(() => {
          MySwal.fire({
            title: <strong>Pedido de alteração enviado</strong>,
            icon: 'success',
          }).then(() => {
            setMessage('');
            setValidMessage(true);

            onSend();
          });
        })
        .catch(() => {
          MySwal.fire({
            title: <strong>Oops</strong>,
            html: <i>Alguma coisa deu errada, tente novamente mais tarde :(</i>,
            icon: 'error',
          });
        });
    }
  };

  return (
    <CardBody className="border-bottom">
      <h6>Pedir alteração no item #{lineItem?.number || 'do pedido'}</h6>

      <FormTextarea
        className={validMessage ? '' : 'required'}
        placeholder="Escreva aqui sobre a mudança que deseja na sua reserva."
        rows="5"
        onChange={(e) => {
          const text = e.currentTarget.value;
          setValidMessage(text.length > 0);
          setMessage(text);
        }}
        value={message}
      />

      <Row>
        <Row className="mx-auto my-1">
          <Button
            pill
            outline
            theme="danger"
            size="sm"
            className="m-2"
            onClick={() => setMessage('')}
          >
            Limpar
          </Button>
          <Button
            pill
            theme="primary"
            size="sm"
            className="m-2"
            disabled={!validMessage}
            onClick={onRequestChangeHandler}
          >
            Pedir mudança
          </Button>
        </Row>
      </Row>
    </CardBody>
  );
};

export default RequestChange;
