import React from 'react';

import { Button } from 'shards-react';
import { useNavigate } from 'react-router-dom';

const OrderListItemButtons = ({ order }) => {
  const navigate = useNavigate();

  const continueOrder = () => {
    const preBookingUrl = `/pre-booking?ref=${order.id}`;
    navigate(preBookingUrl);
  };

  return (
    <div className="display-flex">
      {!order.number && order.status === 'pre_booking' && (
        <Button
          outline
          size="sm"
          theme="info"
          className="display-flex align-items-center mr-1"
          onClick={continueOrder}
          data-testid="date-continue-button"
        >
          <span className="material-icons">arrow_forward</span>
          <span className="mb-0 ml-1" style={{ fontSize: '13px' }}>
            Continuar
          </span>
        </Button>
      )}
      {order.status === 'waiting_for_payment' && (
        <Button
          outline
          size="sm"
          theme="warning"
          className="display-flex align-items-center mr-1 d-none d-md-flex"
          onClick={() => {
            const yourReservationUrl = `/your-reservation?ref=${order.id}`;
            navigate(yourReservationUrl);
          }}
          data-testid="date-pay-button"
        >
          <span className="material-icons">attach_money</span>
          <span className="mb-0 ml-1" style={{ fontSize: '13px' }}>
            Pagar
          </span>
        </Button>
      )}
      {order.status !== 'pre_booking' && (
        <Button
          outline
          size="sm"
          theme="secondary"
          className="display-flex align-items-center ml-1"
          onClick={() => {
            navigate(`/your-dates/${order.number}`);
          }}
          data-testid="date-details-button"
        >
          <span className="material-icons">subject</span>
          <span className="mb-0 ml-1" style={{ fontSize: '13px' }}>
            Ver pedido
          </span>
        </Button>
      )}
    </div>
  );
};

export default OrderListItemButtons;
