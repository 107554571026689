import React from 'react';
import { maskCNPJ, maskCPF, maskPhoneNumber, maskZipCode } from 'lib/masks';

export const maskField = (field, type) => {
  switch (type) {
    case 'cnpj':
      return maskCNPJ(field);
    case 'cpf':
      return maskCPF(field);
    case 'phone_number':
      return maskPhoneNumber(field);
    case 'zip_code':
      return maskZipCode(field);
    default:
      return field;
  }
};

export const maxLength = (type) => {
  switch (type) {
    case 'cnpj':
      return 18;
    case 'cpf':
      return 14;
    case 'phone_number':
      return 11;
    case 'zip_code':
      return 8;
    case 'max_size_50':
      return 50;
    default:
      return undefined;
  }
};

export const characterCounter = (value, type) => (
  <p className="text-right me-1 mb-1">
    <small>
      {(value || '').length}/{type.split('max_size_')[1]}
    </small>
  </p>
);
