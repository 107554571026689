/* eslint-disable no-return-assign */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormGroup,
  FormInput,
  Button,
} from 'shards-react';

import EstadosSelect from 'components/estados-select/EstadosSelect';
import EmailPasswordModal from 'components/user-profile/EmailPasswordModal';

import { buildTitle } from 'lib/title';
import { Config } from 'lib/config';
import { maskCPF, hideCPF, maskPhoneNumber, maskZipCode } from 'lib/masks';
import { validateCPF } from 'lib/validators';

const UserAccountDetails = ({ title, userDetails, saveProfile }) => {
  const [userInfo, setUserInfo] = useState({});
  const [validCPF, setValidCPF] = useState(true);
  const [editEmailPassword, setEditEmailPassword] = useState(false);

  document.title = buildTitle('Seu perfil');

  useEffect(() => {
    setUserInfo(userDetails);
  }, [userDetails]);

  if (userInfo === {} || userInfo.id === null) {
    return null;
  }

  const showMaskedCPF = (cpf) => {
    if (cpf === '') {
      return '';
    }

    return hideCPF(cpf);
  };

  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Row form>
                {/* First Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="feFirstName">Nome</label>
                  <FormInput
                    id="feFirstName"
                    placeholder="Nome"
                    value={userInfo.first_name || ''}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        first_name: e.target.value,
                      });
                    }}
                  />
                </Col>
                {/* Last Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="feLastName">Sobrenome</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Sobrenome"
                    value={userInfo.last_name || ''}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        last_name: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="fePhoneNumber">Telefone</label>
                  <FormInput
                    id="fePhoneNumber"
                    value={userInfo.phone_number || ''}
                    required
                    onKeyUp={(e) =>
                      (e.target.value = maskPhoneNumber(e.target.value))
                    }
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        phone_number: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row form>
                {/* CPF */}
                <Col md="6" className="form-group">
                  <label
                    htmlFor="feCPF"
                    className={validCPF ? '' : 'label-for-invalid-input'}
                  >
                    {validCPF ? 'CPF' : 'CPF inválido'}
                  </label>
                  <FormInput
                    id="feCPF"
                    className={validCPF ? '' : 'invalid-input'}
                    placeholder="xxx.xxx.xxx-xx"
                    value={showMaskedCPF(userInfo.document || '')}
                    required
                    disabled
                    maxLength={14}
                    onBlur={(e) => {
                      setValidCPF(validateCPF(e.target.value));
                      setUserInfo({
                        ...userInfo,
                        document: e.target.value,
                      });
                    }}
                    onKeyPress={(e) =>
                      (e.target.value = maskCPF(e.target.value))
                    }
                    onPaste={(e) => {
                      if (!Config.allowPasteCPF) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Col>
                {/* Data de nascimento */}
                <Col md="6" className="form-group">
                  <label htmlFor="feBirthDate">Data de Nascimento</label>
                  <FormInput
                    type="date"
                    typeable="true"
                    id="feBirthDate"
                    placeholder="dd/mm/aaaa"
                    value={userInfo.birth_date || ''}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        birth_date: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row form>
                {/* Email */}
                <Col md="5" className="form-group">
                  <label htmlFor="feEmail">Email</label>
                  <FormInput
                    type="email"
                    disabled
                    id="feEmail"
                    placeholder="Email"
                    value={userInfo.email || ''}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        email: e.target.value,
                      });
                    }}
                    autoComplete="email"
                  />
                </Col>
                {/* Password */}
                <Col md="5" className="form-group">
                  <label htmlFor="fePassword">Senha</label>
                  <FormInput
                    type="password"
                    disabled
                    id="fePassword"
                    placeholder="Senha"
                    value="********"
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        password: e.target.value,
                      });
                    }}
                    autoComplete="current-password"
                  />
                </Col>
                <Col md="2" className="offset-sm-2-mobile">
                  <Button
                    outline
                    theme="secondary"
                    className="mt-2rem sp-2 w-100"
                    onClick={() => setEditEmailPassword(true)}
                  >
                    Alterar
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label htmlFor="feAddress">Endereço</label>
                    <FormInput
                      id="feAddress"
                      placeholder="Endereço"
                      value={userInfo.street_address || ''}
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          street_address: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <label htmlFor="feAddressNumber">Número</label>
                  <FormInput
                    id="feAddressNumber"
                    placeholder="Número"
                    value={userInfo.address_number || ''}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        address_number: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <label htmlFor="feAddressComplement">Complemento</label>
                  <FormInput
                    id="feAddressComplement"
                    placeholder="Complemento"
                    value={userInfo.address_complement || ''}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        address_complement: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row form>
                {/* City */}
                <Col md="5" className="form-group">
                  <label htmlFor="feCity">Cidade</label>
                  <FormInput
                    id="feCity"
                    placeholder="Cidade"
                    value={userInfo.city || ''}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        city: e.target.value,
                      });
                    }}
                  />
                </Col>
                {/* State */}
                <Col md="4" className="form-group">
                  <label htmlFor="feInputState">Estado</label>
                  <EstadosSelect
                    currentStateId={userInfo.state_id}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        state_id: e.target.value,
                      });
                    }}
                  />
                </Col>
                {/* Zip Code */}
                <Col md="3" className="form-group">
                  <label htmlFor="feZipCode">CEP</label>
                  <FormInput
                    id="feZipCode"
                    placeholder="CEP"
                    value={userInfo.zip_code || ''}
                    onChange={(e) => {
                      setUserInfo({
                        ...userInfo,
                        zip_code: e.target.value,
                      });
                    }}
                    onKeyUp={(e) =>
                      (e.target.value = maskZipCode(e.target.value))
                    }
                  />
                </Col>
              </Row>
              <Button
                theme="accent"
                type="button"
                disabled={!validCPF}
                onClick={() => {
                  saveProfile(userInfo);
                }}
              >
                Atualizar dados
              </Button>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>

      {editEmailPassword && (
        <EmailPasswordModal
          user={userInfo}
          close={() => setEditEmailPassword(false)}
        />
      )}
    </Card>
  );
};

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,

  // The logged user. It comes fom the currentUser variable, ready from the
  // AuthContext.
  userDetails: PropTypes.object,

  // Function that changes the user's data in the database.
  // Check `src/views/UserProfile.js` to know how it works.
  saveProfile: PropTypes.func,
};

UserAccountDetails.defaultProps = {
  title: 'Seus Detalhes',
  userDetails: {},
  saveProfile: () => {},
};

export default UserAccountDetails;
