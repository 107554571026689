import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import { getCookie, setCookie } from 'cookies-next';

import { Config } from 'lib/config';

import styles from './CookieConsent.module.scss';

const CookieConsent = () => {
  const [acceptAnalyticsCookies, setAcceptAnalyticsCookies] = useState(false);
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [trayIsOpen, setTrayIsOpen] = useState(false);

  useEffect(() => {
    const cookieValue = getCookie('cookieConsent');
    setShowCookieConsent(!cookieValue);
  }, []);

  const handleTrayIsOpen = () => {
    setTrayIsOpen(!trayIsOpen);
  };

  const saveCookiesPreference = (analyticsCookiesConsent) => {
    const value = {
      necessaryCookies: true,
      analyticsCookies: analyticsCookiesConsent,
    };

    const cookiesConfig = {
      path: '/',
      sameSite: true,
    };

    const cookieDomain = process.env.REACT_APP_COOKIES_DOMAIN;

    if (cookieDomain && cookieDomain.length > 0) {
      cookiesConfig.domain = cookieDomain;
    }

    setCookie('cookieConsent', JSON.stringify(value), cookiesConfig);

    setShowCookieConsent(false);
  };

  const acceptCookies = () => {
    setAcceptAnalyticsCookies(true);

    saveCookiesPreference(true);
  };

  const rejectCookies = () => {
    setAcceptAnalyticsCookies(false);

    saveCookiesPreference(false);
  };

  return (
    <div className={styles.cookieConsentContainer} id="CookieConsent">
      <div className={styles.cookieIcon}>
        <span
          title="Gerencie seus cookies"
          className={
            showCookieConsent
              ? `${styles.bannerOpen}`
              : `${styles.bannerHidden}`
          }
          onClick={() => {
            const cookieValue = getCookie('cookieConsent');
            if (cookieValue) {
              setShowCookieConsent(!showCookieConsent);
            }
          }}
        ></span>
      </div>
      {showCookieConsent && (
        <div className={styles.cookieConsentContentContainer}>
          <div
            className={`${styles.cookieConsentContent} ${
              trayIsOpen ? styles.close : ''
            }`}
          >
            <h6>Controle sua privacidade</h6>
            <div>
              <p className="text-muted">
                Nosso site usa cookies para melhorar a sua navegação.
              </p>
              <a
                href={`${Config.storeUrl}/politica-de-privacidade`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Política de privacidade
              </a>
            </div>
            <div className={styles.cookieActions}>
              <span className={styles.myOptions} onClick={handleTrayIsOpen}>
                Minhas opções
              </span>
              <div className={styles.cookieButtons}>
                <span
                  className={styles.cookieButton}
                  onClick={() => rejectCookies()}
                  data-testid="reject-cookies"
                >
                  Rejeitar
                </span>
                <span
                  className={styles.cookieButton}
                  onClick={() => acceptCookies()}
                  data-testid="accept-cookies"
                >
                  Aceitar
                </span>
              </div>
            </div>
          </div>
          <div
            className={`${styles.cookieTray} ${
              trayIsOpen ? styles.open : styles.close
            }`}
          >
            <div className={styles.cookieTrayContent}>
              <p>Quais cookies usamos?</p>

              <div className={styles.cookiesList}>
                {/* Necessary cookies */}
                <div
                  className={`${styles.cookieInfo} ${styles.necessaryCookies}`}
                >
                  <div className={styles.cookieLabel}>
                    <div>
                      <span className="material-icons">settings</span>
                      <span>Necessários</span>
                    </div>
                    <Switch
                      defaultChecked
                      disabled
                      className={styles.notAllowed}
                    />
                  </div>
                  <div>
                    <p>
                      Os cookies necessários são fundamentais para o
                      funcionamento correto do site, permitindo acesso a áreas
                      seguras, garantindo segurança e cumprindo as leis
                      aplicáveis. O site não funcionaria adequadamente sem eles.
                    </p>
                  </div>
                </div>

                {/* Estatistical Cookies */}
                <div className={`${styles.cookieInfo}`}>
                  <div className={styles.cookieLabel}>
                    <div>
                      <span className="material-icons">trending_up</span>
                      <span>Estatísticos</span>
                    </div>
                    <Switch
                      checked={acceptAnalyticsCookies}
                      onChange={(e) => {
                        setAcceptAnalyticsCookies(e.target.checked);
                      }}
                    />
                  </div>
                  <div>
                    <p>
                      Os cookies de estatísticas são usados para medir a
                      audiência do site. Eles coletam dados para produzir
                      estatísticas anônimas que não são compartilhadas com
                      outras empresas ou usadas para outros fins.
                    </p>
                  </div>
                </div>
              </div>
              <div className={`${styles.cookieActions} px-2`}>
                <span className={styles.myOptions} onClick={handleTrayIsOpen}>
                  Fechar
                </span>
                <div className={styles.cookieButtons}>
                  <span
                    className={styles.cookieButton}
                    onClick={() => rejectCookies()}
                  >
                    Rejeitar
                  </span>
                  <span
                    className={styles.cookieButton}
                    onClick={() => {
                      saveCookiesPreference(acceptAnalyticsCookies);
                      setTrayIsOpen(false);
                    }}
                  >
                    Salvar
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookieConsent;
