import client from './client';

// This class represents a resource that will come from the API.
//
// APIs REST are built on top of the concept of resources, an object with a
// type, associated data, relationships to other resources, and a set of methods
// that operate on it.
//
// For example, the API may expose endpoints to deal with Orders. In this case,
// Order is our resource and the API REST will provide endpoints that allow us
// to request CRUD operations: create, read, update and delete.
//
// This class encapsulates the logic behind it, exposing functions that connect
// with the API to request these operations. It will automatically build the
// URL based on the resource and the operation, making it transparent for
// developers. That being said, when we create an Order class that inherits from
// Resource, we will be able to use an instance of the class
// (const Order = new Order()) to do:
//
// * Order.all(): returns a Promise with a list of orders. Sorting, filter, and
//   pagination will depend on the implementation of the API, but when available
//   you can send extra parameters to the `all` function in the form of a hash
//   argument.
//
// * Order.find(10): returns a Promise with an Order whose id is 10.
//
// * Order.create({ title: 'Going vegan', ... }): creates a new order and returns
//   a promise with the newly created resource.
//
// * Order.update(10, { title: 'Going vegan', ... }): updates an order and returns
//   a promise with the newly updated resource.
//
// * Order.destroy(10): deletes the order with id 10 and returns a promise.
//
// Notice that in the examples the variable Order starts with a capital letter.
// This was done on purpose to make a point to whoever is going to use it that
// it is not an instance of Order, resembling Rails' Action Record. This is
// optional, though. Here in this project, these Resource objects will be
// exposed through the API object. Thus, to access Order operations you can
// require `/api` and use it like this:
//
// API.Order.all()
export default class Resource {
  // All resources receive an options hash in the constructor. This hash
  // accepts two arguments:
  //
  // * rootKey: the root element from which we will retrieve data from the API's
  //   result. Usually, it is the name of the resource, in lowercase and the
  //   singular form.
  //
  //   Example: the order API may return something like this:
  //
  //   ```
  //   {
  //      data: [{ title: 'first order' }, { title: 'second order' }]
  //   }
  //   ```
  //
  //   In this case, rootKey would be `data`. Check the implementation on the
  //   API if you decide not to use the pattern of using the resource name.
  //
  // * uri: each REST endpoint starts with the resource name, in the plural
  //   form. This could be automated, removing the need to provide this value
  //   when instantiating the class, but there are cases where we need more
  //   elaborate URLs, like when a resource is nested inside another resource.
  //   Then, with this uri argument, we can have the flexibility to build the URL
  //   as we see fit.
  //
  //   Usually, it will be `/<resource-name>`, with the resource name in
  //   lowercase and the plural form. For example, we could pass `/orders` to
  //   the Order resource.
  constructor(options = {}) {
    this.rootKey = options.rootKey;
    this.uri = options.uri;
    this.client = client;
  }

  all(params = {}) {
    return this.client.get(this.buildUri(), { params });
  }

  find(id) {
    return this.client.get(`${this.buildUri()}/${id}`);
  }

  create(data) {
    const body = {};
    body[this.rootKey] = data;

    return this.client.post(this.buildUri(), body);
  }

  update(id, data) {
    const body = {};
    body[this.rootKey] = data;

    return this.client.patch(`${this.buildUri()}/${id}`, body);
  }

  destroy(id, data = {}) {
    return this.client.delete(`${this.buildUri()}/${id}`, { data });
  }

  buildUri() {
    if (this.uri[0] === '/') {
      return this.uri;
    }

    return `/${this.uri}`;
  }
}
