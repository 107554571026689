export const VALIDATIONS = {
  cpf: (value) => {
    const cpf = value.replace(/\D/g, '');

    if (cpf.length !== 11) return false;

    const invalidCPFs = [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
    ];

    if (invalidCPFs.includes(cpf)) return false;

    let sum = 0;
    for (let i = 0; i < 9; i += 1) {
      sum += parseInt(cpf.charAt(i), 10) * (10 - i);
    }
    let digit = 11 - (sum % 11);
    if (digit === 10 || digit === 11) digit = 0;

    if (parseInt(cpf.charAt(9), 10) !== digit) return false;

    sum = 0;
    for (let i = 0; i < 10; i += 1) {
      sum += parseInt(cpf.charAt(i), 10) * (11 - i);
    }
    digit = 11 - (sum % 11);
    if (digit === 10 || digit === 11) digit = 0;

    if (parseInt(cpf.charAt(10), 10) !== digit) return false;

    return true;
  },
  email: (value) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(value);
  },
  max_size_50: (value) => value.length <= 50,
  default: () => true,
};
