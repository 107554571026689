export const BADGE_INFO = {
  pre_booking: {
    text: 'Pedido iniciado',
    tooltip: 'Você precisa nos passar alguns dados.',
    klass: 'bg-warning',
  },
  placed: {
    text: 'Pré-agendado',
    tooltip: 'Em breve você receberá um e-mail com o agendamento.',
    klass: 'bg-info',
  },
  waiting_for_payment: {
    text: 'Pendente',
    tooltip: 'Pagamento pendente.',
    klass: 'bg-warning',
  },
  waiting_for_changes: {
    text: 'Em edição',
    tooltip:
      'Você pediu alguma edição no seu pedido e estamos trabalhando nisso!',
    klass: 'bg-info',
  },
  ready: {
    text: 'Em breve',
    tooltip: 'Está tudo certo com o seu date, agora é só esperar!',
    klass: '',
  },
  closed: {
    text: 'Encerrado',
    tooltip: 'Todos os itens desse pedido foram finalizados.',
    klass: 'bg-dark',
  },
  expired: {
    text: 'Expirado',
    tooltip: 'O pedido expirou.',
    klass: 'bg-dark',
  },

  // The statuses below refer to the line items
  created: {
    text: 'Pré-agendado',
    tooltip: 'Em breve você receberá um e-mail com o agendamento.',
    klass: 'bg-info',
  },
  booked: {
    text: 'Agendado',
    tooltip: 'Esse item já está agendado!',
    klass: 'bg-success',
  },
  completed: {
    text: 'Concluído',
    tooltip: 'Esperamos que tenham se divertido!',
    klass: 'bg-dark',
  },
  canceled: {
    text: 'Cancelado',
    tooltip: 'Esse item foi cancelado',
    klass: 'bg-danger',
  },
  unfulfilled: {
    text: 'Não aconteceu',
    tooltip: 'Esse evento não aconteceu',
    klass: 'bg-warning',
  },
};
