import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Col, Container, Row } from 'shards-react';
import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/loading/Loading';
import PageTitle from 'components/common/PageTitle';
import GiftCardList from './GiftCardList';
import Redeem from './Redeem';

import './styles.scss';

const GiftCards = () => {
  const fetchGiftCards = async () => {
    const result = await API.GiftCard.userGiftCards();
    return result.data;
  };

  const { data: giftCards, isLoading } = useQuery(['user-gift-cards'], () =>
    fetchGiftCards(),
  );

  return (
    <Container
      fluid
      className="main-content-container px-4 gift-cards-component"
    >
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Cartão-presente"
          subtitle="Créditos"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      <Row>
        <Col>
          <Tabs className="my-4" defaultActiveKey="redeem">
            <Tab eventKey="redeem" title="Resgate um cartão-presente">
              <Row>
                <Col lg="4">
                  <Redeem />
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="my-gift-cards" title="Meus cartões">
              {(!giftCards || isLoading) && <Loading />}
              {giftCards && !isLoading && (
                <Row>
                  <Col sm={12} md={6}>
                    <GiftCardList
                      giftCards={giftCards.received_gift_cards}
                      icon="fa-solid fa-envelopes-bulk"
                      title="Cartões que recebi"
                      type="received"
                      hideRecipient
                      hideSent
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <GiftCardList
                      giftCards={giftCards.sent_gift_cards}
                      icon="fa-regular fa-paper-plane"
                      title="Cartões que enviei"
                      type="sent"
                      hideExpiresAt
                      hideSender
                    />
                  </Col>
                </Row>
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default GiftCards;
