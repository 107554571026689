/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import API from 'api';

const ForgotPassword = ({ onSignInClick, onSignUpClick, initialEmail }) => {
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');

  // eslint-disable-next-line no-unused-vars
  const [orderId, setOrderId] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const q = new URLSearchParams(window.location.search).get('email');
    setEmail(q || initialEmail);

    const ref = new URLSearchParams(window.location.search).get('ref');
    setOrderId(ref);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setMessage('');
      setError('');
      setLoading(true);

      const msg = 'Se o email estiver cadastrado, você receberá um email';
      const currentUrl = window.location.href.includes('forgot-password')
        ? ''
        : window.location.href;

      API.ResetPassword.sendResetPasswordEmail(email, currentUrl)
        .then(() => {
          setMessage(msg);
          setLoading(false);
        })
        .catch(() => {
          setError(
            'Não conseguimos solicitar a troca de senha, tente novamente mais tarde',
          );
          setLoading(false);
        });
    } catch {
      setMessage(message);
      setLoading(false);
    }
  };

  const signInLink = () => {
    if (onSignInClick) {
      return (
        <span className="link-appearance" onClick={onSignInClick}>
          {' '}
          Entrar
        </span>
      );
    }

    return <Link to="/login"> Entrar</Link>;
  };

  const signUpLink = () => {
    if (onSignUpClick) {
      return (
        <span className="link-appearance" onClick={onSignUpClick}>
          {' '}
          Cadastre-se
        </span>
      );
    }

    return <Link to="/signup"> Cadastre-se</Link>;
  };

  return (
    <div className="row justify-content-md-center mt-3 vh-100 mt-5">
      <div className="col col-md-4"></div>
      <div className="col-md-4">
        <div className="card">
          <div className="card-body">
            <h2 className="text-center mb-4">Reset de senha</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            {message && <div className="alert alert-success">{message}</div>}
            <form onSubmit={handleSubmit}>
              <section className="form-group" id="email">
                <label>Email</label>
                <input
                  className="form-control"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </section>
              <button
                disabled={loading}
                className="w-100 btn btn-primary"
                type="submit"
              >
                Reset de senha
              </button>
            </form>
            <div className="w-100 text-center mt-3">{signInLink()}</div>
          </div>
          <div className="w-100 text-center mt-2">
            Precisa de uma conta? {signUpLink()}
          </div>
        </div>
      </div>
      <div className="col col-md-4"></div>
    </div>
  );
};

export default ForgotPassword;
