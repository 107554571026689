import React from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import PrivateRoute from 'components/private-route/PrivateRoute';
import DefaultLayout from 'layouts/Default';
import { AuthProvider } from 'contexts/AuthContext';
import { getCookie } from 'cookies-next';

// Route Views
import AccountActivation from 'views/authentication/AccountActivation';
import AfterPayment from 'views/AfterPayment';
import Contact from 'views/Contact';
import DateDetails from 'views/DatesList/DateDetails';
import DatesList from 'views/DatesList/DatesList';
import ErrorPage from 'views/ErrorPage';
import ForgotPassword from 'views/authentication/ForgotPassword';
import GiftCards from 'views/GiftCards/GiftCards';
import Login from 'views/authentication/Login';
import Logout from 'views/authentication/Logout';
import Notifications from 'views/Notifications';
import OrderGatekeeper from 'views/OrderGatekeeper/OrderGatekeeper';
import PaymentProcessorCallback from 'views/PaymentProcessorCallback/PaymentProcessorCallback';
import Pix from 'views/Pix';
import PreBooking from 'views/PreBooking/PreBooking';
import Reservation from 'views/Reservation/Reservation';
import ResetPassword from 'views/authentication/ResetPassword';
import ResetPasswordWithToken from 'views/authentication/ResetPasswordWithToken';
import Signup from 'views/authentication/Signup';
import UserProfile from 'views/UserProfile';

import { Config } from 'lib/config';

import { Provider } from 'react-redux';
import { store } from './redux/store';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'layouts/dashboard.css';

function initializeReactGA() {
  if (!Config.googleAnalyticsId) {
    return;
  }
  ReactGA.initialize(Config.googleAnalyticsId);
}

const App = () => {
  const cookieConsent = getCookie('cookieConsent');

  const analyticsConsent =
    cookieConsent && JSON.parse(cookieConsent).analyticsCookies;

  if (analyticsConsent) {
    initializeReactGA();
  }

  const publicRoute = (Children, props = {}) => (
    <DefaultLayout {...props}>
      <Children />
    </DefaultLayout>
  );

  const privateRoute = (Children, props = {}) => (
    <PrivateRoute>{publicRoute(Children, props)}</PrivateRoute>
  );

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // if true, it will refetch data when the browser's tab gains focus.
        // It does not work well on admin because of nested forms.
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <Routes>
            <Route exact path="/" element={<Navigate to="/your-dates" />} />

            <Route exact path="/your-dates" element={privateRoute(DatesList)} />

            <Route
              exact
              path="/your-dates/:orderNumber"
              element={privateRoute(DateDetails)}
            />

            <Route
              exact
              path="/user-profile"
              element={privateRoute(UserProfile)}
            />

            <Route exact path="/gift-cards" element={privateRoute(GiftCards)} />

            <Route
              exact
              path="/notifications"
              element={privateRoute(Notifications)}
            />

            <Route exact path="/contact" element={privateRoute(Contact)} />

            <Route
              exact
              path="/pre-booking"
              element={privateRoute(PreBooking, {
                noSidebar: true,
                noHeaderMenus: true,
              })}
            />

            <Route
              exact
              path="/after-payment"
              element={privateRoute(AfterPayment, { noSidebar: true })}
            />

            <Route
              exact
              path="/your-reservation"
              element={privateRoute(Reservation, {
                noSidebar: true,
                noLogo: true,
              })}
            />

            <Route exact path="/404" element={publicRoute(ErrorPage)} />

            <Route
              exact
              path="/signup"
              element={publicRoute(Signup, {
                noSidebar: true,
                noNavbar: true,
                noFooter: true,
              })}
            />

            <Route
              exact
              path="/login"
              element={publicRoute(Login, {
                noSidebar: true,
                noNavbar: true,
                noFooter: true,
              })}
            />

            <Route
              exact
              path="/logout"
              element={privateRoute(Logout, {
                noSidebar: true,
                noNavbar: true,
                noFooter: true,
              })}
            />

            <Route
              exact
              path="/forgot-password"
              element={publicRoute(ForgotPassword, {
                noSidebar: true,
                noNavbar: true,
                noFooter: true,
              })}
            />

            <Route
              exact
              path="/reset-password"
              element={publicRoute(ResetPassword, {
                noSidebar: true,
                noNavbar: true,
                noFooter: true,
              })}
            />

            <Route
              exact
              path="/order-gatekeeper"
              element={publicRoute(OrderGatekeeper, {
                noSidebar: true,
                noNavbar: true,
                noFooter: true,
              })}
            />

            <Route
              exact
              path="/ativar-conta"
              element={publicRoute(AccountActivation, {
                noSidebar: true,
                noNavbar: true,
                noFooter: true,
              })}
            />

            <Route
              exact
              path="/alterar-senha"
              element={publicRoute(ResetPasswordWithToken, {
                noSidebar: true,
                noNavbar: true,
                noFooter: true,
              })}
            />

            <Route
              exact
              path="/pix"
              element={privateRoute(Pix, { noSidebar: true })}
            />

            <Route
              exact
              path="/pagamento/:status"
              element={privateRoute(PaymentProcessorCallback, {
                noSidebar: true,
              })}
            />

            <Route component={ErrorPage} />
          </Routes>
        </Provider>
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default App;
