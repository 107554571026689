import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormCheckbox,
} from 'shards-react';
import { Form } from 'react-bootstrap';

import { useDispatch } from 'react-redux';
import { useCart } from 'lib/hooks';

import {
  changeUserIsParticipant,
  setFieldForSingleParticipant,
  setParticipants,
} from 'redux/cart/cartSlice';
import { characterCounter, maskField, maxLength } from './dynamic-fields-utils';

const ParticipantsCard = ({ registerDynamicField, user, variant }) => {
  const dispatch = useDispatch();
  const { lineItemParams } = useCart();

  if (!lineItemParams) {
    return null;
  }

  const { participants: participantsParams, userIsParticipant } =
    lineItemParams;

  const isGroup = variant.max_party_size > 2;

  const changeUserAsParticipant = () => {
    // The function was called but the state hasn't yet been updated, so:
    const currentState = !userIsParticipant;

    const firstParticipant = {};
    variant.participant_fields.forEach((field) => {
      firstParticipant[field.name] = currentState ? user[field.name] || '' : '';
    });

    const updatedParticipants = [...participantsParams];
    updatedParticipants[0] = firstParticipant;

    dispatch(setParticipants(updatedParticipants));
    dispatch(changeUserIsParticipant(!userIsParticipant));
  };

  return (
    <Col sm="12" className="mb-4">
      <Card>
        <CardHeader className="border-bottom">
          <h5 className="m-0">Participantes</h5>
        </CardHeader>
        <CardBody className="py-3">
          <Col sm="12" className="mb-3">
            <p className="mb-3">
              Agora algumas informações sobre cada um de vocês:
            </p>
            <FormCheckbox
              checked={userIsParticipant}
              onChange={() => changeUserAsParticipant()}
              className="mb-3 d-inline-flex"
            >
              Eu vou participar dessa experiência
            </FormCheckbox>
            <Form>
              <Row>
                {participantsParams.map(
                  (participantParams, participantIndex) => (
                    <Col
                      sm="12"
                      md="4"
                      lg="3"
                      className="pr-5"
                      // eslint-disable-next-line react/no-array-index-key
                      key={`participantParams-${participantIndex}`}
                    >
                      <h6>
                        {userIsParticipant &&
                          !isGroup &&
                          participantIndex === 0 &&
                          'Você'}
                        {userIsParticipant &&
                          !isGroup &&
                          participantIndex !== 0 &&
                          'Sua companhia'}
                        {userIsParticipant &&
                          isGroup &&
                          `Companhia ${participantIndex + 1}`}
                        {!userIsParticipant &&
                          `Participante ${participantIndex + 1}`}
                      </h6>

                      {variant.participant_fields.map((participantField) => (
                        <Form.Group
                          className="mb-4"
                          controlId={`#${participantField.name}`}
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${participantIndex}-${
                            participantField.name
                          }-${Object.keys(participantParams).join('-')}`}
                        >
                          <Form.Label>
                            {participantField.label}
                            {participantField.required ? (
                              <span className="text-danger"> *</span>
                            ) : (
                              <span className="text-muted font-italic ml-1">
                                (opcional)
                              </span>
                            )}
                          </Form.Label>
                          <Form.Control
                            type={participantField.type}
                            required={participantField.required}
                            value={
                              participantParams?.[participantField.name] ?? ''
                            }
                            maxLength={maxLength(
                              participantField.validation_type,
                            )}
                            disabled={
                              userIsParticipant &&
                              participantIndex === 0 &&
                              participantField.required
                            }
                            onChange={(e) => {
                              e.target.value = maskField(
                                e.target.value,
                                participantField.validation_type,
                              );
                              dispatch(
                                setFieldForSingleParticipant({
                                  participantIndex,
                                  field: participantField.name,
                                  value: e.target.value,
                                }),
                              );
                            }}
                            ref={(ref) =>
                              registerDynamicField(ref, participantIndex)
                            }
                          />

                          {participantField.validation_type?.includes(
                            'max_size_',
                          ) &&
                            characterCounter(
                              participantParams?.[participantField.name],
                              participantField.validation_type,
                            )}
                          {participantField.description && (
                            <Form.Text className="text-muted">
                              {participantField.description}
                            </Form.Text>
                          )}
                        </Form.Group>
                      ))}
                    </Col>
                  ),
                )}
              </Row>
            </Form>
            <p
              className="card-text text-muted d-inline-block my-3"
              style={{ fontSize: '0.875rem' }}
            >
              {userIsParticipant && !isGroup && (
                <>
                  As informações da sua companhia são utilizadas apenas para
                  procedimentos internos. Sua companhia{' '}
                  <strong>não receberá</strong> nenhum tipo de e-mail ou contato
                  da Make My Date.
                </>
              )}
              {userIsParticipant && isGroup && (
                <>
                  As informações das suas companhias são utilizadas apenas para
                  procedimentos internos. Suas companhias{' '}
                  <strong>não receberão</strong> nenhum tipo de e-mail ou
                  contato da Make My Date.
                </>
              )}
              {!userIsParticipant && (
                <>
                  As informações dos participantes são utilizadas apenas para
                  procedimentos internos. Os participantes{' '}
                  <strong>não receberão</strong> nenhum tipo de e-mail ou
                  contato da Make My Date.
                </>
              )}
            </p>
          </Col>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ParticipantsCard;
