/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Tooltip,
} from 'shards-react';

import { useNavigate } from 'react-router-dom';

import { BADGE_INFO } from 'lib/badge-info';
import { formatDate, formatDateTimeUsingSlashes } from 'lib/date-utils';

import DateListItem from './DateListItem';
import OrderListItemButtons from './OrderListItemButtons';

const OrderListItem = ({ order }) => {
  const navigate = useNavigate();

  const [openTooltip, setOpenTooltip] = useState(false);

  const toggle = () => {
    setOpenTooltip((o) => !o);
  };

  const badge = (options) => {
    let klass = 'order-badge';
    let id = `statusBadge_${order.id}`;
    let href = null;

    if (options.mobile) {
      klass = `${klass}-mobile`;
      id = `mobile_${id}`;
    }

    if (order.status === 'waiting_for_payment') {
      href = `/your-reservation?ref=${order.id}`;
      klass = `${klass} pointer`;
    }

    const currentBadge = () => (
      <Badge
        pill
        id={id}
        className={`${klass} ${BADGE_INFO[order.status].klass}`}
        style={
          order.status === 'ready'
            ? { animation: 'green-pulse 2s infinite' }
            : {}
        }
      >
        {BADGE_INFO[order.status].text}
      </Badge>
    );

    if (href) {
      // eslint-disable-next-line react/jsx-no-target-blank
      return (
        <a target="_blank" href={href}>
          {currentBadge()}
        </a>
      );
    }

    return currentBadge();
  };

  let cardClass = '';

  if (!order.number && order.status === 'pre_booking') {
    cardClass = 'date-card-disabled-background';
  }

  return (
    <Card
      className={`card-post card-post--aside card-post--1 flex-flow-column ${cardClass}`}
    >
      <CardHeader className="d-flex justify-content-between border-bottom">
        <div className="d-flex align-items-center">
          {order.number && (
            <h6 className="mb-0 mr-2">Pedido: {order.number}</h6>
          )}
          {badge({ mobile: false })}
          <Tooltip
            open={openTooltip}
            target={`#statusBadge_${order.id}`}
            toggle={toggle}
          >
            {BADGE_INFO[order.status].tooltip}
          </Tooltip>
        </div>
        <div className="text-right">
          Valor total:{' '}
          <span className="text-nowrap">{order.billing.price.formatted}</span>
        </div>
      </CardHeader>
      {order.status === 'waiting_for_payment' && (
        <CardBody className="d-flex justify-content-center d-md-none border-bottom py-3">
          <Button
            outline
            size="sm"
            theme="warning"
            className="display-flex align-items-center mr-1"
            onClick={() => {
              const yourReservationUrl = `/your-reservation?ref=${order.id}`;
              navigate(yourReservationUrl);
            }}
            data-testid="date-pay-button"
          >
            <span className="material-icons">attach_money</span>
            <span className="mb-0 ml-1" style={{ fontSize: '13px' }}>
              Ir para o pagamento
            </span>
          </Button>
        </CardBody>
      )}
      <CardBody className="p-1">
        {order.line_items.map((lineItem, idx) => (
          <Col
            lg="12"
            sm="12"
            className="my-2"
            key={lineItem.id}
            data-testid={`order-index-${idx}`}
          >
            <DateListItem item={lineItem} orderID={order.id} />
          </Col>
        ))}
      </CardBody>
      <CardFooter className="d-flex align-items-center justify-content-between border-top">
        <span className="text-muted d-none d-sm-block d-md-none">
          Criado em {formatDateTimeUsingSlashes(order.inserted_at)}
        </span>
        <span className="text-muted d-none d-md-block">
          Criado em {formatDate(order.inserted_at)}
        </span>
        {badge({ mobile: true })}
        <OrderListItemButtons order={order} />
      </CardFooter>
    </Card>
  );
};
export default OrderListItem;
