import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { CardBody, Collapse } from 'shards-react';
import { useAuth } from 'contexts/AuthContext';

import { formatDateTimeUsingSlashes } from 'lib/date-utils';
import RequestChange from './RequestChange';

import './changes-requested.scss';

const ChangesRequested = ({ changesRequested, lineItem, order, refetch }) => {
  const [state, setState] = useState('closed');
  const { currentUser } = useAuth();

  const buildBadge = (status) => {
    if (status === 'pending') {
      return <span className="badge mx-1 bg-warning">Pendente</span>;
    }
    if (status === 'approved') {
      return <span className="badge mx-1 bg-success">Aprovado</span>;
    }
    if (status === 'rejected') {
      return <span className="badge mx-1 bg-danger">Rejeitado</span>;
    }

    return '';
  };

  const buildConversation = () =>
    changesRequested.map((changeRequested) => (
      <div key={changeRequested.id} className="change-request mt-3">
        <div className="user-request d-flex">
          <img
            className="avatar user-avatar"
            src={currentUser.avatar}
            alt={currentUser.first_name}
          />
          <div className="card">
            <div className="card-header d-flex justify-content-between p-3">
              <p className="mb-0">
                <strong>
                  {currentUser.firstName} {currentUser.lastName}
                </strong>
              </p>
              <p className="text-muted small mb-0">
                <i className="far fa-clock"></i>&nbsp;
                {formatDateTimeUsingSlashes(changeRequested.inserted_at, true)}
              </p>
            </div>
            <div className="card-body">
              <p className="mb-0">{changeRequested.request}</p>

              <hr />
              {buildBadge(changeRequested.status)}
            </div>
          </div>
        </div>

        {changeRequested.response && (
          <div className="admin-response d-flex">
            <div className="card">
              <div className="card-header d-flex justify-content-between p-3">
                <p className="mb-0">
                  <strong>Make My Date</strong>
                </p>
                <p className="text-muted small mb-0">
                  <i className="far fa-clock"></i>&nbsp;
                  {formatDateTimeUsingSlashes(changeRequested.updated_at, true)}
                </p>
              </div>
              <div className="card-body">
                <p className="mb-0">{changeRequested.response}</p>
              </div>
            </div>
            <img
              className="avatar admin-avatar"
              src="https://res.cloudinary.com/makemydate/image/upload/v1638294831/Logo/apple-touch-icon_xnvi6u.png"
              alt="Make My Date"
            />
          </div>
        )}
      </div>
    ));

  return (
    <CardBody className="border-bottom">
      <div
        className="d-inline-flex pointer"
        onClick={() => setState(state === 'opened' ? 'closed ' : 'opened')}
      >
        <h6>Pedidos de mudança</h6>
        <span
          className={`material-icons text-fiord-blue dropdown-arrow-icon
                  ${state === 'opened' ? 'inactive-icon' : ''}`}
        >
          keyboard_arrow_down
        </span>
        <span
          className={`material-icons text-fiord-blue dropdown-arrow-icon
                  ${state === 'opened' ? '' : 'inactive-icon'}`}
        >
          keyboard_arrow_up
        </span>
      </div>
      <Collapse open={state === 'opened'}>
        {changesRequested?.length > 0 && buildConversation()}
        {changesRequested?.length === 0 && (
          <Alert variant="light">
            Ainda não há pedidos de mudança neste item do pedido
          </Alert>
        )}

        <RequestChange order={order} lineItem={lineItem} onSend={refetch} />
      </Collapse>
    </CardBody>
  );
};

export default ChangesRequested;
