/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

/*
  This slide contains only information that will be used to place a pre-booking
  order. Other order or line item information should be retrieved directly from
  the order.

  Example:

    {
      lineItemsParams: [
        {
          id: item.id,
          participants: [...],
          booking: {
            startDate: '',
            fromTime: '',
            toTime: '',
            weekDays: 0,
            comment: '',
          },
          customFields: {},
          userIsParticipant: true,
          validationStatus: 'initial',
          removed: false
        }
      ],
      currentLineItemIndex: 0,
      specialItemsParams: {
        '3862d552-0f3c-4a6f-8e39-cfe58eeec322': {
          removed: true
        }
      }
    }

    validationStatus can be: 'initial', 'valid', and 'invalid'.
 */
const initialState = {
  // Users have to fill in information for each variant line item from their
  // carts. This variable is an array containing all the data for all the
  // variant line items.
  lineItemsParams: [],

  // This is the index of the line item being edited by the user. It will be
  // used in conjunction with the line items array above.
  currentLineItemIndex: 0,

  // Special line items, like gift cards and surprise dates, are deal with in
  // a separate structure, because they are not part of the variant line items.
  specialItemsParams: {},
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    changeUserIsParticipant: (state) => {
      const { lineItemsParams, currentLineItemIndex } = state;
      const lineItem = lineItemsParams[currentLineItemIndex];
      lineItem.userIsParticipant = !lineItem.userIsParticipant;
    },
    removeLineItem: (state) => {
      state.lineItemsParams[state.currentLineItemIndex].removed = true;
      state.lineItemsParams[state.currentLineItemIndex].validationStatus =
        'valid';
    },
    setBooking: (state, action) => {
      const { field, value } = action.payload;
      const { booking } = state.lineItemsParams[state.currentLineItemIndex];

      booking[field] = value;
    },
    setCustomField: (state, action) => {
      const { field, value } = action.payload;
      const { customFields } =
        state.lineItemsParams[state.currentLineItemIndex];

      customFields[field] = value;
    },
    setCurrentLineItemIndex: (state, action) => {
      state.currentLineItemIndex = action.payload;
    },
    setFieldForSingleParticipant: (state, action) => {
      const { participantIndex, field, value } = action.payload;
      const { participants } =
        state.lineItemsParams[state.currentLineItemIndex];

      participants[participantIndex][field] = value;
    },
    setLineItemsParams: (state, action) => {
      state.lineItemsParams = action.payload;
      state.currentLineItemIndex = 0;
    },

    setParticipants: (state, action) => {
      state.lineItemsParams[state.currentLineItemIndex].participants =
        action.payload;
    },
    setSpecialItemParams: (state, action) => {
      state.specialItemsParams[action.payload.id] = {
        removed: action.payload.removed,
      };
    },
    setValidationStatus: (state, action) => {
      const { validationStatus } = action.payload;

      state.lineItemsParams[state.currentLineItemIndex].validationStatus =
        validationStatus;
    },
    setupDefaultParticipants: (state, action) => {
      const { lineItemsParams } = state;

      // `variantsFields` is an array of arrays. It contains all fields for all
      // variants on the cart. Supposing there are two variants, the fields
      // will be like this:
      //
      // [
      //   [
      //     {label: 'Nome', name: 'name', required: true, type: 'text', validation_type: null},
      //     {label: 'Email', name: 'email', required: false, type: 'email', validation_type: 'email'}
      //   ],
      //   [
      //     {label: 'Nome', name: 'name', required: true, type: 'text', validation_type: null},
      //     {label: 'CPF', name: 'document', required: false, type: 'text', validation_type: 'cpf'}
      //   ],
      // ]
      const { user, variantsFields } = action.payload;

      variantsFields.forEach((variantFields, index) => {
        const firstParticipant = {};

        variantFields.forEach((field) => {
          firstParticipant[field.name] = user[field.name] || '';
        });

        state.lineItemsParams[index].participants = [
          firstParticipant,
          ...lineItemsParams[index].participants.slice(1),
        ];
      });
    },
    undoRemoveLineItem: (state) => {
      state.lineItemsParams[state.currentLineItemIndex].removed = false;
    },
  },
});

export const {
  changeUserIsParticipant,
  removeLineItem,
  setBooking,
  setCurrentLineItemIndex,
  setCustomField,
  setFieldForSingleParticipant,
  setLineItemsParams,
  setParticipants,
  setSpecialItemParams,
  setupDefaultParticipants,
  setValidationStatus,
  undoRemoveLineItem,
} = cartSlice.actions;

export default cartSlice.reducer;
