import React from 'react';
import { Container, Row, Col } from 'shards-react';

import PageTitle from 'components/common/PageTitle';
import ContactDetails from 'components/contact/ContactDetails';
import ActivationAccountWarning from 'components/activation-account-warning/ActivationAccountWarning';

const Contact = () => (
  <Container fluid className="main-content-container px-4">
    <ActivationAccountWarning />

    <Row noGutters className="page-header py-4">
      <PageTitle
        title="Contato"
        subtitle="Fale com a gente"
        md="12"
        className="ml-sm-auto mr-sm-auto"
      />
    </Row>

    <Row>
      <Col lg="12">
        <ContactDetails />
      </Col>
    </Row>
  </Container>
);

export default Contact;
