import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Container, Row, Col } from 'shards-react';

import API from 'api';

import ActivationAccountWarning from 'components/activation-account-warning/ActivationAccountWarning';
import Loading from 'components/loading/Loading';
import PageTitle from 'components/common/PageTitle';
import UserAccountDetails from 'components/user-profile/UserAccountDetails';
import UserDelete from 'components/user-profile/UserDelete';
import UserDetails from 'components/user-profile/UserDetails';
import { useAuth } from 'contexts/AuthContext';
import { extractAvatarNumberFromUrl } from 'lib/avatar';

const UserProfile = () => {
  const { currentUser, updateProfileDataInMemory } = useAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    API.User.find(currentUser.id).then((response) => {
      const { data: body } = response;
      const userFromBackend = body.data;

      if (userFromBackend !== null) {
        setUser(userFromBackend);
        updateProfileDataInMemory(userFromBackend);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MySwal = withReactContent(Swal);

  const onSaveProfile = (userFormData) => {
    if (
      !userFormData.address_number ||
      userFormData.address_number.length === 0
    ) {
      // eslint-disable-next-line no-param-reassign
      userFormData.address_number = 'S/N';
    }

    // eslint-disable-next-line no-param-reassign
    userFormData = {
      ...userFormData,
      // send only the avatar number to the backend instead of the full avatar URL
      avatar: extractAvatarNumberFromUrl(userFormData.avatar),
    };

    API.User.update(currentUser.id, userFormData)
      .then((response) => {
        const { data: body } = response;
        const updatedUser = body.data;
        updateProfileDataInMemory(updatedUser);

        MySwal.fire({
          title: <strong>Perfil atualizado!</strong>,
          icon: 'success',
        }).then(() => window.location.reload());
      })
      .catch(() => {
        MySwal.fire({
          title: <strong>Oops</strong>,
          html: <i>Alguma coisa deu errada, tente novamente mais tarde :(</i>,
          icon: 'error',
        });
      });
  };

  if (!user) {
    return <Loading />;
  }

  return (
    <Container fluid className="main-content-container px-4">
      <ActivationAccountWarning loggedUser={user} />

      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Seu Perfil"
          subtitle="Visão geral"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      <Row>
        <Col lg="4">
          <UserDetails userDetails={user} saveProfile={onSaveProfile} />
          <UserDelete />
        </Col>
        <Col lg="8">
          <UserAccountDetails userDetails={user} saveProfile={onSaveProfile} />
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfile;
