import React from 'react';
import { Container, Row, Card, CardBody, Button } from 'shards-react';
import { useNavigate } from 'react-router-dom';

import { buildTitle } from 'lib/title';

const AfterPayment = () => {
  const navigate = useNavigate();

  document.title = buildTitle('Confirmação de pagamento');

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header pt-4">
        <i className="fa fa-check-circle fa-5x mx-auto mt-3 text-primary"></i>
      </Row>
      <Row className="page-header pt-4">
        <Row className="mx-auto text-center">
          <div className="mx-auto">
            <span className="text-uppercase page-subtitle">
              Agora é só esperar
            </span>
          </div>
        </Row>
      </Row>
      <Row className="page-header pb-4">
        <Row className="mx-auto text-center">
          <div className="mx-auto">
            <h3 className="page-title">Você fez seu pagamento!</h3>
          </div>
        </Row>
      </Row>
      <Card className="after-payment-card">
        <CardBody className="text-center">
          <div className="mx-auto">
            <h5>
              Agora é só aguardar o e-mail com a confirmação do seu pagamento e
              estará tudo pronto para vocês aproveitarem muito essa experiência!
            </h5>
          </div>
        </CardBody>
      </Card>
      <Row>
        <Row className="mx-auto">
          <Button
            pill
            outline
            theme="secondary"
            size="lg"
            className="mt-3"
            onClick={() => {
              navigate('/your-dates');
            }}
          >
            &larr; Voltar para minha conta
          </Button>
        </Row>
      </Row>
    </Container>
  );
};

export default AfterPayment;
