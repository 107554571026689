import React from 'react';
import { NavItem, NavLink, Badge, Collapse, DropdownItem } from 'shards-react';

export default class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.toggleNotifications = this.toggleNotifications.bind(this);
  }

  toggleNotifications() {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      visible: !this.state.visible,
    });
  }

  render() {
    return (
      <NavItem className="d-none border-right dropdown notifications pointer">
        <NavLink
          className="nav-link-icon text-center"
          onClick={this.toggleNotifications}
        >
          <div className="nav-link-icon__wrapper">
            <i className="material-icons">&#xE7F4;</i>
            <Badge pill theme="danger">
              1
            </Badge>
          </div>
        </NavLink>
        <Collapse
          open={this.state.visible}
          className="dropdown-menu dropdown-menu-small"
        >
          <DropdownItem>
            <div className="notification__icon-wrapper">
              <div className="notification__icon">
                <i className="material-icons">campaign</i>
              </div>
            </div>
            <div className="notification__content">
              <span className="notification__category">Oferta para você!</span>
              <p>
                Você ganha{' '}
                <span className="text-success text-semibold">28%</span> de
                desconto. Aí sim!
              </p>
            </div>
          </DropdownItem>
          <DropdownItem>
            <div className="notification__icon-wrapper">
              <div className="notification__icon">
                <i className="material-icons">auto_awesome</i>
              </div>
            </div>
            <div className="notification__content">
              <span className="notification__category">Novo Date</span>
              <p>
                Novo date que combina com você. Peça o seu{' '}
                <span className="text-danger text-semibold">agora</span>!
              </p>
            </div>
          </DropdownItem>
          <DropdownItem className="notification__all text-center">
            <a href="/notifications">Ver todas as notificações</a>
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}
