import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shards-react';

const SeeMyOrderButton = ({ order }) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        navigate(`/your-dates/${order.number}`);
      }}
      theme="dark"
    >
      <i className="far fa-list-alt"></i>
      Ver meu pedido
    </Button>
  );
};

export default SeeMyOrderButton;
