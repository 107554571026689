import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'shards-react';

import MainNavbar from 'components/layout/MainNavbar/MainNavbar';
import MainSidebar from 'components/layout/MainSidebar/MainSidebar';
import MainFooter from 'components/layout/MainFooter';
import CookieConsent from 'components/cookie-consent/CookieConsent';

import './styles.scss';

const lgSize = { size: 10, offset: 2 };
const mdSize = { size: 9, offset: 3 };
const noSidebarSize = { size: 12 };

const DefaultLayout = ({
  children,
  noFooter,
  noHeaderMenus,
  noLogo,
  noNavbar,
  noSidebar,
}) => (
  <Container fluid className="flex-container">
    <Row className="flex-container">
      {!noSidebar && <MainSidebar noSidebar={noSidebar} />}
      <Col
        className="main-content p-0 flex-container vh-100"
        lg={noSidebar ? noSidebarSize : lgSize}
        md={noSidebar ? noSidebarSize : mdSize}
        sm="12"
        tag="main"
      >
        {!noNavbar && (
          <MainNavbar
            showLogo={noSidebar && !noLogo}
            showHeaderMenus={!noHeaderMenus}
          />
        )}
        {children}
        {!noFooter && <MainFooter />}
      </Col>
    </Row>
    <CookieConsent />
  </Container>
);

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default DefaultLayout;
