import client from '../client';

export default class ResetPassword {
  sendResetPasswordEmail(email, url) {
    return client.post('/reset-password', { email, url });
  }

  resetPassword(token, password) {
    return client.patch(`/reset-password/${token}`, { password });
  }

  getUserFromToken(token) {
    return client.get(`/reset-password/${token}`, {});
  }
}
