/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-return-assign */
import React, { useState, useEffect } from 'react';

import API from 'api';

import Loading from 'components/loading/Loading';
import { Config } from 'lib/config';
import { getQueryParameter } from 'lib/query-string';
import { useAuth } from 'contexts/AuthContext';

const ResetPasswordWithToken = () => {
  const [loading, setLoading] = useState(true);
  const [loggingIn, setLoggingIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordConfirmationValid, setIsPasswordConfirmationValid] =
    useState(true);
  const [status, setStatus] = useState('failure');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const resetPasswordToken = getQueryParameter('ref');
  const url = getQueryParameter('url');
  const { login } = useAuth();

  useEffect(() => {
    if (resetPasswordToken) {
      API.ResetPassword.getUserFromToken(resetPasswordToken)
        .then((response) => {
          const { data: body } = response;

          if (body !== null) {
            setEmail(body.email);
          }

          setStatus('success');
          setLoading(false);
        })
        .catch(() => {
          setStatus('failure');
          setLoading(false);
        });
    }
  }, [resetPasswordToken]);

  const validatePassword = () => {
    let valid = true;
    setIsPasswordValid(true);
    setIsPasswordConfirmationValid(true);

    if (password.trim().length < 8) {
      setIsPasswordValid(false);
      valid = false;
    }

    if (password.trim() !== passwordConfirmation.trim()) {
      setIsPasswordConfirmationValid(false);
      valid = false;
    }

    return valid;
  };

  const returnUrl = () => {
    if (url) {
      return decodeURI(url);
    }

    return '/';
  };

  if (loading) {
    return <Loading />;
  }

  const handleSubmit = () => {
    setMessage('');
    setError('');

    if (validatePassword()) {
      API.ResetPassword.resetPassword(resetPasswordToken, password)
        .then(() => {
          setMessage(
            'Senha alterada com sucesso. Aguarde, estamos fazendo login...',
          );
          setLoggingIn(true);
          setTimeout(() => {
            login(email, password)
              .then((stat) => {
                if (stat.loggedIn) {
                  setTimeout(() => (window.location.href = returnUrl()), 800);
                } else {
                  setError(status.error);
                }
              })
              .catch(() => {
                setError('Não foi possível fazer o login');
              });
          }, 2000);
        })
        .catch(() => {
          setError(
            'Não foi possível alterar sua senha. Tente novamente mais tarde',
          );
        });
    } else {
      setError(
        'A senha deve ter no mínimo 8 caracteres e ser idêntica à confirmação',
      );
    }
  };

  return (
    <div
      id="account-activation"
      className="row justify-content-md-center mt-3 vh-100 mt-5"
    >
      <div className="col-md-4">
        <div className="card">
          <div className="card-body">
            <div>
              <section className="container">
                {status === 'success' && (
                  <div className="success">
                    <h1 className="text-center">Senha</h1>
                    {error && <div className="alert alert-danger">{error}</div>}
                    {message && (
                      <div className="alert alert-success">{message}</div>
                    )}

                    <section className="row">
                      <div className="col">
                        <section className="form-group">
                          <label>Nova senha*</label>
                          <input
                            className={`form-control ${
                              isPasswordValid ? '' : 'required'
                            }`}
                            type="password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </section>

                        <section className="form-group">
                          <label>Confirmação de senha*</label>
                          <input
                            className={`form-control ${
                              isPasswordConfirmationValid ? '' : 'required'
                            }`}
                            type="password"
                            required
                            value={passwordConfirmation}
                            onChange={(e) =>
                              setPasswordConfirmation(e.target.value)
                            }
                          />
                        </section>
                      </div>
                    </section>
                    <div>
                      <button
                        disabled={loading || loggingIn}
                        className="w-100 btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Alterar senha
                      </button>
                    </div>
                  </div>
                )}
                {status === 'failure' && (
                  <div className="failure">
                    <h1>Hum...</h1>
                    <p>
                      Desculpe, alguma coisa deu errada. Lembre-se que um pedido
                      de troca de senha é valido por apenas 3 dias. Se o prazo
                      já passou,{' '}
                      <a href={`${Config.storeUrl}/contato`}>
                        entre em contato
                      </a>{' '}
                      com a gente.
                    </p>
                    <p>Senão, tente novamente mais tarde</p>

                    <a
                      href={Config.storeUrl}
                      className="btn btn-secondary btn-l"
                    >
                      VOLTAR PARA A HOME
                    </a>
                  </div>
                )}
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordWithToken;
