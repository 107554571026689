import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import Form from 'react-bootstrap/Form';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
} from 'shards-react';

import API from 'api';

import { showImage } from 'lib/date-image';
import {
  formatDateWithDayAndMonth,
  formatDateWithWeekDay,
  getTimeFromDate,
} from 'lib/date-utils';
import LineItemPriceSummary from './LineItemPriceSummary';

const LineItemDetails = ({ lineItem, order }) => {
  const [comment, setComment] = useState('');
  const [isCommentValid, setIsCommentValid] = useState(true);
  const [requestChangeVisible, setRequestChangeVisible] = useState(false);

  const commentRef = useRef(null);

  useEffect(() => {
    if (requestChangeVisible) {
      commentRef.current.focus();
    }
  }, [requestChangeVisible]);

  const closeRequestChange = () => {
    setComment('');
    setIsCommentValid(true);
    setRequestChangeVisible(false);
  };

  const requestChange = () => {
    if (comment.trim().length === 0) {
      setIsCommentValid(false);
      Swal.fire(
        'Deixe um comentário pra gente com alguma dica pra nos ajudar a encontrar o dia e horário ideal para vocês',
        '',
        'error',
      );
    } else {
      API.Order.requestChanges(order.id, {
        comment,
        line_item_id: lineItem.id,
      }).then(() => {
        setIsCommentValid(true);
        Swal.fire({
          title: 'Sucesso',
          html: `Pedido de alteração enviado! Em breve a gente entra em contato com uma nova sugestão de dia e horário para seu date. Você pode acompanhar o status do seu pedido na <a href="/your-dates/${order.number}" target="_blank">página de detalhes do pedido.</a>`,
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => closeRequestChange());
      });
    }
  };

  return (
    <Card
      small
      className="card-post card-post--aside card-post--1 flex-flow-column mb-3"
    >
      <CardHeader className="border-bottom">
        <h5 className="m-0">{lineItem.title}</h5>
      </CardHeader>

      <div className="inherit column-mobile">
        <div className="d-flex flex-column w-100">
          <Row className="line-item-row" key={lineItem.id}>
            <div
              className="card-post__image__2 flex-grow-1"
              style={{ backgroundImage: `url('${showImage(lineItem)}')` }}
            ></div>
            <CardBody className="p-0">
              <Col>
                <div className="card-text-2">
                  <span className="line-item-number">
                    #&nbsp;{lineItem.number}
                  </span>

                  <h5 className="card-title mt-1">{lineItem.title}</h5>

                  {lineItem.children?.map((child) => (
                    <div key={`line-item-details-children-${child.id}`}>
                      <i className="fa fa-plus"></i> {child.quantity} x{' '}
                      {child.title} de {child.billing.price.formatted} ={' '}
                      {child.billing.price_with_discount_x_quantity.formatted}
                    </div>
                  ))}
                </div>
                <div className="card-text-2">
                  {lineItem.scheduled_for && (
                    <div className="mt-4 schedule-information">
                      <i className="fa fa-calendar-days"></i> &nbsp; Agendado
                      para {formatDateWithWeekDay(lineItem.scheduled_for)},
                      {formatDateWithDayAndMonth(lineItem.scheduled_for)}, às{' '}
                      {getTimeFromDate(lineItem.scheduled_for)}h
                    </div>
                  )}
                </div>
              </Col>
              <Col className="line-item-price-summary-column">
                <LineItemPriceSummary lineItem={lineItem} />
              </Col>
            </CardBody>
          </Row>
          <Row className="justify-content-end mr-2">
            <Button
              pill
              outline
              theme="secondary"
              className={`${requestChangeVisible ? 'd-none' : 'm-3'}`}
              onClick={() => {
                setRequestChangeVisible(true);
              }}
            >
              Pedir mudança na reserva
            </Button>
          </Row>
        </div>
      </div>

      <CardFooter
        className={`${requestChangeVisible ? 'border-top' : 'd-none'}`}
      >
        <h5>Solicitação de alteração</h5>

        <Form.Control
          as="textarea"
          className={`mb-3 ${isCommentValid ? '' : 'is-invalid'}`}
          placeholder="Escreva aqui quais mudanças você quer na sua reserva."
          rows="5"
          onChange={(e) => {
            setComment(e.target.value);
          }}
          ref={commentRef}
          value={comment}
        />
        <div className="text-end text-danger">
          {comment.length} caracteres (escreva ao menos 15 caracteres)
        </div>
        <Row>
          <Row className="mx-auto my-1">
            <Button
              pill
              outline
              theme="danger"
              size="lg"
              className="m-1"
              onClick={() => {
                closeRequestChange();
              }}
            >
              Cancelar
            </Button>
            <Button
              pill
              theme="secondary"
              size="lg"
              className="m-1"
              disabled={comment?.length < 15}
              onClick={() => requestChange()}
            >
              Pedir mudança
            </Button>
          </Row>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default LineItemDetails;
