/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Navbar } from 'shards-react';

import NavbarNav from 'components/layout/MainNavbar/NavbarNav/NavbarNav';
import NavbarToggle from 'components/layout/MainNavbar/NavbarToggle';
import MainLogo from 'components/layout/MainLogo';

import './styles.scss';

const MainNavbar = ({ showHeaderMenus, showLogo, stickyTop }) => {
  const classes = classNames(
    'main-navbar',
    'bg-white',
    stickyTop && 'sticky-top',
  );

  return (
    <div className={classes}>
      <Container data-testid="main-nav">
        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0">
          <div className="w-100">
            {showLogo && (
              <MainLogo
                extraClass="d-flex align-items-center"
                hideLogoText={false}
                alignLogoLeft
              />
            )}
          </div>

          {showHeaderMenus && (
            <>
              <NavbarNav />
              <NavbarToggle />
            </>
          )}
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
  stickyTop: true,
};

export default MainNavbar;
