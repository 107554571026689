/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Loading from 'components/loading/Loading';

const ResetPassword = () => {
  const [error, setError] = useState('');
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const q = new URLSearchParams(window.location.search).get('token');
    setToken(q);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setMessage('');
      setError('');
      setLoading(true);
      setMessage('Senha alterada com sucesso');
    } catch {
      setMessage(
        'Não foi possível alterar sua senha, tente novamente mais tarde.',
      );
    }

    setLoading(false);
  };

  if (!token) {
    return <Loading />;
  }

  return (
    <div className="row justify-content-md-center mt-3 vh-100 mt-5">
      <div className="col col-md-4"></div>
      <div className="col-md-4">
        <div className="card">
          <div className="card-body">
            <h2 className="text-center mb-4">Nova senha</h2>
            {error && <div className="alert alert-danger">{error}</div>}

            {message && <div className="alert alert-success">{message}</div>}

            <form onSubmit={handleSubmit}>
              <section className="form-group">
                <label>Nova senha</label>
                <input
                  className="form-control"
                  type="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </section>

              <section className="form-group">
                <label>Confirmação de senha</label>
                <input
                  className="form-control"
                  type="password"
                  required
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </section>

              <button
                disabled={loading}
                className="w-100 btn btn-primary"
                type="submit"
              >
                Atualizar senha
              </button>
            </form>
            <div className="w-100 text-center mt-3">
              <Link to="/login">Entrar</Link>
            </div>
          </div>
          <div className="w-100 text-center mt-2">
            Precisa de uma conta? <Link to="/signup">Cadastre-se</Link>
          </div>
        </div>
      </div>
      <div className="col col-md-4"></div>
    </div>
  );
};

export default ResetPassword;
