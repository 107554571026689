import React from 'react';
import { Card, Row, Col, Form, Button } from 'shards-react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import API from 'api';

import { Config } from 'lib/config';
import { useAuth } from 'contexts/AuthContext';

const UserDelete = () => {
  const { currentUser, logout } = useAuth();

  const MySwal = withReactContent(Swal);

  const onClickHandler = () => {
    MySwal.fire({
      title: 'Tem certeza que quer encerrar a sua conta?',
      html: `Ao encerrar sua conta, seus dados pessoais serão inativados
            por 3 anos e depois deletados ou anonimizados. Lembre-se que
            esta ação é <b>permanente</b> e <b>não poderá ser revertida</b>.
            Além disso, todos os seus pedidos serão cancelados e <b>não será
            possível criar uma nova conta com o mesmo CPF.</b>`,
      showConfirmButton: false,
      showCancelButton: true,
      focusCancel: true,
      cancelButtonText: 'Não',
      showDenyButton: true,
      denyButtonText: 'Sim',
      icon: 'warning',
    }).then((result) => {
      if (result.isDenied) {
        API.User.update(currentUser.id, { status: 'inactive' })
          .then(() => {
            logout();
            window.location.href = Config.storeUrl;
          })
          .catch(() => {
            Swal.fire('Oops, alguma coisa deu errada', '', 'error');
          });
      }
    });
  };

  return (
    <Card small className="mb-4 pr-3">
      <Row>
        <Col className="center">
          <h6 className="m-3">Encerrar Conta</h6>
        </Col>
        <Col className="align-self-center center">
          <Form>
            <Button block outline theme="danger" onClick={onClickHandler}>
              Encerrar
            </Button>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

export default UserDelete;
